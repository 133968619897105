import React, { Component } from "react";
import Loader from "../../components/Loader";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { GetLocalStorageDataWithParse, withRouter } from "../../helpers";
import Toast from "../../components/Toast";
import Logout from "../../helpers/Logout";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { UserAction } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { CommunicationService } from "../../services/communication.service";
import Dropdown from "../../components/Dropdown";

var currentDate = new Date();

const ErrorView = (props) => {
	return (
		<>
			{props.errorFor != "" && (
				<div
					className={
						props.errorFor == "emptyDropdown"
							? "invalid-dropdown-icon"
							: "invalid-message-icon"
					}
				>
					<img src="images\icon material-error.png" alt="invalid" />
				</div>)}
		</>
	);
};

class LinkDocumentModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toastMessage: null,
			toastType: "",
			toastVisible: false,
			errorMessages: {},
			showErrorMessage: false,
			clientDataList: [],
			shipDataList: [],
			poNumberList: [],
			tableABList: [],
			documentTypeId: this.props.documentTypeId || 0,
			sdocIssueDate: "",
			mdIssueDate: "",
			mdNumber: "",
			sdocNumber: "",
			division: "",
			markerName: "",
			productName: "",
			objectOfDeclaration: "",
			Remarks: "",
			DeclarationOfConformity: false,
			DeclarationOfAsbestosFree: false,
			DeclarationOfMSDS: false,
			Miscellaneous: false,
			DeclarationList: [{
				DateOfDeclaration: convertDate(new Date()),
				ItemsDeclaredFor: "",
				ExpiryDate: "",
				SupplierName: "",
				Division: "",
				Remarks: "",
				index: Math.random(),
				isErrorpresent: false,
			}],

			listDeclarationData: [],
			DateOfDeclaration: convertDate(new Date()),
			TFile: this.props.file || [],
			userTypeId: 0,
			dropdown_clientFilter: 0,
			dropdown_shipFilter: 0,
			userRolePermissionRead: false,
			accessList: [],
			loggedInUserId: 0,
			dropdown_poNumber: "",
			trackingtoolId: 0,
			defaultPoNumberList: [],
			dropdown_supplierFilter: 0,
			disableDocumentType: false
		};
		this.DeclarationOfConformity = React.createRef()
		this.DeclarationOfAsbestosFree = React.createRef()
		this.DeclarationOfMSDS = React.createRef()
		this.Miscellaneous = React.createRef()
	}

	componentDidMount = async () => {
		try {
			var userData = GetLocalStorageDataWithParse("loggedInUser");
			if (userData != null) {
				this.setState({
					loggedInUserId: userData.id,
					userTypeId: userData.typeId,
					mdIssueDate: currentDate,
					sdocIssueDate: currentDate,
					accessList: GetLocalStorageDataWithParse("userRolePermission"),
				}, async () => {
					this.GetAllFilterDropdown();
				}
				);
			}
		}
		catch (error) { }
	};

	GetAllFilterDropdown = async () => {
		try {
			this.props.setIsLoading(true);
			this.setState({
				userRolePermissionRead:
					this.state.accessList != null &&
					this.state.accessList.communicationAccess == UserAction.READ,
			});
			const { userTypeId, loggedInUserId, TFile, documentTypeId } = this.state;

			const responseFilters = await CommunicationService.GetAllCommunicationLinkFilters(loggedInUserId, userTypeId);
			if (responseFilters == undefined) {
				this.setState({
					toastVisible: true,
					toastMessage: helperText.global.somethingWentWrong,
				}, () => {
					this.props.setIsLoading(false);
				});
			} else if (responseFilters != null) {
				if (responseFilters.status == 401) {
					Logout(this.props.navigate);
				} else if (responseFilters.status == 200 && responseFilters.data != null) {
					var response = responseFilters.data;
					this.setState({
						clientDataList: response.clientList,
						defaultShipDataList: response.shipList,
						defaultPoNumberList: response.poNumberList,
						supplierList: response.supplierList,
						tableABList: response.tableABCombinedList,
						fileName: TFile != null ? TFile.name : "",
						disableDocumentType: documentTypeId > 0 ? true : false,
					}, () => {
						this.props.setIsLoading(false);
					});

				}
			} else {
				this.setState({
					toastVisible: true,
					toastMessage: helperText.global.somethingWentWrong,
				}, () => {
					this.props.setIsLoading(false);
				});
			}
		} catch (error) { }
	};

	onChangeDocumentType = async (e) => {
		var documentTypeId = e.target.value;
		this.setState({
			documentTypeId: parseInt(documentTypeId, 10),
			listDeclarationData: []
		}, () => {
			if (this.state.showErrorMessage) {
				this.validateFields()
			}
		});
	};

	navigateTo(path) {
		this.props.navigate(path);
	}

	onDateChanged = (id, e) => {
		const { errorMessages, showErrorMessage } = this.state;

		if (id == "sdocIssueDate") {
			errorMessages["errorSDocIssueDate"] = "";
			this.setState({ sdocIssueDate: e, errorMessages: errorMessages });
		}

		if (id == "mdIssueDate") {
			errorMessages["errorMDIssueDate"] = "";
			this.setState({ mdIssueDate: e, errorMessages: errorMessages });
		}

		if (showErrorMessage) {
			this.validateFields();
		}
	};

	onTextChange = (e) => {
		const { name, value } = e.target;
		const { showErrorMessage } = this.state
		this.setState({
			[name]: value,
		},
			() => {
				if (showErrorMessage)
					this.validateFields();
			}
		);
	};

	onSaveClick = async () => {
		try {
			const { onSaveMDSDOCDeclarationData } = this.props;
			const {
				sdocIssueDate,
				mdIssueDate,
				mdNumber,
				sdocNumber,
				division,
				markerName,
				productName,
				objectOfDeclaration,
				Remarks,
				documentTypeId,
				TFile,
				listDeclarationData,
				userTypeId,
				trackingtoolId,
				dropdown_supplierFilter,
				dropdown_clientFilter,
				dropdown_shipFilter,
				loggedInUserId
			} = this.state;

			if (!this.validateFields()) {
				this.props.setIsLoading(true);
				let mdsdocData = new FormData();

				mdsdocData.append("SupplierId", parseInt(dropdown_supplierFilter));
				mdsdocData.append("division", division);

				mdsdocData.append("firstClientId", parseInt(dropdown_clientFilter, 10));
				mdsdocData.append("firstShipId", parseInt(dropdown_shipFilter, 10));
				mdsdocData.append("ClientId", parseInt(dropdown_clientFilter, 10));
				mdsdocData.append("ShipId", parseInt(dropdown_shipFilter, 10));
				mdsdocData.append("loggedInUserID", loggedInUserId);
				mdsdocData.append("TrackingToolId", trackingtoolId);
				mdsdocData.append("DocumentType", (documentTypeId == 1 ? "MD/SDoC" : "Declaration"));
				mdsdocData.append("IsUploaded", true);
				mdsdocData.append("IsSupplierLoggedIn", false);
				mdsdocData.append("IsAdminLoggedIn", true);
				mdsdocData.append("UserTypeId", userTypeId);
				mdsdocData.append("IsMdsdocUploaded", true);
				mdsdocData.append("MdsdocId", "");
				mdsdocData.append("poNumberList", null)
				mdsdocData.append("lineItemDescription", null)

				if (documentTypeId == 1) {

					var sdocDate = sdocIssueDate == "" ? "" : convertDate(sdocIssueDate);
					var mdDate = mdIssueDate == "" ? "" : convertDate(mdIssueDate);

					mdsdocData.append("sdocIssueDate", sdocDate);
					mdsdocData.append("mdIssueDate", mdDate);
					mdsdocData.append("mdNumber", mdNumber);
					mdsdocData.append("sdocNumber", sdocNumber);
					mdsdocData.append("markerName", markerName);
					mdsdocData.append("productName", productName);
					mdsdocData.append("objectOfDeclaration", objectOfDeclaration);
					mdsdocData.append("Remarks", Remarks);
					mdsdocData.append("TFile", TFile);

					onSaveMDSDOCDeclarationData(mdsdocData);

				}
				else if (documentTypeId == 2) {
					var imageData = []

					if (listDeclarationData.length > 0) {
						var imageData = listDeclarationData.map((e) => ({
							Name: e.name,
							files: e.TFile
						}))
					}

					for (let i = 0; i < imageData.length; i++) {
						mdsdocData.append(`${imageData[i].Name}`, imageData[i].files);
					}

					var DeclarationData = Array.prototype.slice.call(listDeclarationData)
					mdsdocData.append("DeclarationData", JSON.stringify(DeclarationData))

					onSaveMDSDOCDeclarationData(mdsdocData);

				}
			}
		}
		catch (error) {
			console.log(error);
		}

	}

	validateFields() {
		const {
			sdocIssueDate,
			mdIssueDate,
			mdNumber,
			sdocNumber,
			errorMessages,
			TFile,
			documentTypeId,
			listDeclarationData,
			dropdown_clientFilter,
			dropdown_shipFilter,
			dropdown_poNumber,
			dropdown_supplierFilter,
		} = this.state;

		let errors = errorMessages;
		let hasError = false;
		errors["errorClient"] = parseInt(dropdown_clientFilter, 10) === 0 ? "true" : "";
		errors["errorShip"] = parseInt(dropdown_shipFilter, 10) === 0 ? "true" : "";
		errors["errorPO"] = isStringEmpty(dropdown_poNumber) ? "true" : "";
		errors["errorSupplier"] = parseInt(dropdown_supplierFilter, 10) === 0 ? "true" : "";
		errors["errorDocumentType"] = parseInt(documentTypeId, 10) == 0 ? "true" : "";

		if (errors["errorClient"] != "" || errors["errorShip"] != "" || errors["errorPO"] != "" || errors["errorDocumentType"] != "" || errors["errorSupplier"] != "") {
			hasError = true;
		}

		if (documentTypeId == 1) {
			var sdocDateEmptyCheck = sdocIssueDate == "" ? "" : convertDate(sdocIssueDate);
			var mdDateEmptyCheck = mdIssueDate == "" ? "" : convertDate(mdIssueDate);

			errors["errorMdNumber"] = isStringEmpty(mdNumber) ? "true" : "";
			errors["errorSdocNumber"] = isStringEmpty(sdocNumber) ? "true" : "";
			errors["errorMDIssueDate"] = (mdDateEmptyCheck == null || mdDateEmptyCheck == undefined || mdDateEmptyCheck == "") ? "true" : "";
			errors["errorSDocIssueDate"] = (sdocDateEmptyCheck == null || sdocDateEmptyCheck == undefined || sdocDateEmptyCheck == "") ? "true" : "";

			if (errors['errorMdNumber'] != "" || errors['errorSdocNumber'] != "" || errors['errorMDIssueDate'] != "" || errors["errorSDocIssueDate"] != "") {
				hasError = true
			}
		}
		else if (documentTypeId == 2) {
			var declarationData = listDeclarationData.map(
				x => {
					if (x.ExpiryDate == "") {
						x.isErrorpresent = isStringEmpty(x.ExpiryDate) ? true : false
					}
					return x;
				});

			var isErrorPresent = declarationData.filter((t) => t.isErrorpresent?.toString().toLowerCase().includes(true));
			if (isErrorPresent.length > 0) {
				hasError = true;
			}

			this.setState({
				listDeclarationData: declarationData
			});
		}

		this.setState({
			errorMessages,
			showErrorMessage: hasError
		});

		return hasError;
	}

	checkBoxClick = (e) => {

		const { name, value, checked } = e.target;

		let nextCurrentDate = new Date();
		nextCurrentDate.setDate(new Date().getDate() + 1);
		nextCurrentDate.toLocaleDateString();

		const { listDeclarationData, DeclarationList, TFile } = this.state;
		var rowIndex = listDeclarationData.findIndex(t => t.name == name);
		var obj = [...DeclarationList]
		if (checked) {
			this.setState(prevState => ({
				listDeclarationData: [
					...prevState.listDeclarationData,
					{
						obj,
						name: name,
						DeclarationName: name,
						DateOfDeclaration: convertDate(new Date()),
						ExpiryDate: "",
						isErrorpresent: false,
						fileName: TFile != null ? TFile.name : "",
						IsMisc: (name.trim().toLowerCase() == "Miscellaneous".trim().toLowerCase()) ? true : false,
						IsDOC: (name.trim().toLowerCase() == "DeclarationOfConformity".trim().toLowerCase()) ? true : false,
						IsDOAsb: (name.trim().toLowerCase() == "DeclarationOfAsbestosFree".trim().toLowerCase()) ? true : false,
						IsDOMsds: (name.trim().toLowerCase() == "DeclarationOfMSDS".trim().toLowerCase()) ? true : false,
						nextCurrentDate: nextCurrentDate,
						TFile: TFile != null ? TFile : null
					}
				],
				[name]: checked,
			})
			);
		}
		else {
			if (rowIndex > -1) {
				listDeclarationData.splice(rowIndex, 1);
				this.setState({ listDeclarationData: listDeclarationData, [name]: checked, })
			}
		}
	}

	closeToast = () => {
		this.setState({ toastVisible: false });
	};

	onTextDeclartionChange = (e, itemName) => {

		var { listDeclarationData } = this.state;
		const { name, value } = e.target;

		var rowIndex = listDeclarationData.findIndex(t => t.name == itemName);
		if (rowIndex > -1) {
			listDeclarationData[rowIndex][name] = value;
			this.setState({ listDeclarationData }, () => { });
		}
	}

	onDeclarationDateChange = (e, itemName, dateName) => {

		const { listDeclarationData } = this.state;
		let checkDate = false;
		var rowIndex = listDeclarationData.findIndex(t => t.name == itemName);
		if (rowIndex > -1) {
			if (dateName == "ExpiryDate") {
				listDeclarationData[rowIndex]['ExpiryDate'] = e;

				var declarationDate = listDeclarationData[rowIndex]['DateOfDeclaration']
				var dDate = declarationDate == "" ? "" : declarationDate;
				var eDate = convertDate(e);;

				if (dDate != "") {

					if (new Date(dDate).getTime() >= new Date(eDate).getTime()) {
						checkDate = true;
					}
				}

				if (e != "") {
					listDeclarationData[rowIndex]['isErrorpresent'] = false;
				}
				else {
					listDeclarationData[rowIndex]['isErrorpresent'] = true;
				}


				this.setState({ listDeclarationData }, () => { });
			}
		}
	}

	//item declared autocomplete code
	onAddLineItemDescriptionTextChange = async (e, itemName) => {
		const { name, value } = e.target;
		const { listDeclarationData, tableABList } = this.state;

		var selectedLineItem = tableABList.filter(
			(x) => x.label != null && x.label?.trim().toLowerCase() == value?.trim().toLowerCase()
		);

		var rowIndex = listDeclarationData.findIndex(t => t.name == itemName);
		if (rowIndex > -1) {
			listDeclarationData[rowIndex][name] = value;
			this.setState({ listDeclarationData }, () => { });
		}
	};

	onDropdownItemSelect = async (e) => {
		const { showErrorMessage, defaultShipDataList, defaultPoNumberList, dropdown_clientFilter, supplierList } = this.state;
		const { name, value } = e.target;
		this.setState({
			[name]: value,
		},
			() => {
				if (name == "dropdown_clientFilter") {
					var filteredShipList = defaultShipDataList.filter(x => x.clientId == parseInt(value, 10));

					this.setState({
						shipDataList: filteredShipList,
						dropdown_shipFilter: 0,
						dropdown_poNumber: "",
						poNumberList: []
					});
				}
				if (parseInt(dropdown_clientFilter, 10) != 0 && name == "dropdown_shipFilter") {
					var filteredPOList = defaultPoNumberList.filter(x => x.parentValue == dropdown_clientFilter && x.shipId == value);

					this.setState({
						poNumberList: filteredPOList,
					});
				}
				if (name == "dropdown_supplierFilter") {
					var supplierDivision = supplierList.filter(x => x.value == value)[0]?.division;

					this.setState({ division: supplierDivision });

				}

				if (name == "dropdown_poNumber") {
					this.setState({ trackingtoolId: value, dropdown_poNumber: defaultPoNumberList?.filter(x => x.value == value)[0]?.label });
				}

				if (showErrorMessage) this.validateFields();
			}
		);
	};

	render() {
		const {
			toastVisible,
			toastType,
			toastMessage,
			showErrorMessage,
			clientDataList,
			shipDataList,
			poNumberList,
			tableABList,
			documentTypeId,
			sdocIssueDate,
			mdIssueDate,
			mdNumber,
			sdocNumber,
			division,
			markerName,
			productName,
			objectOfDeclaration,
			Remarks,
			errorMessages,
			DeclarationOfConformity,
			DeclarationOfAsbestosFree,
			DeclarationOfMSDS,
			Miscellaneous,
			listDeclarationData,
			DateOfDeclaration,
			fileName,
			dropdown_clientFilter,
			dropdown_shipFilter,
			dropdown_poNumber,
			supplierList,
			dropdown_supplierFilter,
			disableDocumentType,
			trackingtoolId
		} = this.state;

		return (
			<>
				{toastVisible && (
					<Toast
						toastVisible={toastVisible}
						type={toastType}
						message={toastMessage}
						closeToast={this.closeToast}
					/>
				)}
					<div className="supplierdetaildoc" style={{ display: "block" }}>
						<div className="modal-backdrop fade show" style={{ display: "block" }}></div>
						<div className="modal" id="SupplierDocumentupload-modal" style={{ display: "block" }}>
							<div className="modal-dialog modal-lg modal-dialog-centered modal-box" >
								<div className="modal-content ">
									{/* <!-- Modal Header --> */}
									<div className="modal-header">
										<h4 className="modal-title">Add Document</h4>
										<button type="button" data-bs-dismiss="modal">
											<img src="images/modal-close.png" alt="modal-close" style={{ pointerEvents: "all" }} onClick={() => this.props.onModalCloseClick()} />
										</button>
									</div>

									{/* <!-- Modal body --> */}
									<div className="modal-body">
										<div className="row">
											<div className="col-md-4" id={"clientName"}>
												<div
													className={
														!isStringEmpty(errorMessages.errorClient)
															? "doc-form-group invalid-position"
															: " doc-form-group"
													}
												>
													<label className="required-field">Client Name:</label>
													<Dropdown
														name="clientFilter"
														onChange={this.onDropdownItemSelect}
														selected={dropdown_clientFilter}
														style={{
															textOverflow: "ellipsis",
															whiteSpace: "pre",
															overflow: "hidden",
														}}
													>
														{clientDataList == null
															? null
															: clientDataList.map((item) => (
																<option
																	wrap="true"
																	key={item.clientId}
																	value={item.clientId}
																>
																	{item.clientName}
																</option>
															))}
													</Dropdown>
													{dropdown_clientFilter > 0 ? (
														<UncontrolledTooltip
															autohide={false}
															target={"clientName"}
															aria-haspopup="true"
															role="example"
															effect="solid"
															placement="bottom"
															style={{ width: "350px" }}
														>
															{clientDataList != null && clientDataList.filter(x => x.clientId == dropdown_clientFilter)[0]?.clientName}
														</UncontrolledTooltip>) : null}
													{!isStringEmpty(errorMessages.errorClient) && (
														<div className="invalid-dropdown-icon-Suppliermodal">
															<img
																src="images\icon material-error.png"
																alt="invalid"
															></img>
														</div>
													)}
												</div>
											</div>
											<div className="col-md-4" id={"shipName"}>
												<div
													className={
														!isStringEmpty(errorMessages.errorShip)
															? "doc-form-group invalid-position"
															: " doc-form-group"
													}
												>
													<label className="required-field">Ship Name:</label>

													<Dropdown
														name="shipFilter"
														onChange={this.onDropdownItemSelect}
														selected={dropdown_shipFilter}
														style={{
															textOverflow: "ellipsis",
															whiteSpace: "pre",
															overflow: "hidden",
														}}
													>
														{shipDataList == null
															? null
															: shipDataList.map((item) => (
																<option
																	wrap="true"
																	key={item.shipId}
																	value={item.shipId}
																>
																	{item.shipName}
																</option>
															))}
													</Dropdown>
													{dropdown_shipFilter > 0 ? (
														<UncontrolledTooltip
															autohide={false}
															target={"shipName"}
															aria-haspopup="true"
															role="example"
															effect="solid"
															placement="bottom"
															style={{ width: "350px" }}
														>
															{shipDataList != null && shipDataList.filter(x => x.shipId == dropdown_shipFilter)[0]?.shipName}
														</UncontrolledTooltip>) : null}
													{!isStringEmpty(errorMessages.errorShip) && (
														<div className="invalid-dropdown-icon-Suppliermodal">
															<img
																src="images\icon material-error.png"
																alt="invalid"
															></img>
														</div>
													)}
												</div>
											</div>
											<div className="col-md-4" id={"poNumber"}>
												<div
													className={
														!isStringEmpty(errorMessages.errorPO)
															? "doc-form-group invalid-position"
															: " doc-form-group"
													}
												>
													<label className="required-field">PO Number:</label>

													<Dropdown
														name="poNumber"
														onChange={this.onDropdownItemSelect}
														selected={trackingtoolId}
														style={{
															textOverflow: "ellipsis",
															whiteSpace: "pre",
															overflow: "hidden",
														}}
													>
														{poNumberList == null
															? null
															: poNumberList.map((item) => (
																<option
																	wrap="true"
																	key={item.value}
																	value={item.value}
																	title={item.label}
																>
																	{item.label.length > 50 ? item.label.slice(0, 50) + "..." : item.label}
																</option>
															))}
													</Dropdown>
													{dropdown_poNumber != "" ? (
														<UncontrolledTooltip
															autohide={false}
															target={"poNumber"}
															aria-haspopup="true"
															role="example"
															effect="solid"
															placement="bottom"
															style={{ width: "350px" }}
														>
															{poNumberList != null && poNumberList.filter(x => x.label == dropdown_poNumber)[0]?.label}
														</UncontrolledTooltip>) : null}
													{!isStringEmpty(errorMessages.errorPO) && (
														<div className="invalid-dropdown-icon-Suppliermodal">
															<img
																src="images\icon material-error.png"
																alt="invalid"
															></img>
														</div>
													)}
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4">
												<div className={
													!isStringEmpty(errorMessages.errorSupplier)
														? "doc-form-group invalid-position"
														: " doc-form-group"} id="supplierName">

													<label className="required-field">Supplier Name:</label>

													<Dropdown
														name="supplierFilter"
														onChange={this.onDropdownItemSelect}
														selected={dropdown_supplierFilter}
														style={{
															textOverflow: "ellipsis",
															whiteSpace: "pre",
															overflow: "hidden",
														}}
													>
														{supplierList == null
															? null
															: supplierList.map((item) => (
																<option
																	wrap="true"
																	key={item.value}
																	value={item.value}
																>
																	{item.label}
																</option>
															))}
													</Dropdown>
													{dropdown_supplierFilter > 0 ? (
														<UncontrolledTooltip
															autohide={false}
															target={"supplierName"}
															aria-haspopup="true"
															role="example"
															effect="solid"
															placement="bottom"
															style={{ width: "350px" }}
														>
															{supplierList != null && supplierList.filter(x => x.value == dropdown_supplierFilter)[0]?.label}
														</UncontrolledTooltip>) : null}
													{!isStringEmpty(errorMessages.errorSupplier) && (
														<div className="invalid-dropdown-icon-Suppliermodal">
															<img
																src="images\icon material-error.png"
																alt="invalid"
															></img>
														</div>
													)}
												</div>
											</div>
											<div className="col-md-4">
												<div className="doc-form-group">
													<label htmlFor="">Division:</label>
													<input className="form-control" type="text" name="division" value={division} onChange={this.onTextChange} disabled />
												</div>
											</div>
											<div className="col-md-4">
												<div
													className={
														!isStringEmpty(errorMessages.errorDocumentType)
															? "doc-form-group invalid-position"
															: " doc-form-group"
													}
												>
													<label htmlFor="" className="required-field">Document Type:</label>
													<select className="form-select" aria-label="form-select example"
														onChange={(e) => this.onChangeDocumentType(e)} value={documentTypeId} disabled={disableDocumentType}>
														<option key={0} value="0">Select</option>
														<option key={1} value="1">MD/SDoC</option>
														<option key={2} value="2">Declaration</option>
													</select>
													{!isStringEmpty(errorMessages.errorDocumentType) && (
														<div className="invalid-dropdown-icon-Suppliermodal">
															<img
																src="images\icon material-error.png"
																alt="invalid"
															></img>
														</div>
													)}
												</div>
											</div>
										</div>

										{documentTypeId == 1 &&
											<div className="doc-gray-box">
												<div className="row">
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="" className="required-field">MD Number:</label>
															<div className={!isStringEmpty(errorMessages.errorMdNumber) ? "invalid-position" : ""}>
																<input className="form-control" type="text"
																	name="mdNumber" value={mdNumber} onChange={this.onTextChange} />

																{!isStringEmpty(errorMessages.errorMdNumber)
																	&& <ErrorView errorFor="emptyText" />
																}
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="" className="required-field">SDoC Number:</label>

															<div className={!isStringEmpty(errorMessages.errorSdocNumber) ? "invalid-position" : ""}>
																<input className="form-control" type="text" name="sdocNumber" value={sdocNumber} onChange={this.onTextChange} />
																{!isStringEmpty(errorMessages.errorSdocNumber)
																	&& <ErrorView errorFor="emptyText" />
																}
															</div>

														</div>
													</div>
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">MD Issue Date:</label>
															<div className={!isStringEmpty(errorMessages.errorMDIssueDate) ? "invalid-position" : ""}>

																<DatePicker
																	min="0"
																	selectedDate={mdIssueDate}
																	onDateChange={(e) => this.onDateChanged("mdIssueDate", e)}
																	isNotRoot={true}
																>
																	{" "}
																</DatePicker>
																{!isStringEmpty(errorMessages.errorMDIssueDate)
																	&& <ErrorView errorFor="emptyText" />
																}
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">SDoC Issue Date:</label>
															<div className={!isStringEmpty(errorMessages.errorSDocIssueDate) ? "invalid-position" : ""}>
																<DatePicker
																	min="0"
																	selectedDate={sdocIssueDate}
																	onDateChange={(e) => this.onDateChanged("sdocIssueDate", e)}
																	isNotRoot={true}
																>
																	{" "}
																</DatePicker>

																{!isStringEmpty(errorMessages.errorSDocIssueDate)
																	&& <ErrorView errorFor="emptyText" />
																}
															</div>
														</div>
													</div>

													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">Maker Name:</label>
															<input className="form-control" type="text" name="markerName" value={markerName} onChange={this.onTextChange} />
														</div>
													</div>
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">Product Name:</label>
															<input className="form-control" type="text" name="productName" value={productName} onChange={this.onTextChange} />
														</div>
													</div>

													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">Objects of Declaration:</label>
															<input className="form-control" type="text" name="objectOfDeclaration" value={objectOfDeclaration} onChange={this.onTextChange} />
														</div>
													</div>
													<div className="col-md-4">
														<div className="doc-form-group">
															<label htmlFor="">Remarks:</label>
															<input className="form-control" type="text" name="Remarks" value={Remarks} onChange={this.onTextChange} />
														</div>
													</div>
													<div className="col-md-12">
														<label className="label-breakword" style={{ overflowWrap: 'anywhere', margin: '10px' }}> {fileName}</label>
													</div>

												</div>
											</div>
										}

										{documentTypeId == 2 &&
											<div>
												<div className="row">
													<div className="col-md-12">
														<div className="declaration-checkbox-group">
															<label className="required-field">Document Type</label>
														</div>
													</div>
												</div>

												<div className="row">
													<div className="col-md-4">
														<div className="doc-declaration-checkbox">
															<input type="checkbox" name="DeclarationOfConformity" style={{ cursor: "pointer" }} id="inlineCheckbox1" value={DeclarationOfConformity} onChange={(e) => this.checkBoxClick(e)} />
															<label htmlFor="check1" style={{ paddingLeft: "4px" }}> Declaration of Conformity</label>
														</div>
													</div>

													<div className="col-md-8">
														<div className="doc-declaration-checkbox">
															<input type="checkbox" name="DeclarationOfAsbestosFree" style={{ cursor: "pointer" }} id="inlineCheckbox2" value={DeclarationOfAsbestosFree} onChange={(e) => this.checkBoxClick(e)} />
															<label htmlFor="check2" style={{ paddingLeft: "4px" }}> Declaration of Asbestos Free</label>
														</div>
													</div>

												</div>

												<div className="row">
													<div className="col-md-4">
														<div className="doc-declaration-checkbox">
															<input type="checkbox" name="DeclarationOfMSDS" style={{ cursor: "pointer" }} id="inlineCheckbox3" value={DeclarationOfMSDS} onChange={(e) => this.checkBoxClick(e)} />
															<label htmlFor="check3" style={{ paddingLeft: "4px" }}> Declaration of MSDS</label>
														</div>
													</div>

													<div className="col-md-8">
														<div className="doc-declaration-checkbox">
															<input type="checkbox" name="Miscellaneous" style={{ cursor: "pointer" }} id="inlineCheckbox4" value={Miscellaneous} onChange={(e) => this.checkBoxClick(e)} />
															<label htmlFor="check4" style={{ paddingLeft: "4px" }}> Miscellaneous</label>
														</div>
													</div>

												</div>

												{listDeclarationData != undefined &&
													listDeclarationData.length > 0 ? (
													listDeclarationData.map((item, index) => {
														return (

															<div className="doc-gray-box" key={index}>
																<div className="row">
																	<div className="col-md-12">
																		<label>{item.name == "DeclarationOfConformity" ? "Declaration of Conformity" : item.name == "DeclarationOfAsbestosFree" ? "Declaration of Asbestos Free" : item.name == "DeclarationOfMSDS" ? "Declaration of MSDS" : "Miscellaneous"}</label>
																	</div>
																</div>
																<div className="row">
																	<div className="col-md-4">
																		<div className="doc-form-group">
																			<label htmlFor="">Date of Declaration:</label>
																			<input
																				type="name"
																				className="form-control input-validation"
																				id="DeclarationDate"
																				name="DeclarationDate"
																				value={DateOfDeclaration}
																				readOnly
																			/>
																		</div>
																	</div>
																	<div className="col-md-4">
																		<div className="doc-form-group">
																			<label htmlFor="">Items Declared For:</label>
																			<input className="form-control"
																				type="text"
																				id="ItemsDeclaredFor"
																				name="ItemsDeclaredFor"
																				value={item.ItemsDeclaredFor}
																				list="addLineItemMdsdoc-options"
																				onChange={(e) => this.onAddLineItemDescriptionTextChange(e, item.name)} />
																			<datalist id="addLineItemMdsdoc-options">
																				{tableABList == null
																					? null
																					: tableABList.map(
																						(item, key, index) => {
																							return (
																								<option
																									value={item.label}
																								></option>
																							);
																						}
																					)}

																			</datalist>
																		</div>
																	</div>
																	<div className="col-md-4">
																		<div className="doc-form-group" >
																			<div
																				className={(item.isErrorpresent) ? "invalid-position" : ""}
																			>
																				<label htmlFor="" className="required-field">Expiry Date:</label>
																				<DatePicker
																					isNotRoot={true}
																					min="0"
																					selectedDate={item.ExpiryDate}
																					onDateChange={(e) => this.onDeclarationDateChange(e, item.name, "ExpiryDate")}
																					minDate={item.nextCurrentDate}
																				>
																					{" "}
																				</DatePicker>
																				{(item.isErrorpresent) && (
																					<div className="invalid-dropdown-icon" style={{ right: "13px", top: "28px" }}>
																						<img
																							src="images\icon material-error.png"
																							alt="invalid"
																						></img>
																					</div>
																				)}

																			</div>
																		</div>
																	</div>
																	<div className="col-md-4">
																		<div className="doc-form-group">
																			<label htmlFor="">Remarks:</label>
																			<input className="form-control" type="text" name="Remarks" value={item.Remarks} onChange={(e) => this.onTextDeclartionChange(e, item.name)} />
																		</div>
																	</div>

																	<div className="col-md-12">
																		<label style={{ overflowWrap: 'anywhere', margin: '10px' }}>&nbsp; {item.fileName}</label>
																	</div>

																</div>
															</div>

														);
													})
												) : null}

												{/*  */}
											</div>}

										{showErrorMessage && (
											<div className="invalid-message-icon-box">
												<img
													src="images/icon material-error.png"
													alt="invalid-icon"
												/>
												<p>Please fill in all the mandatory fields.</p>
											</div>
										)}
										<div className="upload-btn">
											<button type="button" className="btn btn-background-color document-detail-upload-btn" onClick={this.onSaveClick}>Submit</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
			</>
		);
	}
}

export default withRouter(LinkDocumentModal);