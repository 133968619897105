import webserviceWrapper from "../helpers/webserviceWrapper";


export const ShipService = {
    GetManageShipData,
    ChangeStatus,
    GetShipDropdownData,
    AddEditShipDetails,
    CheckIMOPrimaryEmail,
    AddShipDocData,
    GetShipDocumentData,
    DownloadShipDoc,
    DeleteShipDocData,
    GetSystemGeneratedPassword,
    CheckProjectNumberUnique,
    AddPortOfRegistryData,
    SaveShipCrewChangeRequestData,
    GenerateNewPassword,
    ActivateDeactivateShip,
    AddShipDocType,
    CheckIfDocTypeExist
};


function GetManageShipData(clientId, userId, clientUserId, userTypeId, pageSize, pageNumber) {
    return webserviceWrapper.get(`/Ship/GetManageShipData?clientId=${clientId}&userId=${userId}&clientUserId=${clientUserId}&userTypeId=${userTypeId}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
}
function AddShipDocType(typeName) {
    return webserviceWrapper.get(`/Ship/AddShipDocType?typeName=${typeName}`);
}
function CheckIfDocTypeExist(typeName) {
    
    return webserviceWrapper.post(`/Ship/CheckIfDocTypeExist?typeName=${typeName}`);
}
function GetSystemGeneratedPassword(shipId) {
    return webserviceWrapper.get(`/Ship/GetSystemGeneratedPassword?shipId=${shipId}`);
}

function ChangeStatus(shipId, status, clientId, userId, userTypeId) {
    return webserviceWrapper.get(`/Ship/ChangeStatus?shipID=${shipId}&status=${status}&clientId=${clientId}&userId=${userId}&userTypeId=${userTypeId}`);
}

function GetShipDropdownData() {
    return webserviceWrapper.get(`/Ship/GetShipDropdownData`);
}


function AddEditShipDetails(userData) {
    return webserviceWrapper.postWithHeader(`/Ship/AddEditShipDetails`, userData, {"Content-Type": "multipart/form-data",}, 600000);
}

function CheckIMOPrimaryEmail(imoNo, primaryEmail, shipId) {
    return webserviceWrapper.get(`/Ship/CheckIMOPrimaryEmail?imoNo=${imoNo}&primaryEmail=${primaryEmail}&shipId=${shipId}`);
}
function CheckProjectNumberUnique(projectNumberList, shipId) {
    return webserviceWrapper.get(`/Ship/CheckProjectNumberUnique?projectNumberList=${[projectNumberList]}&shipId=${shipId}`);
}

function AddShipDocData(userData) {
    return webserviceWrapper.postWithHeader(`/Ship/AddShipDocData`, userData, {"Content-Type": "multipart/form-data",}, 600000);
}

function GetShipDocumentData(shipId) {
    return webserviceWrapper.get(`/Ship/GetShipDocumentData?shipId=${shipId}`);
}

function DownloadShipDoc(tFileIDlist, shipId) {
    return webserviceWrapper.post(
        `/Ship/DownloadShipDoc?shipId=${shipId}`,
        tFileIDlist,
        600000
    );
}

function DeleteShipDocData(shipdocID, shipId) {
    return webserviceWrapper.get(`/Ship/DeleteShipDocData?shipdocId=${shipdocID}&shipId=${shipId}`);
}

function AddPortOfRegistryData(data) {
    return webserviceWrapper.post(`/Ship/AddPortOfRegistryData`, data);
}

function SaveShipCrewChangeRequestData(shipChangeRequestData) {
    return webserviceWrapper.postWithHeader(`/Ship/SaveShipCrewChangeRequestData`, shipChangeRequestData, {"Content-Type": "multipart/form-data",}, 600000);
}

function GenerateNewPassword(supplierId, roleid, username) {
    return webserviceWrapper.get(`/Ship/GenerateNewPassword?supplierId=${supplierId}&roleId=${roleid}&userName=${username}`);
}

function ActivateDeactivateShip(shipId, loggedInUserID, params, shipEmailAdress) {
    return webserviceWrapper.get(`/Ship/ActivateDeactivateShip?shipId=${shipId}&loggedInUserID=${loggedInUserID}&action=${params}&shipEmailAdress=${shipEmailAdress}`);
}