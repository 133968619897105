import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { TrainingService } from "../../services/training.service";
import admindownload from "../../assets/images/admin-download.png";
import admintrackingdownload from "../../assets/images/admin-tracking-download.png";
import admineye from "../../assets/images/admin-eye.png";
import deleteIcon from "../../assets/images/delete icon.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, helperText, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Toast from "../../components/Toast";
import UploadDataFile from "./UploadDataFile";
import ViewModal from "../../components/ViewModal";
import DeleteModal from "../../components/DeleteModel";
import { UserAction, Module } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { CommonService } from "../../services/common.service";

var resetPageSize = "notReset"
var totalPOCount = 0;

class Training extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappedTrainingDocs: [],
      defaultTrainingDocs: [],
      dropdownList: [],
      //   colDefs: this.gridColumns,
      isLoading: false,
      toastVisible: false,
      toastType: "",
      toastMessage: "",
      showUploadModal: false,
      filePathToView: "",
      showViewModal: false,
      trainingDocIDList: [],

      dropdown_client: "0",
      dropdown_ship: "0",
      dropdown_region: "0",
      dropdown_documentType: "0",
      dropdown_documentName: "0",
      dropdown_applicableTo: "0",
      dropdown_applicableToValue: "",
      showDeleteModal: false,
      rowIdToDelete: 0,
      accessList: [],
      defaultShipNameList: [],
      shipNameList: [],
      loggedInUserID: 0,
      typeId: 0,
      gridApi: null,
      userRolePermissionRead: false,
      searchedText: "",
      pageSize: 100,
      pageNumber: 1,
      hasMoreData: true,
      isLoad: false, count: 0,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  onSendEmailClicked = (e) => {
    AddLocalStorageData("location", "/Training");
    this.state.userRolePermissionRead
      ? e.preventDefault()
      : this.props.navigate("/Communication");
  };
  onSaveNewTypeClick = async (typeName) => {

    this.setState({
      isLoading: true,
    });

    var docData = {
      TypeName: typeName,
      Module: Module.Training
    }
    const {
      pageSize,
      pageNumber
    } = this.state;
    const page = pageNumber;

    var responseSaved =
      await CommonService.AddNewDocumentType(docData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData('selecteddoctype', responseSaved.data)
      const response = await TrainingService.GetTrainingData(
        this.state.loggedInUserID,
        this.state.typeId,
        pageSize,
        page
      );
      if (
        response != null &&
        response != undefined &&
        response.status == 200
      ) {
        this.setState({
          dropdownList: response.data.dropdownList,
          isLoading: false,
        });
      }
      else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  gridColumns = () => [
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      unSortIcon: false,
      filter: false,
    },
    {
      headerName: "Serial Number",
      //   field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Document Type",
      field: "documentType",
      tooltipField: "documentType",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },
    {
      headerName: "Document Name",
      field: "customFileName",
      tooltipField: "customFileName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 300,
    },
    {
      headerName: "Client",
      field: "client",
      tooltipField: "client",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 150,
    },
    {
      headerName: "Ship",
      field: "ship",
      tooltipField: "ship",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 150,
    },
    {
      headerName: "Region",
      field: "region",
      tooltipField: "region",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 150,
    },
    {
      headerName: "Applicable To",
      field: "applicableTo",
      tooltipField: "applicableTo",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 150,
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 120,
      cellRenderer: (params) => (
        <a
          onClick={(e) => {
            this.onSendEmailClicked(e);
          }}
          style={{
            cursor: "pointer",
            opacity: this.state.userRolePermissionRead ? "0.6" : "1",
          }}
          disabled={this.state.userRolePermissionRead}
        >
          Send Email
        </a>
      ),
      cellStyle: { textAlign: "center" }
    },
    {
      headerName: "Date of Creation",
      field: "creationDate",
      tooltipField: "creationDate",
      width: 150,
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admineye}
          title="View"
          onClick={() => this.viewTrainingDocData(params.data.trainingDocsId)}
          width="20px"
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={admindownload}
          title="Download"
          onClick={() =>
            this.downloadTrainingDocData(params.data.trainingDocsId)
          }
          width="15px"
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      hide: this.state.userRolePermissionRead,
      cellRenderer: (params) => (
        // (this.state.defaultTrainingDocs.length != 0 ? !(this.state.defaultTrainingDocs.filter((x) => x.documentTypeId === params.data.documentTypeId).length === 1) : true) ?
        <img
          className="cursor-class"
          src={deleteIcon}
          title="Delete"
          style={{ opacity: this.state.userRolePermissionRead ? "0.6" : "1" }}
          onClick={() => {
            this.state.userRolePermissionRead
              ? ""
              : this.onDeleteModalOpenClick(params.data.trainingDocsId);
          }}
          width="13px"
        />
        // :
        // <></>
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        {
          loggedInUserID: userData.id,
          typeId: userData.typeId,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.getTrainingData();
        }
      );
    }
  }

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  viewTrainingDocData = (id) => {
    var filterList = [];
    filterList = this.state.defaultTrainingDocs.filter(
      (x) => x.trainingDocsId == parseInt(id, 10)
    );
    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };

  deleteTrainingDocData = async (id) => {
    try {
      const { loggedInUserID, typeId } = this.state;

      this.setState({
        isLoading: true,
      });
      var response = await TrainingService.DeleteTrainingDocData(id, loggedInUserID, typeId);
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError
        });
      } else if (response != null) {
        this.setState(
          {
            mappedTrainingDocs: response.data.trainingdocs,
            defaultTrainingDocs: response.data.trainingdocs,
            dropdownList: response.data.dropdownList,
            isLoading: false,
            showDeleteModal: false,
            toastVisible: true,
            toastMessage: helperText.global.deletedDocument,
            toastType: helperText.global.toastSuccess,
          },
          () => { }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  getTrainingData = () => {
    if (this.state.isLoad || !this.state.hasMoreData) return;
    const {
      pageSize,
      pageNumber
    } = this.state;
    const page = pageNumber;
    this.setState(
      {
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.trainingAccess == UserAction.READ,
      },
      async () => {
        const response = await TrainingService.GetTrainingData(
          this.state.loggedInUserID,
          this.state.typeId,
          pageSize,
          page
        );
        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          const newData = response.data.trainingdocs;
          debugger;
          totalPOCount = response?.data?.totalCount ?? 0;
          this.setState({ count: totalPOCount }),
            localStorage.setItem("Count", totalPOCount);

          debugger;
          let hasMoreData = false;
          let count=totalPOCount;
          // if (newData.length === 200)
          //   hasMoreData = true;
          // else
          //   hasMoreData = newData.length === pageSize;

          this.setState((prevState) => ({
            mappedTrainingDocs: [...prevState.mappedTrainingDocs, ...response.data.trainingdocs],
            defaultTrainingDocs: [...prevState.defaultTrainingDocs, ...response.data.trainingdocs],
            dropdownList: response.data.dropdownList,
            isLoading: false,
            defaultShipNameList: response.data.dropdownList.shipList,
            shipNameList: response.data.dropdownList.shipList,
            pageNumber: prevState.pageNumber + 1,
            hasMoreData: [...prevState.mappedTrainingDocs, ...response.data.trainingdocs].length<count,
          }),
            () => {
              this.filterGridView();
            }
          );
        } else if (response.status == 401) {
          Logout(this.props.navigate);
        } else {
          this.setState({
            isLoading: false,
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            defaultShipNameList: [],
            shipNameList: [],
          });
        }
      }
    );
  };

  onFilterSelected = (e) => {
    const { defaultShipNameList } = this.state;
    var { name, value } = e.target;

    if (e.target.name == "dropdown_client") {
      if (e.target.value != "0") {
        var filteredShipList = defaultShipNameList.filter(
          (x) => x.parentValue == e.target.value
        );

        this.setState({ shipNameList: filteredShipList }); //, regionList: filteredYearList
        if (filteredShipList.length == 0) this.setState({ dropdown_ship: 0 });
      } else {
        this.setState({ shipNameList: defaultShipNameList });
      }
      resetPageSize = "Reset";
      totalPOCount = 0;
    }
    this.setState(
      {
        [name]: value,
        hasMoreData: true,
        pageNumber: 1,
      },
      () => {
        this.filterGridView();
      }
    );
  };
  onSortChanged(e) {
    e.api.refreshCells();
  }
  filterGridView = () => {
    const {
      dropdown_client,
      dropdown_ship,
      dropdown_region,
      dropdown_documentType,
      dropdown_documentName,
      dropdown_applicableTo,
      dropdown_applicableToValue,
      searchedText
    } = this.state;

    var filterList = [];
    var trainingList = this.state.defaultTrainingDocs;

    if (
      dropdown_client == "0" &&
      dropdown_ship == "0" &&
      dropdown_region == "0" &&
      dropdown_documentType == "0" &&
      dropdown_documentName == "0" &&
      dropdown_applicableTo == "0"
    )
      filterList = this.state.defaultTrainingDocs;
    else {
      var isFilterAdded = false;
      if (dropdown_client != "0") {
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (x) => x.clientId == parseInt(dropdown_client, 10)
            )
            : trainingList.filter(
              (x) => x.clientId == parseInt(dropdown_client, 10)
            );
        isFilterAdded = true;
      }
      if (dropdown_ship != "0") {
        filterList = isFilterAdded
          ? filterList.filter((x) => x.shipId == parseInt(dropdown_ship, 10))
          : trainingList.filter((x) => x.shipId == parseInt(dropdown_ship, 10));
        isFilterAdded = true;
      }
      if (dropdown_region != "0") {
        filterList = isFilterAdded
          ? filterList.filter(
            (x) => x.regionId == parseInt(dropdown_region, 10)
          )
          : trainingList.filter(
            (x) => x.regionId == parseInt(dropdown_region, 10)
          );
        isFilterAdded = true;
      }
      if (dropdown_documentType != "0") {
        filterList = isFilterAdded
          ? filterList.filter(
            (x) => x.documentTypeId == parseInt(dropdown_documentType, 10)
          )
          : trainingList.filter(
            (x) => x.documentTypeId == parseInt(dropdown_documentType, 10)
          );
        isFilterAdded = true;
      }
      if (dropdown_documentName != "0") {
        var documentName = this.state.dropdownList.documentNameList.filter(
          (x) => x.value == parseInt(dropdown_documentName, 10)
        );
        filterList = isFilterAdded
          ? filterList.filter((e) =>
            e.customFileName.includes(documentName[0].label)
          )
          : trainingList.filter((e) =>
            e.customFileName.includes(documentName[0].label)
          );
        isFilterAdded = true;
      }

      if (dropdown_applicableTo != "0") {
        var applicabletoname = this.state.dropdownList.applicableToList.filter(
          (x) => x.value == parseInt(dropdown_applicableTo, 10)
        );
        filterList = isFilterAdded
          ? filterList.filter((e) =>
            e.applicableTo.includes(applicabletoname[0].label)
          )
          : trainingList.filter((e) =>
            e.applicableTo.includes(applicabletoname[0].label)
          );
        isFilterAdded = true;
      }
    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.client.toString().toLowerCase().includes(value) ||
          t.ship.toString().toLowerCase().includes(value) ||
          t.region.toString().toLowerCase().includes(value) ||
          t.documentType.toString().toLowerCase().includes(value) ||
          t.customFileName.toString().toLowerCase().includes(value) ||
          t.applicableTo.toString().toLowerCase().includes(value) ||
          t.creationDate.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedTrainingDocs: filterList,
    });
  };

  onSearch = (e) => {
    var inputValue = e.target.value;
    var clientList = this.state.defaultClient;

    var inputValue = e.target.value;
    this.setState({
      searchedText: inputValue,
    }, () => {
      this.filterGridView();
    });
    // var { name, value } = e.target;
    // this.setState(
    //   {
    //     [name]: value,
    //   },
    //   () => {
    //     var trainingList = this.state.defaultTrainingDocs;
    //     value = value.toString().toLowerCase();
    //     trainingList = trainingList.filter(
    //       (t) =>
    //         t.client.toString().toLowerCase().includes(value) ||
    //         t.ship.toString().toLowerCase().includes(value) ||
    //         t.region.toString().toLowerCase().includes(value) ||
    //         t.documentType.toString().toLowerCase().includes(value) ||
    //         t.customFileName.toString().toLowerCase().includes(value) ||
    //         t.applicableTo.toString().toLowerCase().includes(value)
    //     );

    //     this.setState({
    //       mappedTrainingDocs: trainingList,
    //     });
    //   }
    // );
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  toggleModal = (name, value) => {
    this.setState({ showUploadModal: false });
  };

  downloadTrainingDocData = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      let tFileIDlist = [];
      if (id == 0) {
        this.state.trainingDocIDList.forEach((element) => {
          var tId = element;
          tFileIDlist.push(tId);
        });
      } else {
        tFileIDlist.push(id);
      }
      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }
      const response = await TrainingService.DownloadTrainingDoc(tFileIDlist);
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          if (element != null) {
            var lastItem = element.split("/").pop();
            fname = lastItem;

            const link = document.createElement("a");
            // link.href = element;
            link.setAttribute("href", element);
            link.setAttribute("download", element);

            var ext = element.substring(element.lastIndexOf(".") + 1);
            if (ext == "pdf" || ext == "PDF") {
              link.setAttribute("target", "_blank");
            } else {
              link.setAttribute("target", "");
            }
            link.download = fname;
            document.body.appendChild(link);
            link.click();
          }
        });
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
      date: 15/11/2022
      Name: RM
      description: method invoked for adding training docs
      <summary> */
  onUploadFile = async (data) => {
    const { loggedInUserID, dropdown_client, dropdown_year, typeId } =
      this.state;
    this.setState({
      showUploadModal: false,
      isLoading: true,
    });
    let trainigData = new FormData();
    trainigData.append("TrainingDocID", data.trainingDocID);
    trainigData.append("RegionID", data.dropdown_region);
    trainigData.append("ClientID", data.dropdown_client);
    trainigData.append("ShipID", data.dropdown_ship);
    trainigData.append("DocumentTypeID", data.dropdown_documentType);
    trainigData.append("DocumentName", data.input_documentName.trim());
    trainigData.append(
      "ApplicableTo",
      JSON.stringify(data.dropdown_applicableTo)
    );
    trainigData.append("TFile", data.TFile);
    trainigData.append("typeId", typeId);
    trainigData.append("LoggedInUserID", loggedInUserID);
    var responseSaved = await TrainingService.AddTrainingDocsData(trainigData);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.getTrainingData();
      this.setState({
        showUploadModal: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.documnetUploadedSuccess,
        isLoading: false,
      });
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
    });
  };

  onDeleteModalYesClick = () => {
    this.deleteTrainingDocData(this.state.rowIdToDelete);
  };

  onDeleteModalCloseClick = () => {
    this.setState({
      showDeleteModal: false,
    });
  };

  /* <summary>
        date: 18/01/2023
        Name: SYJ
        description: On Grid Delete Icon Click.
        <summary> */
  onDeleteModalOpenClick = (id) => {
    this.setState({
      showDeleteModal: true,
      rowIdToDelete: id,
    });
  };

  viewPOFile = (filePath) => {
    this.setState({
      filePathToView: filePath,
      showViewModal: true,
    });
  };

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedTTId = [];
      selectedRows.forEach((element) => {
        listSelectedTTId.push(
          element.trainingDocsId
        ); /*SYJ -18/01/2023 - wrong id error Fixed*/
      });
      this.setState({
        trainingDocIDList: listSelectedTTId,
      });
    } else {
      this.setState({
        trainingDocIDList: [],
      });
    }
  };

  OnUploadBtnClicked = () => {
    this.setState({ showUploadModal: true });
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess
      })
    } catch (e) {
    }
  };

  /* <summary>
 date: 13/02/2023
 Name: HV
 description: clearing filter
 <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        dropdown_documentName: "0",
        dropdown_documentType: "0",
        dropdown_ship: "0",
        dropdown_region: "0",
        dropdown_applicableTo: "0",
        dropdown_client: "0",
        shipNameList: this.state.defaultShipNameList,
        searchedText: "",
        hasMoreData: true,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  goback = () => {
    var loc = GetLocalStorageDataWithoutParse("location");
    if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else {
      this.props.navigate("/Dashboard");
    }
  }

  onScroll = () => {
    debugger
    if (!this.state.isLoading && this.state.hasMoreData) {
      this.getTrainingData();
    }
  };

  render() {
    const {
      mappedTrainingDocs,
      defaultTrainingDocs,
      dropdownList,
      colDefs,
      isLoading,
      toastVisible,
      toastType,
      toastMessage,
      showUploadModal,
      showViewModal,
      filePathToView,
      rowIdToDelete,
      showDeleteModal,
      dropdown_documentName,
      dropdown_documentType,
      dropdown_ship,
      dropdown_region,
      dropdown_applicableTo,
      dropdown_client,
      accessList,
      shipNameList,
      hasMoreData,
      count,
    } = this.state;

    const Buttons = (
      <div
        className="col-12 col-md-4 col-lg-3  training-add-button"
        style={{ marginTop: "38px" }}
      >
        <div className="float-btn  manage-users-main-btns">
          <button
            type="button"
            disabled={
              accessList != null
                ? accessList.trainingAccess == UserAction.READ
                  ? true
                  : false
                : false
            }
            onClick={() => this.setState({ showUploadModal: true })}
            className="btn btn-background-color  manage-template-responsive btn-padding btn-responsive manage-roles-btn manage-users-btn"
          >
            Upload Document
          </button>
          {mappedTrainingDocs != undefined && mappedTrainingDocs.length > 0 ? (
            <span>
              <img
                title="Multi Download"
                className="cursor-class"
                src={admintrackingdownload}
                onClick={() => this.downloadTrainingDocData(0)}
                style={{ width: "28px" }}
              />
            </span>
          ) : null}
        </div>
      </div>
    );

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"Training"} />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Training File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}

        <main className="page-height">
          <section className="manage-supplier">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    {/* <a onClick={() => this.props.navigate("/Dashboard")}> */}
                    <img src="images/back.png" alt="Back" onClick={this.goback} />
                    {/* </a> */}
                    <h5>Training</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div>
                    <Searchbar
                      placeholder={"Document Name, etc."}
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
              {/* mobile code */}
              <div className="training-mobile">
                <div className="accordion-filter-dropdown">
                  <div className="accordion" id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="supplier-filter">
                            <div className="row">
                              <div className="col-lg-12 filter-flex-container wrap">
                                <div className="filter-box filter-flex-item ">
                                  <MobileDropdown
                                    dropdownLabel="Document Type:"
                                    name="documentType"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_documentType}
                                  >
                                    {dropdownList.documentTypeList == null
                                      ? null
                                      : dropdownList.documentTypeList.filter(x => x.label != "Other").map(
                                        (obj, key) => (
                                          <option key={key} value={obj.value}>
                                            {obj.label}
                                          </option>
                                        )
                                      )}
                                    ;
                                  </MobileDropdown>
                                </div>
                                <div className="filter-box filter-flex-item ">
                                  <MobileDropdown
                                    dropdownLabel="Document Name:"
                                    name="documentName"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_documentName}
                                  >
                                    {dropdownList.documentNameList == null
                                      ? null
                                      : dropdownList.documentNameList.map(
                                        (obj, key) => (
                                          <option key={key} value={obj.value}>
                                            {obj.label}
                                          </option>
                                        )
                                      )}
                                    ;
                                  </MobileDropdown>
                                </div>
                                <div className="filter-box filter-flex-item">
                                  <MobileDropdown
                                    dropdownLabel="Client:"
                                    name="client"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_client}
                                  >
                                    {dropdownList.clientList == null
                                      ? null
                                      : dropdownList.clientList.map(
                                        (obj, key) => (
                                          <option key={key} value={obj.value}>
                                            {obj.label}
                                          </option>
                                        )
                                      )}
                                    ;
                                  </MobileDropdown>
                                </div>

                                <div className="filter-box filter-flex-item ">
                                  <MobileDropdown
                                    dropdownLabel="Ship:"
                                    name="ship"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_ship}
                                    style={{
                                      textOverflow: "ellipsis",
                                      whiteSpace: "pre",
                                      overflow: "hidden",
                                    }}
                                  >
                                    {shipNameList == null
                                      ? null
                                      : shipNameList.map((item, key) => (
                                        <option key={key} value={item.value}>
                                          {item.label}
                                        </option>
                                      ))}
                                    ;
                                  </MobileDropdown>
                                </div>
                                <div className="filter-box filter-flex-item ">
                                  <MobileDropdown
                                    dropdownLabel="Region:"
                                    name="region"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_region}
                                  >
                                    {dropdownList.regionList == null
                                      ? null
                                      : dropdownList.regionList.map(
                                        (obj, key) => (
                                          <option key={key} value={obj.value}>
                                            {obj.label}
                                          </option>
                                        )
                                      )}
                                    ;
                                  </MobileDropdown>
                                </div>
                                <div className="filter-box filter-flex-item ">
                                  <MobileDropdown
                                    dropdownLabel="Applicable To:"
                                    name="applicableTo"
                                    onChange={this.onFilterSelected}
                                    selected={dropdown_applicableTo}
                                  >
                                    {dropdownList.applicableToList == null
                                      ? null
                                      : dropdownList.applicableToList.map(
                                        (obj, key) => (
                                          <option key={key} value={obj.value}>
                                            {obj.label}
                                          </option>
                                        )
                                      )}
                                    ;
                                  </MobileDropdown>
                                </div>
                                <div
                                  className="col-12 col-md-4 col-lg-3 "
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    type="button"
                                    className="btn btn-blue-bg"
                                    onClick={this.clearAllFilter}
                                    style={{ marginTop: "23px" }}
                                  >
                                    Reset
                                  </button>
                                </div>
                                {Buttons}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* mobile code */}
              {/* desktop code */}
              <div className="training-desktop">
                <div className="desktop-class">
                  <div className="supplier-filter">
                    <div className="row">
                      <div className="col-md-3 col-12" id={"docType"}>
                        <Dropdown
                          dropdownLabel="Document Type:"
                          name="documentType"
                          onChange={this.onFilterSelected}
                          selected={dropdown_documentType}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.documentTypeList == null
                            ? null
                            : dropdownList.documentTypeList.filter(x => x.label != "Other").map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_documentType > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"docType"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.documentTypeList != null && dropdownList.documentTypeList.filter(x => x.value == dropdown_documentType)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-md-3 col-12" id={"docName"}>
                        <Dropdown
                          dropdownLabel="Document Name:"
                          name="documentName"
                          onChange={this.onFilterSelected}
                          selected={dropdown_documentName}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.documentNameList == null
                            ? null
                            : dropdownList.documentNameList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_documentName > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"docName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.documentNameList != null && dropdownList.documentNameList.filter(x => x.value == dropdown_documentName)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-md-3 col-12" id={"clientName"}>
                        <Dropdown
                          dropdownLabel="Client:"
                          name="client"
                          onChange={this.onFilterSelected}
                          selected={dropdown_client}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.clientList == null
                            ? null
                            : dropdownList.clientList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_client > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.clientList != null && dropdownList.clientList.filter(x => x.value == dropdown_client)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div className="col-md-3 col-12" id={"shipName"}>
                        <Dropdown
                          dropdownLabel="Ship:"
                          name="ship"
                          onChange={this.onFilterSelected}
                          selected={dropdown_ship}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {shipNameList == null
                            ? null
                            : shipNameList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_ship > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"shipName"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {shipNameList != null && shipNameList.filter(x => x.value == dropdown_ship)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div
                        className="col-md-3 col-12"
                        style={{ marginTop: "15px" }}
                        id={"region"}
                      >
                        <Dropdown
                          dropdownLabel="Region:"
                          name="region"
                          onChange={this.onFilterSelected}
                          selected={dropdown_region}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.regionList == null
                            ? null
                            : dropdownList.regionList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_region > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"region"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.regionList != null && dropdownList.regionList.filter(x => x.value == dropdown_region)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div
                        className="col-md-3 col-12"
                        style={{ marginTop: "15px" }}
                        id={"applicableTo"}
                      >
                        <Dropdown
                          dropdownLabel="Applicable To:"
                          name="applicableTo"
                          onChange={this.onFilterSelected}
                          selected={dropdown_applicableTo}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {dropdownList.applicableToList == null
                            ? null
                            : dropdownList.applicableToList.map((obj, key) => (
                              <option key={key} value={obj.value}>
                                {obj.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_applicableTo > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"applicableTo"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {dropdownList.applicableToList != null && dropdownList.applicableToList.filter(x => x.value == dropdown_applicableTo)[0]?.label}
                          </UncontrolledTooltip>) : null}
                      </div>
                      <div
                        className="col-12 col-md-4 col-lg-3 "
                        style={{ alignSelf: "center", marginTop: "15px" }}
                      >
                        <button
                          type="button"
                          className="btn btn-blue-bg"
                          onClick={this.clearAllFilter}
                          style={{ marginTop: "23px" }}
                        >
                          Reset
                        </button>
                      </div>
                      {Buttons}
                    </div>
                  </div>
                </div>
                {/*</div>* grid Code for manage supplier */}
                <div className="training-grid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <AGGrid
                          rowData={mappedTrainingDocs}
                          colDefs={this.gridColumns()}
                          onGridReady={this.onGridReady}
                          rowSelection="multiple"
                          onSelectionChanged={(e) => this.onSelectionChanged(e)}
                          autoHeight={false}
                          onSortChanged={(e) => this.onSortChanged(e)}
                          pagination="100"
                          onScroll={this.onScroll}
                          lazyLoading={true}
                          hasMoreData={hasMoreData}
                          resetLoadedPages={hasMoreData}
                          totalCount={totalPOCount}
                          stateCount={count}
                          isLazyPage={true}
                          update={"training"}
                          isTraining={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {showUploadModal && (
          <UploadDataFile
            onCloseClick={this.toggleModal}
            clientList={this.state.dropdownList.clientList}
            shipList={this.state.dropdownList.shipList}
            onUploadClick={this.onUploadFile}
            dropdownList={this.state.dropdownList}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
          />
        )}

        {showDeleteModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteModalCloseClick}
            onYesClick={this.onDeleteModalYesClick}
            onNoClick={this.onDeleteModalCloseClick}
            idToDelete={rowIdToDelete}
            modalheader="Delete Training Item"
          ></DeleteModal>
        )}
        <Footer />
      </>
    );
  }
}

export default withRouter(Training);
