import React, { Component } from "react";
import Header from "../../components/Header.jsx";
import Footer from "../../components/Footer.jsx";
import DatePicker from "../../components/DatePickerComponent";
import ViewModal from "../../components/ViewModal";
import Loader from "../../components/Loader";
import DirtyModal from "../../components/DirtyModal";
import { isStringEmpty, convertDate } from "../../helpers/string-helper";
import editIcon from "../../assets/images/profile-feather-edit.png";
import { changePageTitle, withRouter, isEmailValid, AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse } from "../../helpers";
import { ShipService } from "../../services/ship.service";
import Toast from "../../components/Toast";
import Logout from "../../helpers/Logout";
import AGGrid from "../../components/AGGrid";
import Dropdown from "../../components/Dropdown";
import UploadFileModal from "./UploadFileModal";
import admindownload from "../../assets/images/admin-download.png";
import admineye from "../../assets/images/admin-eye.png";
import historyIcon from "../../assets/images/admin-history.png";
import xIcon from "../../assets/images/cancel-icon.png";
import ViewHistoryModal from "./ViewHistoryModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { TitlePageNames, AddNewValue } from "../../helpers/enums.js";
import PortRegistryModal from "./PortRegistryModal";
import { UserAction, UserRole } from "../../helpers/enums";
import ClientChangeRequestModal from "../ClientShipPortal/ClientChangeRequestModal.jsx";
import ProceedModal from "../../components/ProceedModal.jsx";
import { helperText, isValidFile } from "../../helpers";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import { CommonService } from "../../services/common.service";
import addProfileIcon from "../../assets/images/plus.png";
import { UserService } from "../../services/user.service";
import deleteIcon from "../../assets/images/delete.png";
import DeleteModal from "../../components/DeleteModel";
import { UncontrolledTooltip } from "reactstrap";

const NUMBER_REGEX = /^[0-9]{0,9}$/;
const PHONE_NUMBER_REGEX = /^[0-9()+-\s]+$/;
const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9()\-./]*$/;
const IHM_Part1_REGEX = /^[a-zA-Z0-9.]*$/;
const GROSS_TONAGE_REGEX = /^[0-9]*$/;

const defaultAddNewItem = { label: "Add New", value: "0" };
const validFileFormatsReadable = "image/png, image/jpeg, image/jpg";

const validFileFormats = ["png, jpg, jpeg"];
const ErrorView = (props) => {
  return (
    <>
      {props.errorFor != "" && (
        <div
          className={
            props.errorFor == "emptyDropdown"
              ? "invalid-dropdown-icon"
              : "invalid-message-icon"
          }
        >
          <img src="images\icon material-error.png" alt="invalid" />
        </div>
      )}
    </>
  );
};

class AddEditShip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      dropdownList: {},
      clientId: 0,
      clientName: "",
      disableSave: false,
      loggedInUserID: 0,
      toastMessage: null,
      toastType: "",
      toastVisible: false,
      errorMessages: {},
      showErrorMessage: false,
      userName: "",
      showPassword: false,
      shipTypeList: [],
      classificationSocietyList: [],
      portRegistryList: [],
      shipBuilderNameList: [],
      shipOwnerNameList: [],
      part1SocList: [],
      statComplianceList: [],
      serviceCategoryList: [],
      ihmmInchargeList: [],
      alternateIhmmInchargeList: [],
      mappedDocuments: [],
      colDefs: this.gridColumns,
      //ship detail field states
      shipId: 0,
      shipName: "",
      shipIMONo: "",
      shipEmailAdress: "",
      dropdown_typeOfShip: "",
      shipDistinctiveNo: "",
      classNumber: "",
      dropdown_portofregistry: "",
      dropdown_classificationSociety: "",
      grossTonnage: "",
      principalDimensions: "",
      //dropdown_shipBuilderName: "",
      //dropdown_shipOwnerName: "",
      shipbuilderAddress: "",
      shipbuilderContactNo: "",
      shipOwnerAddress: "",
      shipOwnerContactNo: "",

      commPrimaryEmailaddress: "",
      commSecondaryEmailaddress: "",
      dateKeelLaid: "",
      dateConstruction: "",

      //client details
      ownerName: "",
      ownerIdNo: "",
      ownerAdress: "",
      companyIdNo: "",
      ownerEmailAdress: "",

      ownerContactNo: "",
      ihmIdNo: "",
      picName: "",
      picDesignation: "",
      picEmail: "",
      picContactNo: "",
      //ihm particulars
      reportNo: "",
      issuedBy: "",
      ihmPart1Approved: "",
      socEUExpiryDate: "",
      socEUIssueDate: "",
      socHKCExpiryDate: "",
      socHKCIssueDate: "",
      expiryDate: "",
      dateRecycling: "",
      issueDate: "",
      latestUpdateDate: "",
      dropdown_statecompliance: "",
      dropdown_part1soc: "",
      isActive: true,
      stateComplianceHKC: "",
      stateComplianceEU: "",
      ihmPart3: "",
      ihmPart2: "",
      //maritec reference data
      projectNumber: "",
      emailList: [],
      secondaryEmailDetails: [],
      password: "",
      profilePictureUrl: "",
      fileInputKey: this.generateRandomString(),
      fileProfilePath: "",
      fileProfileName: "",
      documentProfileFile: "",
      imoNoExists: false,
      primaryEmailExists: false,
      showModal: false,
      documentTypeList: [],
      flagStateList: [],
      showViewModal: false,
      filePathToView: "",
      showHistoryModal: false,
      filesBasedDocumentType: [],
      allFiles: [],
      selectedDocListIds: [],
      dateRangeErrorHKC: false,
      dateRangeErrorEU: false,
      showConfirmationPopup: false,
      selectdDocDeleteId: 0,
      listMaritecData: [],
      test: [],
      ihm:
        GetLocalStorageDataWithoutParse("selectedShip") != 0 &&
          GetLocalStorageDataWithoutParse("selectedShip") != null &&
          GetLocalStorageDataWithoutParse("selectedShip") != ""
          ? false
          : true,
      ihmm:
        GetLocalStorageDataWithoutParse("selectedShip") != 0 &&
          GetLocalStorageDataWithoutParse("selectedShip") != null &&
          GetLocalStorageDataWithoutParse("selectedShip") != ""
          ? false
          : true,
      abestos: false,
      others: false,
      projectNumberError: false,
      object: [
        {
          shipServiceCategoryBridgeId: null,
          serviceCategoryId: null,
          projectNumber: "",
          contractDate: "",
          expiryDate: "",
          dropdown_inChargeUserId: 0,
          dropdown_alternateinChargeUserId: 0,
          year: "",
          name: "",
        },
      ],
      showPortRegistryModal: false,
      shipBuilderName: "",
      shipOwnerName: "",
      originalPortOfRegList: [],
      isClient: false,
      isShipCrew: false,
      disableFields: false,
      showRequestModal: false,
      requestType: "",
      showProceedModal: false,
      showErrorMessageEmail: false,
      showErrorMessageDuplicateEmail: false,
      clientUserId: 0,
      isClientOrClientUser: false,
      maritecEmptyError: false,
      shipProfileFileMediaId: 0,
      userTypeId: 0,
      shipClientCode: GetLocalStorageDataWithoutParse("selectedClientCode"),
      saveButtonClick: false,
      accessList: [],
      userRolePermissionRead: false,
      showChangePasswordConfirmationModal: false,
      showActivateDeactivateConfirmationModal: false,
      isAccountActivated: false,
      showErrorEmailAcctivation: false,
      showDirtyPopup: false,
      path: "",
      shortEmployeeNameError: false,
      isProfileImgDeleted: false,
      euAutoCount: 0,
      hkcAutoCount: 0,
      euManualCount: 0,
      hkcManualCount: 0,
    };
  }

  /* <summary>
   Date: 21/03/2023
   Name: HV
   Description: passed filemediaid instaed shipdocumnetbridgeid
   <summary> */
  viewShipDocData = (id) => {
    var filterList = [];
    filterList = this.state.allFiles.filter(
      (x) => x.fileMediaId == parseInt(id, 10)
    );

    var path = filterList[0].filePath;
    this.setState({ showViewModal: true, filePathToView: path });
  };
  onDirtyModalCloseClick = () => {
    this.setState({
      showDirtyPopup: false,
    });
  };
  onProceedClick = () => {
    this.setState(
      {
        showDirtyPopup: false,
      },
      () => {
        AddLocalStorageData("isDirty", false);
        if (this.state.path != "") this.props.navigate(this.state.path);
      }
    );
  };
  viewHistory = (id) => {
    const { filesBasedDocumentType, allFiles } = this.state;
    var list = allFiles
      .filter((x) => x.documentTypeId == id)
      .sort(function (a, b) {
        return new Date(b.OriginalCreatedOn) - new Date(a.OriginalCreatedOn);
      });
    list.shift();
    this.setState({ filesBasedDocumentType: list }, () => {
      this.setState({ showHistoryModal: true });
    });
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /* <summary>
   date: 6/1/2023
   Name: NGG
   description: ag grid column data
   <summary> */
  gridColumns = (disableFields) => {
    return [
      {
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        unSortIcon: false,
      },
      {
        headerName: "Document Name",
        field: "documentName",
        tooltipField: "documentName",
        width: 250,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Version",
        field: "versionNumber",
        tooltipField: "versionNumber",
        width: 150,
      },
      {
        headerName: "Document Type",
        field: "documentTypeName",
        tooltipField: "documentTypeName",
        width: 190,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Revised Document",
        field: "isRevisedValue",
        tooltipField: "isRevisedValue",
        width: 190,
      },
      {
        headerName: "Issue Date",
        field: "issueDate",
        tooltipField: "issueDate",
        width: 150,
      },
      {
        headerName: "Expiry Date",
        field: "expiryDate",
        tooltipField: "expiryDate",
        width: 150,
      },
      {
        headerName: "Issued By",
        field: "issuedByName",
        tooltipField: "issuedByName",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Approved By",
        field: "approvedBy",
        tooltipField: "approvedBy",
        width: 160,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 60,
        sortable: false,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admineye}
            title="View"
            onClick={() => this.viewShipDocData(params.data.fileMediaId)}
            style={{ width: "20px" }}
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 60,
        sortable: false,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={admindownload}
            title="Download"
            onClick={() =>
              this.downloadShipDocData(
                params.data.fileMediaId,
                params.data.shipId
              )
            }
            width="20px"
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: false,
        headerName: "",
        width: 60,
        sortable: false,
        cellRenderer: (params) =>
          (
            this.state.allFiles.length != 0
              ? !(
                this.state.allFiles.filter(
                  (x) => x.documentTypeId === params.data.documentTypeId
                ).length === 1
              )
              : true
          ) ? (
            <img
              className="cursor-class"
              src={historyIcon}
              title="History"
              width="20px"
              onClick={() => this.viewHistory(params.data.documentTypeId)}
            />
          ) : (
            <></>
          ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        width: 60,
        sortable: false,
        hide: disableFields,
        cellRenderer: (params) =>
        (
          <img
            className="cursor-class"
            src={deleteIcon}
            onClick={() =>
              this.showDeleteDocModal(
                params.data.fileMediaId,
                params.data.shipId
              )
            }
            width="15px"
            title="Delete"
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
    ];
  };

  componentDidMount = async () => {
    try {
      //HV - 20/02/2023 - changing browser title name
      changePageTitle(TitlePageNames.SHIPDETAILS);
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      if (userData != null) {
        var clientData = GetLocalStorageDataWithParse("selectedClient");
        if (clientData != null) {
          this.setState({
            clientId: clientData?.clientId || clientData?.clientID,
            clientName: clientData?.clientName,
            clientUserId:
              userData.typeId == UserRole.CLIENTUSER ? userData.id : 0,
            username: userData.name,
          });
        }
        this.setState(
          {
            loggedInUserID: userData.id,
            isClient: UserRole.CLIENT == userData.typeId,
            isShipCrew: userData.typeId == UserRole.SHIPCREW,
            isClientOrClientUser:
              UserRole.CLIENT == userData.typeId ||
              userData.typeId == UserRole.CLIENTUSER,
            disableFields:
              UserRole.CLIENT == userData.typeId ||
              userData.typeId == UserRole.SHIPCREW ||
              userData.typeId == UserRole.CLIENTUSER,
            accessList: GetLocalStorageDataWithParse("userRolePermission"),
            userTypeId: userData.typeId,
          },
          () => {
            this.getDropdownData(true);
          }
        );
      }
    } catch (error) { }
  };

  /* <summary>
   Date: 21/03/2023
   Name: HV
   Description: passed filemediaid instaed shipdocumnetbridgeid
   <summary> */
  downloadShipDocData = async (fileMediaId, shipId) => {
    try {
      this.setState({
        isLoading: true,
      });
      let tFileIDlist = [];
      if (fileMediaId == 0) {
        this.state.selectedDocListIds.forEach((element) => {
          var tId = element;
          tFileIDlist.push(tId);
        });
      } else {
        tFileIDlist.push(fileMediaId);
      }

      if (tFileIDlist.length == 0) {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.noDocumentsSelected,
        });
        return;
      }
      const response = await ShipService.DownloadShipDoc(tFileIDlist, shipId);
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;
          const link = document.createElement("a");
          // link.href = element;
          link.setAttribute("href", element);
          link.setAttribute("download", element);

          var ext = element.substring(element.lastIndexOf(".") + 1);
          if (ext == "pdf" || ext == "PDF") {
            link.setAttribute("target", "_blank");
          } else {
            link.setAttribute("target", "");
          }
          document.body.appendChild(link);
          link.download = fname;
          link.click();
        });
        //	document.body.removeChild( link );
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 16/1/2023
   Name: NGG
   description: downloads the document 
   <summary> */
  deleteShipDocData = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      var response = await ShipService.DeleteShipDocData(
        this.state.selectdDocDeleteId,
        this.state.shipId
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      } else if (response != null) {
        this.setState({
          mappedDocuments: response.data.shipDocumentGroupModel,
          allFiles: response.data.allShipDocumentList,
          toastVisible: true,
          toastMessage: helperText.global.deletedDocument,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 11/1/2023
   Name: NGG
   description: Show hide password
   <summary> */
  togglePassword = () => {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  };

  /* <summary>
   date: 11/1/2023
   Name: NGG
   description: Get all ship document data
   <summary> */
  getShipDocumentsData = async (shipId, closeModal) => {
    const response = await ShipService.GetShipDocumentData(shipId);

    if (response.data != null && response.data != undefined) {
      if (response.status == 200) {
        this.setState(
          {
            mappedDocuments: response.data.shipDocumentGroupModel,
            allFiles: response.data.allShipDocumentList,
            toastVisible: closeModal ? true : false,
            toastMessage: closeModal
              ? helperText.global.documnetUploadedSuccess
              : "",
            toastType: closeModal ? helperText.global.toastSuccess : "",
            isLoading: false,
          },
          () => {
            if (closeModal) this.showUploadModal();
          }
        );
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  /* <summary>
   date: 11/1/2023
   Name: NGG
   description: Get system generated password
   <summary> */
  getSystemGeneratedPasswordForNewUser = async () => {
    const { loggedInUserID, shipId } = this.state;
    this.setState({ isLoading: true });
    const response = await ShipService.GetSystemGeneratedPassword(shipId);
    if (response != null && response != undefined && response.status == 200) {
      this.setState({ password: response.data.password });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
      });
    }
    this.setState({ isLoading: false });
  };

  /* <summary>
   date: 11/1/2023
   Name: NGG
   description: Changes password which is system generated.
   <summary> */
  //onChangePasswordClick = async () => {
  //this.getSystemGeneratedPasswordForNewUser();
  //};

  onChangePasswordClick = async (setUsername) => {
    try {
      const { shipId } = this.state;
      this.setState({ isLoading: true });
      // if (shipId == 0) {
      //   this.setState({
      //     toastVisible: true,
      //     toastType: helperText.global.toastError,
      //     toastMessage: helperText.UserProfile.saveUserDataToChangePassword,
      //     showChangePasswordConfirmationModal: false,
      //   });
      // }
      var userData = GetLocalStorageDataWithParse("loggedInUser");
      var roleid = 0;
      var username = "";
      if (userData != null) {
        roleid = userData.typeId;
        username = userData.name;
      }
      var response = await ShipService.GenerateNewPassword(
        shipId,
        roleid,
        username
      );
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      } else if (response != null) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200 && response.data != null) {
          this.setState({
            password: response.data,
            toastVisible: setUsername != null ? true : false,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.Client.clientUpdated,
          });
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
      this.setState({
        showChangePasswordConfirmationModal: false,
        isLoading: false,
      });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  /* <summary>
   date: 9/1/2023
   Name:NGG
   description: Get data to bind on the add/edit ship screen
   <summary> */
  getShipDetailsData = async (shipId) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { clientId, loggedInUserID, clientUserId, userTypeId } = this.state; //, userTypeId
      const response = await ShipService.GetManageShipData(
        clientId,
        loggedInUserID,
        clientUserId,
        userTypeId,0,0   
      );
      console.log("responseeeeeeee",response)
      //, userTypeId
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          await this.BindShipDetailsData(
            response.data.lstShipModel,
            response.data.lstShipService
          );
        }
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      // this.setState({
      //   isLoading: false,
      // });
    } catch (error) { }
  };

  /* <summary>
     date: 6/1/2023
     Name: NGG
     description: Get dropdown  data to bind to the dropdown fields
     <summary> */
  getDropdownData = async (bindata) => {
    this.setState({
      isLoading: true,
      userRolePermissionRead:
        this.state.accessList != null &&
        this.state.accessList.clientAndShipAccess == UserAction.READ,
    });
    const response = await ShipService.GetShipDropdownData();

    if (response != null && response != undefined && response.status == 200) {
      var shipDropdownData = response.data;

      var portRegistryList = shipDropdownData.portOfRegistryList;
      const defaultAddNewOtherItem = { label: "OTHER", value: "100" };
      portRegistryList.push(defaultAddNewOtherItem);
      portRegistryList.push(defaultAddNewItem);

      var shipBuilderNameList = Array.from([
        ...new Set(shipDropdownData.shipBuilderNameList.map((l) => l.label)),
      ]);

      var shipOwnerNameList = Array.from([
        ...new Set(shipDropdownData.shipOwnerNameList.map((l) => l.label)),
      ]);

      this.setState(
        {
          originalPortOfRegList: shipDropdownData.portOfRegistryList,
          shipTypeList: shipDropdownData.shipTypeList,
          classificationSocietyList: shipDropdownData.classificationSocietyList,
          portRegistryList: portRegistryList,
          shipBuilderNameList: shipBuilderNameList,
          shipOwnerNameList: shipOwnerNameList,
          statComplianceList: shipDropdownData.statementComplianceList,
          serviceCategoryList: shipDropdownData.serviceCategoryList,
          ihmmInchargeList: shipDropdownData.ihmmInChargeList,
          alternateIhmmInchargeList: shipDropdownData.ihmmInChargeList,
          flagStateList: shipDropdownData.flagStateList,
          documentTypeList: shipDropdownData.documentTypeList,
          //    isLoading: false,
        },
        async () => {
          const { object } = this.state;
          if (bindata) {
            let shipId = parseInt(GetLocalStorageDataWithoutParse("selectedShip"), 10);
            if (shipId > 0 || this.state.saveButtonClick) {
              if (this.state.saveButtonClick) {
                await this.getShipDetailsData(this.state.shipId);
                await this.getShipDocumentsData(this.state.shipId, false);
              } else {
                await this.getShipDetailsData(shipId);
                await this.getShipDocumentsData(shipId, false);
              }
            } else {
              this.onChangePasswordClick(null);
            }
          }
          this.setState({
            saveButtonClick: false,
          });
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  /* <summary>
   date: 9/1/2023
   Name: NGG
   description: binds data to add/edit ship screen
   <summary> */
  BindShipDetailsData = async (shipDetailsData, shipServiceDetails) => {
    try {
      var defaultShipServiceList = [];
      const { object } = this.state;
      this.setState({
        isLoading: true,
      });
      shipServiceDetails = shipServiceDetails.filter(
        (x) => x.shipId == GetLocalStorageDataWithoutParse("selectedShip")
      );
      if (shipServiceDetails.length != 0) {
        let list = [];

        list = shipServiceDetails.map((x) => {
          if (x.name == "ihm") {
            this.setState({ ihm: true });
          } else if (x.name == "ihmm") {
            this.setState({ ihmm: true });
          } else if (x.name == "abestos") {
            this.setState({ abestos: true });
          } else if (x.name == "others") {
            this.setState({ others: true });
          }
          return {
            ...x,
            contractDate: new Date(x.contractDate),
            expiryDate: new Date(x.expiryDate),
          };
        });

        this.setState({ listMaritecData: list });
      }
      shipDetailsData = shipDetailsData.filter(
        (x) => x.shipId == GetLocalStorageDataWithoutParse("selectedShip")
      );

      if (shipDetailsData != null) {
        let selectedShipData = shipDetailsData[0];
        this.setState({
          shipId: selectedShipData?.shipId,
          isAccountActivated: selectedShipData?.isActivated ?? "",
          shipName: selectedShipData?.shipName ?? "",
          shipIMONo: selectedShipData?.imono ?? "",
          shipClientCode: selectedShipData?.clientCode ?? "",
          shipEmailAdress: selectedShipData?.shipEmailAddress ?? "",
          //dropdown_typeOfShip: shipDetailsData[0].shipTypeName,
          shipDistinctiveNo: selectedShipData?.shipDistinctiveNo ?? "",
          classNumber: selectedShipData?.classNumber ?? "",
          dropdown_portofregistry: selectedShipData?.portOfRegistryId ?? "",
          dropdown_classificationSociety:
            selectedShipData?.classificationSocietyId ?? "",
          grossTonnage:
            selectedShipData?.grossTonnage == null
              ? ""
              : selectedShipData?.grossTonnage ?? "",
          principalDimensions: selectedShipData?.principalDimensions ?? "",
          shipbuilderAddress: selectedShipData?.shipBuilderAddress ?? "",
          shipbuilderContactNo: selectedShipData?.shipBuilderContactNo ?? "",
          shipOwnerAddress: selectedShipData?.shipOwnerAddress ?? "",
          shipOwnerContactNo: selectedShipData?.shipOwnerContactNo ?? "",
          shipBuilderName: selectedShipData?.shipBuilderName ?? "",
          shipOwnerName: selectedShipData?.shipOwnerName ?? "",

          ownerName: selectedShipData?.imoregisteredOwnerName ?? "",
          ownerIdNo: selectedShipData?.imoregisteredOwnerIdentficationNo ?? "",
          ownerAdress: selectedShipData?.imoregisteredOwnerAddress ?? "",
          companyIdNo: selectedShipData?.imocompanyIdentificationNo ?? "",
          ownerEmailAdress: selectedShipData?.imoregisteredEmailAddress ?? "",

          ownerContactNo: selectedShipData?.imoregisteredContactNo ?? "",
          ihmIdNo: selectedShipData?.ihmidentificationNo ?? "",
          picName: selectedShipData?.shipIhmpicName ?? "",
          picDesignation: selectedShipData?.shipIhmpicDesignation ?? "",
          picEmail: selectedShipData?.shipIhmpicEmailAddress ?? "",
          picContactNo: selectedShipData?.shipIhmpicContactNo ?? "",
          projectNumber: selectedShipData?.projectNumber ?? "",
          isActive: selectedShipData?.isActive ?? "",
          dropdown_typeOfShip: selectedShipData?.shipTypeId ?? "",
          dropdown_statecompliance: selectedShipData?.statementOfComplianceId ?? "",
          dateConstruction:
            selectedShipData?.dateConstruction == null
              ? ""
              : new Date(selectedShipData?.dateConstruction) ?? "",
          dateKeelLaid:
            selectedShipData?.dateKeelLaid == null
              ? ""
              : new Date(selectedShipData?.dateKeelLaid) ?? "",
          commPrimaryEmailaddress: selectedShipData?.shipOwnerEmailAdress ?? "",
          password: selectedShipData?.password ?? "",
          profilePictureUrl: selectedShipData?.shipProfilePictureUrl ?? "",
          shipProfileFileMediaId: selectedShipData?.shipProfileFileMediaId ?? "",

          reportNo: selectedShipData?.ihmpart1ReportNo ?? "",
          issuedBy: selectedShipData?.ihmpart1SocissuedBy ?? "",
          ihmPart1Approved:
            selectedShipData?.ihmpart1Approved == null
              ? ""
              : selectedShipData?.ihmpart1Approved == true
                ? "Yes"
                : "No",
          socEUExpiryDate:
            selectedShipData?.ihmpart1SoceuexpiryDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1SoceuexpiryDate) ?? "",
          socEUIssueDate:
            selectedShipData?.ihmpart1SoceuissuedDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1SoceuissuedDate) ?? "",
          socHKCExpiryDate:
            selectedShipData?.ihmpart1SochkcexpiryDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1SochkcexpiryDate) ?? "",
          socHKCIssueDate:
            selectedShipData?.ihmpart1SochkcissuedDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1SochkcissuedDate) ?? "",

          dateRecycling:
            selectedShipData?.recyclingDate == null
              ? ""
              : new Date(selectedShipData?.recyclingDate) ?? "",
          issueDate:
            selectedShipData?.ihmpart1IssueDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1IssueDate) ?? "",
          latestUpdateDate:
            selectedShipData?.ihmpart1UpdateLatestDate == null
              ? ""
              : new Date(selectedShipData?.ihmpart1UpdateLatestDate) ?? "",

          stateComplianceHKC:
            selectedShipData?.statementOfComplianceHkc == null
              ? ""
              : selectedShipData?.statementOfComplianceHkc == true
                ? "Yes"
                : "No",
          stateComplianceEU:
            selectedShipData?.statementOfComplianceEu == null
              ? ""
              : selectedShipData?.statementOfComplianceEu == true
                ? "Yes"
                : "No",
          ihmPart3:
            selectedShipData?.ihmpart3 == null
              ? ""
              : selectedShipData?.ihmpart3 == true
                ? "Yes"
                : "No",
          ihmPart2:
            selectedShipData?.ihmpart2 == null
              ? ""
              : selectedShipData?.ihmpart2 == true
                ? "Yes"
                : "No",
          secondaryEmailDetails:
            (selectedShipData?.communicationSecondaryEmailAddress != null)
              ? selectedShipData?.communicationSecondaryEmailAddress.split(",")
              : [],

          euAutoCount: selectedShipData?.euexpiryAutomaticCount,
          hkcAutoCount: selectedShipData?.hkcexpiryAutomaticCount,
          euManualCount: selectedShipData?.euexpiryManualCount == null ? 0 : selectedShipData?.euexpiryManualCount,
          hkcManualCount: selectedShipData?.hkcexpiryManualCount == null ? 0 : selectedShipData?.hkcexpiryManualCount,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) {
    }
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
   date: 9/1/2023
   Name: NGG
   description: Sets the dropdown values
   <summary> */
  onDropdownItemSelect = async (e) => {
    var { name, value } = e.target;

    if (
      e.target.name == "dropdown_portofregistry" &&
      parseInt(value, 10) == parseInt(AddNewValue.AddNew, 10)
    ) {
      this.setState({
        showPortRegistryModal: true,
      });
    } else {
      const { showErrorMessage } = this.state;

      this.setState(
        {
          [name]: value,
          showPortRegistryModal: false,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (e.target.name == "dropdown_classificationSociety") {
            this.setState({ ihmPart1Approved: value == 0 ? "" : "Yes" });
          }
          if (showErrorMessage) {
            this.validateFields();
          }
        }
      );
    }
  };

  /* <summary>
   date: 7/1/2023
   Name: NGG
   description: Validates the required fields
   <summary> */
  validateFields = () => {
    const {
      shipName,
      shipIMONo,
      shipEmailAdress,
      dropdown_typeOfShip,
      shipDistinctiveNo,
      dropdown_portofregistry,
      dropdown_statecompliance,
      dateConstruction,
      projectNumber,
      errorMessages,
      commPrimaryEmailaddress,
      stateComplianceHKC,
      stateComplianceEU,
      socEUExpiryDate,
      socEUIssueDate,
      socHKCIssueDate,
      socHKCExpiryDate,
      ihmPart2,
      ihmPart3,
      dateRecycling,
      dropdown_classificationSociety,
      ihmPart1Approved,
      showErrorMessageEmail,
      showErrorMessageDuplicateEmail,
      ihm,
      ihmm,
      abestos,
      others,
      listMaritecData,
    } = this.state;
    let errors = errorMessages;
    var dateConst =
      dateConstruction == "" || dateConstruction == null
        ? ""
        : convertDate(dateConstruction);
    var dateRangeCheck = false;

    var shipValue = "";
    shipValue = shipName;

    errors["errorShipName"] =
      isStringEmpty(shipName) || shipValue.trim().length < 3 ? "true" : "";

    errors["errorShipIMONo"] = shipIMONo == 0 ? "true" : "";
    errors["errorTypeOfShip"] = dropdown_typeOfShip == 0 ? "true" : "";
    errors["errorPortofRegistry"] = dropdown_portofregistry == 0 ? "true" : "";
    //errors["errorStateCompliance"] = dropdown_statecompliance == 0 ? "true" : "";
    //errors["errorProjectNumber"] = isStringEmpty(projectNumber) ? "true" : "";
    // errors["errorShipOwnerEmailAdress"] = isStringEmpty(commPrimaryEmailaddress)
    //   ? "true"
    //   : "";
    errors["errorEmailExists"] = shipEmailAdress != "" &&
      shipEmailAdress.trim() == commPrimaryEmailaddress.trim() ? "true" : "";

    if (stateComplianceHKC == "Yes") {
      errors["errorHKCIssueDate"] =
        socHKCIssueDate == "" || socHKCIssueDate == undefined ? "true" : "";
      errors["errorHKCExpiryDate"] =
        socHKCExpiryDate == "" || socHKCExpiryDate == undefined ? "true" : "";
      if (
        errors["errorHKCIssueDate"] == "" &&
        errors["errorHKCExpiryDate"] == ""
      ) {
        var date1 = new Date(socHKCIssueDate);
        var date2 = new Date(socHKCExpiryDate);

        if (date2 < date1) {
          this.setState({ dateRangeErrorHKC: true });
          dateRangeCheck = true;
        } else if (date1 > date2) {
          this.setState({ dateRangeErrorHKC: true });
          dateRangeCheck = true;
        } else {
          this.setState({ dateRangeErrorHKC: false });
        }
      }
    } else {
      errors["errorHKCIssueDate"] = "";
      errors["errorHKCExpiryDate"] = "";
    }

    if (stateComplianceEU == "Yes") {
      errors["errorEUIssueDate"] =
        socEUIssueDate == "" || socEUIssueDate == undefined ? "true" : "";
      errors["errorEUExpiryDate"] =
        socEUExpiryDate == "" || socEUExpiryDate == undefined ? "true" : "";

      if (
        errors["errorEUIssueDate"] == "" &&
        errors["errorEUExpiryDate"] == ""
      ) {
        var date1 = new Date(socEUIssueDate);
        var date2 = new Date(socEUExpiryDate);

        if (date2 < date1) {
          this.setState({ dateRangeErrorEU: true });
          dateRangeCheck = true;
        } else if (date1 > date2) {
          this.setState({ dateRangeErrorEU: true });
          dateRangeCheck = true;
        } else {
          this.setState({ dateRangeErrorEU: false });
        }
      }
    } else {
      errors["errorEUIssueDate"] = "";
      errors["errorEUExpiryDate"] = "";
    }

    //IHM PART1 APPROVED

    //if (ihmPart1Approved == "Yes") ihmPart1Approved == "Yes" && {
    errors["errorClassificationSociety"] =
      dropdown_classificationSociety == 0 ? "true" : "";
    //}

    if (ihmPart2 == "Yes" && ihmPart3 == "Yes") {
      errors["errorRecyclingDate"] =
        dateRecycling == "" || dateRecycling == undefined ? "true" : "";
    } else {
      errors["errorRecyclingDate"] = "";
    }
    if (
      // !isStringEmpty(errors["errorShipName"]) ||
      !isStringEmpty(errors["errorShipIMONo"]) ||
      !isStringEmpty(errors["errorShipEmailAdress"]) ||
      !isStringEmpty(errors["errorTypeOfShip"]) ||
      !isStringEmpty(errors["errorPortofRegistry"]) ||
      !isStringEmpty(errors["errorShipOwnerEmailAdress"]) ||
      !isStringEmpty(errors["errorRecyclingDate"]) ||
      !isStringEmpty(errors["errorClassificationSociety"]) ||
      !isStringEmpty(errors["errorHKCIssueDate"]) ||
      !isStringEmpty(errors["errorHKCExpiryDate"]) ||
      !isStringEmpty(errors["errorEUIssueDate"]) ||
      !isStringEmpty(errors["errorEUExpiryDate"])
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: true,
        showErrorMessageEmail: false,
        showErrorMessageDuplicateEmail: false,
      });
      return true;
    } else if (
      (!isStringEmpty(shipEmailAdress) && !isEmailValid(shipEmailAdress)) ||
      (!isStringEmpty(commPrimaryEmailaddress) &&
        !isEmailValid(commPrimaryEmailaddress))
    ) {
      errors["errorShipEmailAdress"] = !isStringEmpty(shipEmailAdress) && !isEmailValid(shipEmailAdress)
        ? "true"
        : "";
      errors["errorShipOwnerEmailAdress"] = !isStringEmpty(commPrimaryEmailaddress) && !isEmailValid(
        commPrimaryEmailaddress
      )
        ? "true"
        : "";
      this.setState({
        errorMessages,
        showErrorMessage: false,
        showErrorMessageEmail: true,
        showErrorMessageDuplicateEmail: false,
      });
      return true;
    } else if (
      !isStringEmpty(shipEmailAdress) &&
      !isStringEmpty(commPrimaryEmailaddress) &&
      errors["errorEmailExists"] != ""
    ) {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        showErrorMessageEmail: false,
        showErrorMessageDuplicateEmail: true,
      });
      return true;
    } else if (dateRangeCheck) {
      return false;
    }
    var ihmindex = 0;
    var ihmmindex = 0;
    var abestosindex = 0;
    var othersindex = 0;
    if (listMaritecData.length != 0) {
      for (var i = 0; i < listMaritecData.length; i++) {
        if (ihm == true && listMaritecData[i].name == "ihm") {
          ihmindex = i;
        } else if (ihmm == true && listMaritecData[i].name == "ihmm") {
          ihmmindex = i;
        } else if (abestos == true && listMaritecData[i].name == "abestos") {
          abestosindex = i;
        } else if (others == true && listMaritecData[i].name == "others") {
          othersindex = i;
        }
      }
    }

    if (
      ihm == true &&
      (!listMaritecData[ihmindex].hasOwnProperty("contractDate") ||
        !listMaritecData[ihmindex].hasOwnProperty(
          "dropdown_alternateinChargeUserId"
        ) ||
        !listMaritecData[ihmindex].hasOwnProperty("dropdown_inChargeUserId") ||
        !listMaritecData[ihmindex].hasOwnProperty("expiryDate") ||
        !listMaritecData[ihmindex].hasOwnProperty("projectNumber"))
      //|| !listMaritecData[ihmindex].hasOwnProperty("year")
    ) {
      this.setState({
        maritecEmptyError: true,
      });
      return true;
    } else if (
      ihmm == true &&
      (!listMaritecData[ihmmindex].hasOwnProperty("contractDate") ||
        !listMaritecData[ihmmindex].hasOwnProperty(
          "dropdown_alternateinChargeUserId"
        ) ||
        !listMaritecData[ihmmindex].hasOwnProperty("dropdown_inChargeUserId") ||
        !listMaritecData[ihmmindex].hasOwnProperty("expiryDate") ||
        !listMaritecData[ihmmindex].hasOwnProperty("projectNumber"))
      //|| !listMaritecData[ihmmindex].hasOwnProperty("year")
    ) {
      this.setState({
        maritecEmptyError: true,
      });
      return true;
    } else if (
      abestos == true &&
      (!listMaritecData[abestosindex].hasOwnProperty("contractDate") ||
        !listMaritecData[abestosindex].hasOwnProperty(
          "dropdown_alternateinChargeUserId"
        ) ||
        !listMaritecData[abestosindex].hasOwnProperty(
          "dropdown_inChargeUserId"
        ) ||
        !listMaritecData[abestosindex].hasOwnProperty("expiryDate") ||
        !listMaritecData[abestosindex].hasOwnProperty("projectNumber"))
      //|| !listMaritecData[abestosindex].hasOwnProperty("year")
    ) {
      this.setState({
        maritecEmptyError: true,
      });
      return true;
    } else if (
      others == true &&
      (!listMaritecData[othersindex].hasOwnProperty("contractDate") ||
        !listMaritecData[othersindex].hasOwnProperty(
          "dropdown_alternateinChargeUserId"
        ) ||
        !listMaritecData[othersindex].hasOwnProperty(
          "dropdown_inChargeUserId"
        ) ||
        !listMaritecData[othersindex].hasOwnProperty("expiryDate") ||
        !listMaritecData[othersindex].hasOwnProperty("projectNumber"))
      //|| !listMaritecData[othersindex].hasOwnProperty("year")
    ) {
      this.setState({
        maritecEmptyError: true,
      });
      return true;
    } else this.setState({ maritecEmptyError: false });

    if (errors["errorShipName"] != "") {
      this.setState({
        errorMessages,
        showErrorMessage: false,
        shortEmployeeNameError: true,
      });
      return true;
    } else {
      this.setState({
        shortEmployeeNameError: false,
      });
    }

    let hasError = false;

    if (!this.checkForDuplicateProjectNumbers()) {
      hasError = true;
    }
    if (this.checkForDuplicateProjectNumbers()) {
      hasError = false;
    }

    this.setState({
      errorMessages,
      showErrorMessage: false,
      showErrorMessageEmail: false,
      showErrorMessageDuplicateEmail: false,
      maritecEmptyError: false,
    });
    return hasError;
  };

  onModalCloseClick = () => {
    this.setState({
      showViewModal: false,
      showConfirmationPopup: false,
      showChangePasswordConfirmationModal: false,
      showActivateDeactivateConfirmationModal: false,
    });
  };

  onViewHistoryModalClose = () => {
    this.setState({ showHistoryModal: false });
  };

  /* <summary>
   date: 16/1/2023
   Name: NGG
   description: adds selected documents ids to list
   <summary> */
  onSelectionChanged = () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let listSelectedBridgeId = [];

      selectedRows.forEach((element) => {
        listSelectedBridgeId.push(element.fileMediaId);
      });
      this.setState({ selectedDocListIds: listSelectedBridgeId });
    } else {
      this.setState({
        selectedDocListIds: [],
      });
    }
  };
  onSaveNewTypeClick = async (typeName) => {
    this.setState({
      isLoading: true,
    });

    var responseSaved = await ShipService.AddShipDocType(typeName);
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      AddLocalStorageData("selecteddoctype", responseSaved.data);
      const response = await ShipService.GetShipDropdownData();

      if (response != null && response != undefined && response.status == 200) {
        var shipDropdownData = response.data;
        this.setState({
          documentTypeList: shipDropdownData.documentTypeList,
          isLoading: false,
        });
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };
  /* <summary>
   date: 9/1/2023
   Name: NGG
   description: Saves the add/edit ship details page data
   <summary> */
  onSave = async () => {
    const {
      shipId,
      shipName,
      shipIMONo,
      shipEmailAdress,
      dropdown_typeOfShip,
      shipDistinctiveNo,
      classNumber,
      dropdown_portofregistry,
      dropdown_classificationSociety,
      grossTonnage,
      principalDimensions,
      shipbuilderAddress,
      shipbuilderContactNo,
      shipOwnerAddress,
      shipOwnerContactNo,
      commPrimaryEmailaddress,
      commSecondaryEmailaddress,
      classificationSocietyList,
      //dropdown_shipBuilderName,
      //dropdown_shipOwnerName,
      dateConstruction,
      dateKeelLaid,
      ownerName,
      ownerIdNo,
      ownerAdress,
      companyIdNo,
      ownerEmailAdress,

      ownerContactNo,
      ihmIdNo,
      picName,
      picDesignation,
      picEmail,
      picContactNo,
      errorMessages,
      reportNo,
      socEUExpiryDate,
      socEUIssueDate,
      socHKCExpiryDate,
      socHKCIssueDate,
      expiryDate,
      issueDate,
      issuedBy,
      dropdown_statecompliance,
      dropdown_part1soc,
      projectNumber,
      disableSave,
      loggedInUserID,
      secondaryEmailDetails,
      isActive,
      emailList,
      password,
      profilePictureUrl,
      documentProfileFile,
      clientId,
      latestUpdateDate,
      ihmPart1Approved,
      stateComplianceHKC,
      stateComplianceEU,
      ihmPart2,
      ihmPart3,
      dateRecycling,
      listMaritecData,
      projectNumberError,
      shipBuilderName,
      shipOwnerName,
      isShipCrew,
      shipProfileFileMediaId,
      shipClientCode,
      username,
      isProfileImgDeleted,
      userTypeId,
      euManualCount,
      hkcManualCount,
    } = this.state;

    var commaSeparated = secondaryEmailDetails.toString();
    var hasErrors = this.validateFields();
    if (!hasErrors) {
      this.setState({
        isLoading: true,
        disableSave: false,
        showErrorMessage: false,
      });

      let shipData = new FormData();
      shipData.append("ShipId", shipId);
      shipData.append("ShipName", shipName);
      shipData.append("Imono", shipIMONo);
      shipData.append("ShipEmailAddress", shipEmailAdress);
      shipData.append("ShipTypeId", dropdown_typeOfShip);
      shipData.append("ShipDistinctiveNo", shipDistinctiveNo);
      shipData.append("ClassNumber", classNumber);
      shipData.append("PortOfRegistryId", dropdown_portofregistry);
      shipData.append(
        "ClassificationSocietyId",
        dropdown_classificationSociety
      );

      shipData.append("GrossTonnage", grossTonnage);
      shipData.append("PrincipalDimensions", principalDimensions);
      shipData.append("ShipBuilderAddress", shipbuilderAddress);
      shipData.append("ShipBuilderContactNo", shipbuilderContactNo);
      shipData.append("ShipOwnerAddress", shipOwnerAddress);
      shipData.append("ShipOwnerContactNo", shipOwnerContactNo);
      shipData.append(
        "CommunicationPrimaryEmailAdress",
        commPrimaryEmailaddress
      );

      shipData.append("ShipBuilderName", shipBuilderName);
      shipData.append("ShipOwnerName", shipOwnerName);

      var dateConst =
        dateConstruction == "" || dateConstruction == null
          ? ""
          : convertDate(dateConstruction);

      shipData.append("DateOfConstruction", dateConst);

      var dateKL = dateKeelLaid == "" ? "" : convertDate(dateKeelLaid);
      shipData.append("DateOfKeelLaid", dateKL);
      shipData.append("ImoregisteredOwnerName", ownerName);
      shipData.append("ClientCode", shipClientCode);

      shipData.append("ImoregisteredOwnerIdentficationNo", ownerIdNo);
      shipData.append("ImoregisteredOwnerAddress", ownerAdress);
      shipData.append("ImocompanyIdentificationNo", companyIdNo);
      shipData.append("ImoregisteredEmailAddress", ownerEmailAdress);

      shipData.append("ImoregisteredContactNo", ownerContactNo);
      shipData.append("IhmidentificationNo", ihmIdNo);
      shipData.append("ShipIhmpicName", picName);
      shipData.append("ShipIhmpicDesignation", picDesignation);
      shipData.append("ShipIhmpicEmailAddress", picEmail);
      shipData.append("ShipIhmpicContactNo", picContactNo);
      shipData.append("loggedInUserID", loggedInUserID);
      shipData.append("IsActive", isActive);
      shipData.append("CommunicationSecondaryEmailAddress", commaSeparated);
      var dateIssue = issueDate == "" ? "" : convertDate(issueDate);
      shipData.append("Ihmpart1IssueDate", dateIssue);
      shipData.append("Ihmpart1ReportNo", reportNo);

      var dateLatestUpdate =
        latestUpdateDate == "" ? "" : convertDate(latestUpdateDate);
      shipData.append("Ihmpart1UpdateLatestDate", dateLatestUpdate);
      shipData.append("Ihmpart1SocissuedBy", issuedBy);
      shipData.append("UserRole", userTypeId);
      shipData.append(
        "Ihmpart1SochkcissuedDate",
        socHKCIssueDate == "" ? "" : convertDate(socHKCIssueDate)
      );
      shipData.append(
        "Ihmpart1SochkcexpiryDate",
        socHKCExpiryDate == "" ? "" : convertDate(socHKCExpiryDate)
      );

      shipData.append(
        "Ihmpart1SoceuissuedDate",
        socEUIssueDate == "" ? "" : convertDate(socEUIssueDate)
      );

      shipData.append(
        "Ihmpart1SoceuexpiryDate",
        socEUExpiryDate == "" ? "" : convertDate(socEUExpiryDate)
      );

      shipData.append(
        "Ihmpart1Approved",
        ihmPart1Approved == "Yes" ? true : ihmPart1Approved == "No" ? false : ""
      );

      shipData.append(
        "StatementOfComplianceHkc",
        stateComplianceHKC == "Yes"
          ? true
          : stateComplianceHKC == "No"
            ? false
            : ""
      );
      shipData.append(
        "StatementOfComplianceEu",
        stateComplianceEU == "Yes"
          ? true
          : stateComplianceEU == "No"
            ? false
            : ""
      );

      shipData.append(
        "Ihmpart2",
        ihmPart2 == "Yes" ? true : ihmPart2 == "No" ? false : ""
      );
      shipData.append(
        "Ihmpart3",
        ihmPart3 == "Yes" ? true : ihmPart3 == "No" ? false : ""
      );

      shipData.append(
        "RecyclingDate",
        dateRecycling == "" ? "" : convertDate(dateRecycling)
      );

      shipData.append("StatementOfComplianceId", dropdown_statecompliance);
      //dropdown_part1soc,
      shipData.append("ProjectNumber", projectNumber);
      shipData.append("Password", password);
      shipData.append("clientID", clientId);
      var test = Array.prototype.slice.call(listMaritecData);
      shipData.append("listMaritecData", JSON.stringify(test));
      let fileProfile = documentProfileFile;

      if (
        fileProfile != "" &&
        fileProfile !== undefined &&
        !isProfileImgDeleted
      ) {
        shipData.append("ShipProfileAttached", fileProfile);
      }

      shipData.append(
        "ShipProfileFileMediaId",
        shipProfileFileMediaId == null || isProfileImgDeleted ? 0 : shipProfileFileMediaId
      );
      shipData.append("username", username);

      shipData.append("euManualCount", euManualCount);
      shipData.append("hkcManualCount", hkcManualCount);

      var response = await ShipService.CheckIMOPrimaryEmail(
        shipIMONo,
        shipEmailAdress,
        shipId
      );
      let errors = errorMessages;

      if (shipId == 0) {
        //check for unique imo number
        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          var emailExists = response.data;

          if (
            emailExists.item1 ||
            (emailExists.item2 && shipEmailAdress != "")
          ) {
            errors["errorShipIMONo"] = emailExists.item1 ? "true" : "";
            errors["errorShipEmailAdress"] =
              emailExists.item2 && shipEmailAdress != "" ? "true" : "";

            this.setState({
              imoNoExists: emailExists.item1 ? true : false,
              primaryEmailExists:
                emailExists.item2 && shipEmailAdress != "" ? true : false,
              errorMessages: errors,
              isLoading: emailExists.item1 || emailExists.item2 ? false : true,
            });
            return;
          } else {
            this.setState(
              {
                imoNoExists: false,
                primaryEmailExists: false,
              },
              () => {
                //this.setState({ isLoading: false });
              }
            );
          }
        }
      } else {
        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          var emailExists = response.data;
          if (emailExists.item2) {
            errors["errorShipEmailAdress"] = emailExists.item2 ? "true" : "";
            this.setState(
              {
                primaryEmailExists: emailExists.item2 ? true : false,
                errorMessages: errors,
              },
              () => {
                this.setState({
                  isLoading: emailExists.item2 ? false : true,
                });
              }
            );
            return;
          } else {
            this.setState(
              {
                primaryEmailExists: false,
              },
              () => {
                //this.setState({ isLoading: false });
              }
            );
          }
        }
      }
      if (listMaritecData.length != 0) {
        var projectNumberLst = listMaritecData.map((x) => {
          if (x.shipServiceCategoryBridgeId == null) {
            return x.projectNumber;
          }
        });

        if (projectNumberLst != "" && projectNumberLst != ",") {
          var response = await ShipService.CheckProjectNumberUnique(
            projectNumberLst.toString(),
            shipId
          );
          if (
            response != null &&
            response != undefined &&
            response.status == 200
          ) {
            if (response.data) {
              this.setState(
                {
                  projectNumberError: response.data ? true : false,
                },
                () => {
                  this.setState({ isLoading: response.data ? false : true });
                }
              );
              return;
            } else {
              this.setState({
                projectNumberError: false,
              });
            }
          }
        }
      }

      if (isProfileImgDeleted) {
        this.deleteClientShipProfileImage(
          this.state.shipId,
          UserRole.SHIPCREW,
          this.state.loggedInUserID
        );
        this.setState({
          isProfileImgDeleted: false,
        });
      }
      var response = await ShipService.AddEditShipDetails(shipData);
      if (response != null && response != undefined && response.status == 200) {
        AddLocalStorageData("selectedShip", response.data);
        AddLocalStorageData("isDirty", false);
        this.setState(
          {
            saveButtonClick: true,
            shipId: response.data,
            showErrorMessage: false,
          },
          async () => {
            await this.getDropdownData(true);
            setTimeout(() => {
              this.setState({
                toastVisible: true,
                toastType: helperText.global.toastSuccess,
                showErrorMessage: false,
                toastMessage:
                  shipId == 0
                    ? helperText.Ship.shipAdded
                    : helperText.Ship.shipUpdated,
              });
            }, 2000);
          }
        );
        //   () => {
        //  this.setState({ isLoading: false } //, () => {
        //   setTimeout(() => {
        //     //this.goback();
        //     this.props.navigate(
        //       isShipCrew ? "/ShipCrewDashboard" : "/ManageShips"
        //     );
        //   }, 1500);
        // }
        //   );
        // }
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: "fail",
          isLoading: false,
          disableSave: false,
        });
      }
    }
  };

  /* <summary>
   date: 8/6/2023
   Name: SYJ
   description: Check for Duplicate ProjectNumber in the listMaritecData list
   <summary> */
  checkForDuplicateProjectNumbers() {
    const { listMaritecData } = this.state;
    var retval = false;
    const projectNumbers = [];
    const duplicateProjectNumbers = [];

    for (let i = 0; i < listMaritecData.length; i++) {
      const x = listMaritecData[i];
      if (x.projectNumber !== null) {
        const projectNumber = x.projectNumber;
        if (projectNumbers.includes(projectNumber)) {
          duplicateProjectNumbers.push(projectNumber);
        }
        projectNumbers.push(projectNumber);
      }
    }

    // Check if duplicate project numbers exist
    if (duplicateProjectNumbers.length > 0) {
      // Handle the presence of duplicate project numbers
      this.setState({ projectNumberError: true });
      retval = false;
    } else {
      // No duplicate project numbers found
      this.setState({ projectNumberError: false });
      retval = true;
    }
    return retval;
  }

  /* <summary>
   date: /1/2023
   Name: NGG
   description: Sets the input field values
   <summary> */
  onTextChange = (e) => {
    const {
      showErrorMessage,
      showErrorMessageEmail,
      showErrorMessageDuplicateEmail,
      errorMessages,
      shortEmployeeNameError,
    } = this.state;
    const { name, value } = e.target;
    let errors = errorMessages;

    if (name == "shipEmailAdress") {
      errors["errorShipEmailAdress"] = "";
      this.setState({
        showErrorEmailAcctivation: false,
        errorMessages: errors,
        primaryEmailExists: false,
      });
    }
    if (name == "commPrimaryEmailaddress") {
      errors["errorShipOwnerEmailAdress"] = "";
      this.setState({
        showErrorMessage: false,
        showErrorMessageEmail: false,
        errorMessages: errors,
        primaryEmailExists: false,
      });
    }
    if (name == "shipIMONo") {
      errors["errorShipIMONo"] = "";
      this.setState({ imoNoExists: false, errorMessages: errors });
      if (!NUMBER_REGEX.test(value)) {
        return;
      }
    } else if (name == "grossTonnage") {
      if (!GROSS_TONAGE_REGEX.test(value)) {
        return;
      }
    }

    if (name == "euManualCount" || name == "hkcManualCount") {
      // Ensure the value is between 0 and 50
      const newValue = Math.min(Math.max(Number(value), 0), 50);

      if (value.length == 0)
        this.setState({ [name]: "", isDirty: true });
      else if (value.length > 2)
        this.setState({ [name]: value.substring(0, 2), isDirty: true });
      else if (Number.parseInt(value, 10) > 50) {
        this.setState({ [name]: "50", isDirty: true });
      }
      else if (Number.parseInt(value, 10) < 0) {
        this.setState({ [name]: "0", isDirty: true });
      } else {
        this.setState({ [name]: newValue, isDirty: true });
      }
      return;
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        AddLocalStorageData("isDirty", true);
        if (
          showErrorMessage ||
          showErrorMessageEmail ||
          showErrorMessageDuplicateEmail ||
          shortEmployeeNameError
        )
          this.validateFields();
      }
    );
  };

  /* <summary>
   date: 7/1/2023
   Name: NGG
   description: Sets date component values
   <summary> */
  onDateChanged = (id, e) => {
    const { showErrorMessage } = this.state;
    AddLocalStorageData("isDirty", true);
    if (id == "dateConstruction")
      this.setState({ dateConstruction: e }, () => {
        if (showErrorMessage) this.validateFields();
      });

    if (id == "dateKeelLaid") this.setState({ dateKeelLaid: e }, () => { });

    if (id == "socEUExpiryDate")
      this.setState({ socEUExpiryDate: e }, () => {
        // this.setState({ stateComplianceEU: "Yes" });
        if (showErrorMessage) this.validateFields();
      });

    if (id == "socEUIssueDate")
      this.setState({ socEUIssueDate: e }, () => {
        // this.setState({ stateComplianceEU: "Yes" });
        if (showErrorMessage) this.validateFields();
      });

    if (id == "socHKCExpiryDate")
      this.setState({ socHKCExpiryDate: e }, () => {
        // this.setState({ stateComplianceHKC: "Yes" });
        if (showErrorMessage) this.validateFields();
      });

    if (id == "socHKCIssueDate")
      this.setState({ socHKCIssueDate: e }, () => {
        // this.setState({ stateComplianceHKC: "Yes" });
        if (showErrorMessage) this.validateFields();
      });

    if (id == "expiryDate") this.setState({ expiryDate: e }, () => { });

    if (id == "issueDate") this.setState({ issueDate: e }, () => { });

    if (id == "latestUpdateDate")
      this.setState({ latestUpdateDate: e }, () => { });

    if (id == "dateRecycling")
      this.setState({ dateRecycling: e }, () => {
        this.setState({ ihmPart2: "Yes", ihmPart3: "Yes" });
        if (showErrorMessage) this.validateFields();
      });
  };

  /* <summary>
   date: 10/1/2023
   Name: NGG
   description: Adds additional input fields dynamically for secondary communication email address
   <summary> */
  addNewRow = (e) => {
    const { secondaryEmailDetails } = this.state;
    var lenght = Object.keys(secondaryEmailDetails).length;

    this.setState((prevState) => ({
      secondaryEmailDetails: [
        ...prevState.secondaryEmailDetails,
        {
          index: Object.keys(secondaryEmailDetails).length,
          email: "",
        },
      ],
    }));
  };

  /* <summary>
   date: 10/1/2023
   Name: NGG
   description: Sets the values for dynamically generated input fields
   <summary> */
  onSecondaryEmailTextChange = (e, index) => {
    let secEmailDetails = [...this.state.secondaryEmailDetails];
    if (["email"].includes(e.target.name)) {
      secEmailDetails[e.target.dataset.id][e.target.name] = e.target.value;
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }

    this.setState({ secondaryEmailDetails: secEmailDetails });
  };

  generateRandomString = () => {
    return Math.random().toString(36);
  };

  /* <summary>
   date: 16/1/2023
   Name: NGG
   description: sets file value on file upload
   <summary> */
  onAttachmentChange = async (e) => {
    const { name, value } = e.target;

    if (!isValidFile(value, validFileFormats)) {
      if (this.state.fileProfilePath == "") {
        AddLocalStorageData("isDirty", true);
        this.setState({
          fileInputKey: this.generateRandomString(),
          [name]: null,
          errorFile: true,
        });
      }

      //HV - 06/06/2023 - if after attaching correct file, wrong file is attached then retain the older file but show error message
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.wrongFileTypeError,
      });
      return;
    }

    let files = e.target.files[0];

    this.setState({
      errorFile: false,
      [name]: files,
    });
    AddLocalStorageData("isDirty", true);
    if (name === "documentProfileFile") {
      this.setState({
        fileProfileName: files.name,
        fileProfilePath: value,
        isProfileImgDeleted: false,
        profilePictureUrl:
          URL.createObjectURL(
            files
          ) /*SYJ - 09/01/2023 - set image URL to State*/,
      });
    }
  };

  showUploadModal = () => {
    this.setState({
      showModal: false,
      showPortRegistryModal: false,
    });
  };

  /* <summary>
     date: 16/1/2023
     Name: NGG
     description:saves uploaded document data
     <summary> */
  onUploadClick = async (docData) => {
    const { loggedInUserID, shipId, clientId, userTypeId } = this.state;

    this.setState({ isLoading: true });
    let shipDocumentData = new FormData();
    shipDocumentData.append("DocumentTypeId", docData.dropdown_documentType);
    shipDocumentData.append("FlagStateId", docData.dropdown_flagState);
    //shipDocumentData.append("ClassificationSocietyId", docData.dropdown_approvedBy)//approved by
    shipDocumentData.append("Remark", docData.remarks);
    var dateIssue =
      docData.issueDate == "" ? "" : convertDate(docData.issueDate);
    shipDocumentData.append("IssueDate", dateIssue);
    var dateExpiry =
      docData.expiryDate == "" ? "" : convertDate(docData.expiryDate);
    shipDocumentData.append("ExpiryDate", dateExpiry);
    shipDocumentData.append("IssueBy", docData.dropdown_issuedBy);
    shipDocumentData.append("ApprovedBy", docData.dropdown_approvedBy);
    shipDocumentData.append("loggedInUserID", loggedInUserID);
    shipDocumentData.append("shipId", shipId);
    shipDocumentData.append("clientId", clientId);
    shipDocumentData.append("userTypeId", userTypeId);

    shipDocumentData.append("ProjectNo", docData.project_num);
    let file = docData.TFile;
    if (file != "" && file.size !== null && file.size !== undefined) {
      shipDocumentData.append("ShipDocumentAttached", file);
      var fileName = file.name.substring(0, file.name.lastIndexOf("."));
      shipDocumentData.append("fileName", fileName);
    }

    var responseSaved = await ShipService.AddShipDocData(shipDocumentData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.getShipDocumentsData(shipId, true);
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  /* <summary>
     date: 16/1/2023
     Name: NGG
     description: sets file value on file upload
     <summary> */
  deleteRow = (index) => {
    var listEmails = [...this.state.secondaryEmailDetails];
    listEmails.splice(index, 1);
    this.setState({ secondaryEmailDetails: listEmails });
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } catch (e) {
    }
  };

  /* <summary>
   date: 24/1/2023
   Name: NGG
   description: sets radio button selected values
   <summary> */
  onRadioButtonClick = (e, name) => {
    AddLocalStorageData("isDirty", true);
    if (name == "stateComplianceHKC") {
      this.setState({
        stateComplianceHKC: e.target.value,
      });

      if (e.target.value == "No") {
        this.setState({
          socHKCIssueDate: "",
          socHKCExpiryDate: "",
        });
      }
    }

    if (name == "statComplianceEU") {
      this.setState({
        stateComplianceEU: e.target.value,
      });

      if (e.target.value == "No") {
        this.setState({
          socEUIssueDate: "",
          socEUExpiryDate: "",
        });
      }
    }

    if (name == "ihmPart1Approved") {
      this.setState({
        ihmPart1Approved: e.target.value,
      }, () => {
        if (this.state.showErrorMessage) {
          this.validateFields();
        }
      });
    }

    if (name == "ihmPart2") {
      this.setState({
        ihmPart2: e.target.value,
      });

      if (e.target.value == "No") {
        this.setState({
          dateRecycling: "",
        });
      }
    }

    if (name == "ihmPart3") {
      this.setState({
        ihmPart3: e.target.value,
      });

      if (e.target.value == "No") {
        this.setState({
          dateRecycling: "",
        });
      }
    }
  };

  showDeleteDocModal = (id, shipId) => {
    this.setState({ showConfirmationPopup: true, selectdDocDeleteId: id });
  };

  onTextMaritecChange = (e, itemName) => {
    var { listMaritecData } = this.state;
    const { name, value } = e.target;

    var rowIndex = listMaritecData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      listMaritecData[rowIndex][name] = value;
      this.setState({ listMaritecData }, async () => {
        AddLocalStorageData("isDirty", true);
        if (this.state.maritecEmptyError) await this.validateFields();
      });
    }
  };

  checkBoxClick = (e) => {
    const { name, value, checked } = e.target;
    AddLocalStorageData("isDirty", true);
    const { listMaritecData, object } = this.state;
    var rowIndex = listMaritecData.findIndex((t) => t.name == name);
    var obj = [...object];
    if (checked) {
      this.setState((prevState) => ({
        listMaritecData: [
          ...prevState.listMaritecData,
          {
            obj,
            name: name,
            serviceCategoryId:
              name == "ihm"
                ? 1
                : name == "ihmm"
                  ? 2
                  : name == "abestos"
                    ? 3
                    : name == "others"
                      ? 4
                      : null,
          },
        ],
        [name]: checked,
      }));
    } else {
      if (rowIndex > -1) {
        listMaritecData.splice(rowIndex, 1);
        this.setState(
          { listMaritecData: listMaritecData, [name]: checked },
          async () => {
            if (this.state.maritecEmptyError) await this.validateFields();
          }
        );
      }
    }
  };

  onMaritecDateChange = (e, itemName, dateName) => {
    const { listMaritecData } = this.state;
    var rowIndex = listMaritecData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      if (dateName == "contractDate") {
        listMaritecData[rowIndex]["contractDate"] = e;
        this.setState({ listMaritecData }, () => { });
      } else if (dateName == "expiryDate") {
        listMaritecData[rowIndex]["expiryDate"] = e;
        this.setState({ listMaritecData }, () => { });
      }

      if (
        listMaritecData[rowIndex]["contractDate"] != undefined &&
        listMaritecData[rowIndex]["expiryDate"] != undefined
      ) {
        var contractDateYear = new Date(
          listMaritecData[rowIndex]["contractDate"]
        ).getFullYear();
        var expiryDateYear = new Date(
          listMaritecData[rowIndex]["expiryDate"]
        ).getFullYear();
        var yearDifference = expiryDateYear - contractDateYear;
        listMaritecData[rowIndex]["year"] = "Year " + yearDifference;
        this.setState({ listMaritecData }, async () => {
          AddLocalStorageData("isDirty", true);
          if (this.state.maritecEmptyError) await this.validateFields();
        });
      }
    }
  };

  onDropdownMaritecItemSelect = (e, itemName) => {
    var { listMaritecData } = this.state;
    const { name, value } = e.target;

    var rowIndex = listMaritecData.findIndex((t) => t.name == itemName);
    if (rowIndex > -1) {
      listMaritecData[rowIndex][name] = value;
      this.setState({ listMaritecData }, () => {
        AddLocalStorageData("isDirty", true);
        if (this.state.maritecEmptyError) this.validateFields();
      });
    }
  };

  /* <summary>
 date: 24/2/2023
 Name: DP
 description: When supplier is put on hold save the date and time of the onhold period
 <summary> */
  /* onPortRegistrySaveClicked = async (docData) => {
    this.setState({ isLoading: true });

    var data = {
      isFlagState: false,
      PortRegistryText: docData.PortRegistryText,
      loggedInUserID: this.state.loggedInUserID,
    };

    var responseSaved = await ShipService.AddPortOfRegistryData(data);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.getDropdownData(false);

      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.global.saveSuccess,
          toastType: helperText.global.toastSuccess,
          toastType: "success",
          isLoading: false,
        },
        () => {
          this.showUploadModal();
        }
      );

    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  }; */

  onPortRegistrySaveClicked = async (docData) => {
    try {
      this.setState({ isLoading: true });

      const data = {
        isFlagState: false,
        PortRegistryText: docData.PortRegistryText,
        loggedInUserID: this.state.loggedInUserID,
      };

      const responseSaved = await ShipService.AddPortOfRegistryData(data);

      if (responseSaved.status === 200) {
        this.getDropdownData(false);

        this.setState(
          {
            toastVisible: true,
            toastMessage: helperText.global.saveSuccess,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
          },
          this.showUploadModal
        );
      } else if (responseSaved.status === 401) {
        Logout(this.props.navigate);
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  formatInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum =
        e.key === "e" || e.key === "." || e.key === "-" || e.key == "E";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  formatgrossInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "e" || e.key === "-" || e.key == "E";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  formatAlphanumericInput = (e) => {
    let checkIfNum;
    if (e.key !== undefined) {
      checkIfNum = e.key === "-";
    } else if (e.keyCode !== undefined) {
      checkIfNum = e.keyCode === 190;
    }
    return checkIfNum && e.preventDefault();
  };

  goback = () => {
    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    if (isDirty === "true") {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (loc == "/ActionRequired") {
        this.setState({ path: "/ActionRequired" });
      } else if (loc == "/ActivityLog") {
        this.setState({ path: "/ActivityLog" });
      } else {
        this.setState({ path: "/ManageShips" });
      }
      this.setState({ showDirtyPopup: true });
    } else {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (loc == "/ActionRequired") {
        this.props.navigate("/ActionRequired");
      } else if (loc == "/ActivityLog") {
        this.props.navigate("/ActivityLog");
      } else {
        this.props.navigate("ManageShips");
      }
    }
  };

  /* <summary>
    date: 08/03/2023
    Name: HV
    description: method for handling back pressed
    <summary>*/
  onBackClick = (path) => {
    var isDirty = GetLocalStorageDataWithoutParse("isDirty");
    if (isDirty === "true") {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (loc == "/ActionRequired") {
        this.setState({ path: "/ActionRequired" });
      } else if (loc == "/ActivityLog") {
        this.setState({ path: "/ActivityLog" });
      } else {
        this.setState({ path: path });
      }
      this.setState({ showDirtyPopup: true });
    } else {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (loc == "/ActionRequired") {
        this.props.navigate("/ActionRequired");
      } else if (loc == "/ActivityLog") {
        this.props.navigate("/ActivityLog");
      } else {
        this.props.navigate(path);
      }
    }
  };

  /* <summary>
  date: 08/03/2023
  Name: HV
  description: pmethods for opening and closing change request model
  <summary> */
  onRequestNewPasswordClick = () => {
    this.setState({ showRequestModal: true, requestType: "Change Password" });
  };

  showChangePasswordConfirmationModal = () => {
    this.setState({
      showChangePasswordConfirmationModal: true,
    });
  };

  onCloseChangeRequestModalClick = () => {
    this.setState({ showRequestModal: false, requestType: "" });
  };

  onShowProceedModalClick = () => {
    this.setState({ showProceedModal: true });
  };

  onProceeModalCloseClick = () => {
    this.setState({
      showProceedModal: false,
    });
  };

  /* <summary>
  date: 08/03/2023
  Name: HV
  description: on change request click
  <summary> */
  SaveShipCrewChangeRequestData = async () => {
    const { shipId, requestType } = this.state;
    this.setState({ isLoading: true, showProceedModal: false });
    let shipChangeRequestData = new FormData();
    shipChangeRequestData.append("RequestType", requestType);
    shipChangeRequestData.append("EditedBy", shipId);
    shipChangeRequestData.append("ShipId", shipId);
    shipChangeRequestData.append("ModuleName", "Ship Crew");
    shipChangeRequestData.append("NavigateTo", "Ship Details");

    var responseSaved = await ShipService.SaveShipCrewChangeRequestData(
      shipChangeRequestData
    );
    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.Supplier.changeRequestSent,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
        },
        () => {
          this.onCloseChangeRequestModalClick();
        }
      );
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
        isLoading: false,
      });
    }
  };

  onPhoneNumberChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    if (value === "") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (showErrorMessage) this.validateFields();
        }
      );
    } else if (PHONE_NUMBER_REGEX.test(value)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  ontextdataChange = (e) => {
    const { name, value } = e.target;
    const { showErrorMessage } = this.state;
    if (value === "") {
      this.setState(
        {
          [name]: value,
        },
        () => {
          AddLocalStorageData("isDirty", true);
          if (showErrorMessage) this.validateFields();
        }
      );
    } else if (
      name === "shipDistinctiveNo" &&
      ALPHA_NUMERIC_REGEX.test(value)
    ) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    } else if (name === "reportNo" && ALPHA_NUMERIC_REGEX.test(value)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    } else if (name === "companyIdNo" && ALPHA_NUMERIC_REGEX.test(value)) {
      this.setState(
        {
          [name]: value,
        },
        () => {
          if (showErrorMessage) this.validateFields();
        }
      );
    }
  };

  // onShipDistinctiveNumberChange = (e) => {
  //   const { name, value } = e.target;
  //   const { showErrorMessage } = this.state;

  //   if (ALPHA_NUMERIC_REGEX.test(value)) {
  //     this.setState({
  //         [name]: value,
  //       }, () => { if (showErrorMessage)
  //           this.validateFields();
  //       }
  //     );
  //   }
  // };

  /* <summary>
  date: 04/04/2023
  Name: AN
  description: email change
  <summary> */
  onEmailChange = (emailId) => {
    AddLocalStorageData("isDirty", true);
    this.setState({ secondaryEmailDetails: emailId });
  };

  /* <summary>
date: 04/04/2023
Name: AN
description: validating email
<summary> */
  validateEmail = (email) => {
    const { errorMessages } = this.state;
    let errors = errorMessages;
    let validEmail = false;

    if (isEmail(email)) {
      errors["secondaryEmailValidation"] = "";
      validEmail = true;
    } else {
      errors["secondaryEmailValidation"] =
        helperText.Communication.invalidEmailValidation;
      validEmail = false;
    }

    this.setState({ errorMessages: errors });
    return validEmail;
  };

  onSendEmail = async () => {
    const { shipId, shipEmailAdress, loggedInUserID, errorMessages } =
      this.state;
    let errors = errorMessages;
    if (!isStringEmpty(shipEmailAdress)) {
      if (shipId != 0) {
        this.setState({ isLoading: true });

        var response = await ShipService.ActivateDeactivateShip(
          shipId,
          loggedInUserID,
          "Activate",
          shipEmailAdress
        );
        if (
          response != null &&
          response != undefined &&
          response.status == 200
        ) {
          AddLocalStorageData("isDirty", false);
          // Activated the profile, now send email
          var responseEmailSent = await CommonService.SendEmailToUser(
            shipId,
            UserRole.SHIPCREW
          );
          if (
            responseEmailSent != null &&
            responseEmailSent != undefined &&
            responseEmailSent.status == 200
          ) {
            var emailSent = responseEmailSent.data;
            if (emailSent == true) {
              this.setState({
                toastVisible: true,
                toastMessage: helperText.Ship.activatedShip,
                toastType: helperText.global.toastSuccess,
                isLoading: false,
                isAccountActivated: true,
                showActivateDeactivateConfirmationModal: false,
              });
            }
          } else {
            this.setState({
              toastVisible: true,
              toastMessage: helperText.global.somethingWentWrong,
              toastType: helperText.global.toastError,
              isLoading: false,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
        }
      }
    } else {
      errors["errorShipEmailAdress"] = "true";

      this.setState({
        errorMessages: errors,
        showErrorEmailAcctivation: true,
        showActivateDeactivateConfirmationModal: false,
      });
    }
  };

  onActivateDeactivateClicked = () => {
    //ask for confirmation from the user whether to Activate or Deactivate
    this.setState({ showActivateDeactivateConfirmationModal: true });
  };

  onDeactivate = async () => {
    const { shipId, loggedInUserID, shipEmailAdress } = this.state;
    if (shipId != 0) {
      this.setState({ isLoading: true });
      var response = await ShipService.ActivateDeactivateShip(
        shipId,
        loggedInUserID,
        "Deactivate",
        shipEmailAdress
      );
      if (response != null && response != undefined && response.status == 200) {
        // Deactivate the profile,
        this.setState({
          toastVisible: true,
          toastMessage: helperText.Ship.deactivatedShip,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
          isAccountActivated: false,
          showActivateDeactivateConfirmationModal: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          isLoading: false,
        });
      }
    }
  };

  /* <summary>
   date: 20/06/2023
   Name: SYJ
   description: Delete profile image
   <summary> */
  onDeleteProfilePic = () => {
    this.setState({
      showDeleteProfileModal: true,
    });
  };

  onDeleteProfileModalYesClick = () => {
    AddLocalStorageData("isDirty", true);
    this.setState({
      profilePictureUrl: null,
      isProfileImgDeleted: true,
      showDeleteProfileModal: false,
      toastVisible: true,
      toastMessage: helperText.global.deletedProfile,
      toastType: helperText.global.toastSuccess,
    });
  };

  onDeleteProfileModalCloseClick = () => {
    this.setState({
      showDeleteProfileModal: false,
      isProfileImgDeleted: false,
    });
  };

  deleteClientShipProfileImage = async (userId, userTypeId, loggedUserId) => {
    try {
      this.setState({
        isLoading: true,
        showDeleteProfileModal: false,
      });
      var response = await UserService.DeleteProfileImage(
        userId,
        userTypeId,
        loggedUserId
      );
      if (response != undefined) {
        if (
          userTypeId == this.state.userTypeId &&
          loggedUserId != 1 &&
          this.state.userId != 2 &&
          this.state.userId != 3 &&
          this.state.userId != 4 &&
          this.state.userId != 6 &&
          this.state.userId != 7
        ) {
          AddLocalStorageData("loggedInUserPic", null);
        }

        this.setState({
          profilePictureUrl: null,
          shipProfileFileMediaId: 0,
          documentProfileFile: null,
          fileInputKey: this.generateRandomString(),
        }, () => {
          this.getDropdownData(true);
        });

      } else {
        this.setState({
          toastVisible: true,
          isLoading: false,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };

  //A.N. - 06/03/2023 - handled for disable all inputs for client login.
  render() {
    const {
      isLoading,
      colDefs,
      userName,
      showErrorMessage,
      toastVisible,
      showErrorMessageEmail,
      showErrorMessageDuplicateEmail,
      toastType,
      toastMessage,
      shipTypeList,
      portRegistryList,
      shipBuilderNameList,
      shipOwnerNameList,
      part1SocList,
      statComplianceList,
      serviceCategoryList,
      ihmmInchargeList,
      alternateIhmmInchargeList,
      mappedDocuments,
      shipId,
      shipName,
      shipIMONo,
      shipEmailAdress,
      dropdown_typeOfShip,
      shipDistinctiveNo,
      classNumber,
      dropdown_portofregistry,
      dropdown_classificationSociety,
      grossTonnage,
      principalDimensions,
      shipbuilderAddress,
      shipbuilderContactNo,
      shipOwnerAddress,
      shipOwnerContactNo,
      commPrimaryEmailaddress,
      commSecondaryEmailaddress,
      classificationSocietyList,
      //dropdown_shipBuilderName,
      //dropdown_shipOwnerName,
      dateConstruction,
      dateKeelLaid,
      ownerName,
      ownerIdNo,
      ownerAdress,
      companyIdNo,
      ownerEmailAdress,

      ownerContactNo,
      ihmIdNo,
      picName,
      picDesignation,
      picEmail,
      picContactNo,
      errorMessages,
      reportNo,
      socEUExpiryDate,
      socEUIssueDate,
      socHKCExpiryDate,
      socHKCIssueDate,
      expiryDate,
      issueDate,
      issuedBy,

      dropdown_statecompliance,
      dropdown_part1soc,
      projectNumber,
      disableSave,
      secondaryEmailDetails,
      password,
      showPassword,
      profilePictureUrl,
      fileInputKey,
      imoNoExists,
      primaryEmailExists,
      showModal,
      clientName,
      flagStateList,
      documentTypeList,
      clientId,
      showViewModal,
      filePathToView,
      dateRecycling,
      showHistoryModal,
      filesBasedDocumentType,
      latestUpdateDate,
      ihmPart1Approved,
      stateComplianceHKC,
      stateComplianceEU,
      ihmPart3,
      ihmPart2,
      dateRangeError,
      dateRangeErrorHKC,
      dateRangeErrorEU,
      showConfirmationPopup,
      listMaritecData,
      ihm,
      ihmm,
      abestos,
      others,
      projectNumberError,
      showPortRegistryModal,
      shipBuilderName,
      shipOwnerName,
      originalPortOfRegList,
      isClientOrClientUser,
      isShipCrew,
      disableFields,
      showRequestModal,
      showProceedModal,
      maritecEmptyError,
      isActive,
      shipClientCode,
      showChangePasswordConfirmationModal,
      userTypeId,
      isAccountActivated,
      showActivateDeactivateConfirmationModal,
      showErrorEmailAcctivation,
      shortEmployeeNameError,
      showDeleteProfileModal,
      euAutoCount,
      hkcAutoCount,
      euManualCount,
      hkcManualCount,
    } = this.state;
    return (
      <div>
        {isLoading && <Loader></Loader>}
        <Header />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View Ship File"
            filePath={filePathToView}
            onCloseClick={this.onModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
          ></ViewModal>
        )}

        {showRequestModal && (
          <ClientChangeRequestModal
            onCloseClick={this.onCloseChangeRequestModalClick}
            modalTitle={"Request admin to setup new password"}
            clientEmailId={shipEmailAdress}
            onChangeRequestClick={this.onShowProceedModalClick}
          />
        )}

        {showChangePasswordConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onChangePasswordClick}
            modalText={"change password? An email will be sent to the user with a new password after clicking the Yes button."}
          />
        )}

        {showActivateDeactivateConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={
              !isAccountActivated ? this.onSendEmail : this.onDeactivate
            }
            modalText={
              !isAccountActivated
                ? "activate Ship profile?"
                : "deactivate Ship profile?"
            }
          />
        )}

        {showProceedModal && (
          <ProceedModal
            showProceedModal={showProceedModal}
            onNoClick={this.onProceeModalCloseClick}
            onYesClick={this.SaveShipCrewChangeRequestData}
          />
        )}

        {showDeleteProfileModal && (
          <DeleteModal
            showDeleteModal
            onCloseClick={this.onDeleteProfileModalCloseClick}
            onYesClick={this.onDeleteProfileModalYesClick}
            onNoClick={this.onDeleteProfileModalCloseClick}
            modalheader="Delete Profile Image"
          ></DeleteModal>
        )}

        <main className="page-height">
          <section className="communications manage-ship">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <a
                    onClick={() =>
                      this.onBackClick(
                        isShipCrew ? "/ShipCrewDashboard" : "/ManageShips"
                      )
                    }
                  >
                    <img
                      style={{ cursor: "pointer" }}
                      className="back-btn-responsive manage-client-back-button"
                      src="images/back button image.png"
                      alt="Back"
                    />
                  </a>{" "}
                  <span className="title-font">
                    {isShipCrew
                      ? "Profile Details"
                      : shipId != 0 && shipId != null && shipId != ""
                        ? isClientOrClientUser
                          ? "View Ship"
                          : "Edit Ship"
                        : "Add Ship"}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <div className="admin-ship-detail-img">
                    <div className="admin-ship-bg-img">
                      {profilePictureUrl == "" ||
                        profilePictureUrl == undefined ? (
                        <img
                          src={"images/add-photo.png"}
                          alt="img-profile"
                          className="admin-profile"
                          style={{ objectFit: "contain" }}
                        />
                      ) : (
                        <img
                          src={profilePictureUrl}
                          alt="img-profile"
                          className="admin-profile"
                          style={{ objectFit: "contain" }}
                        />
                      )}
                      {/* && !disableFields */}
                      {profilePictureUrl != "" &&
                        profilePictureUrl != undefined &&
                        profilePictureUrl != null ? (
                        <div className="admin-profile-icon">
                          <label style={{ left: "68%", top: "40%" }}>
                            <img
                              title={"Delete"}
                              src={deleteIcon}
                              alt="profile-delete"
                              className="img-fluid cursor-class test-Pankaj"
                              style={{ width: "18px", height: "20px" }}
                              onClick={this.onDeleteProfilePic}
                              disabled={this.state.userRolePermissionRead}
                            />
                          </label>
                        </div>
                      ) : null}

                      <div className="admin-profile-icon">
                        <label htmlFor="myfile">
                          <img
                            title={
                              profilePictureUrl == "" ||
                                profilePictureUrl == undefined
                                ? "Add"
                                : "Edit"
                            }
                            src={
                              profilePictureUrl == "" ||
                                profilePictureUrl == undefined
                                ? addProfileIcon
                                : editIcon
                            }
                            alt="profile-edit "
                            className="img-fluid cursor-class"
                            style={{
                              opacity:
                                isClientOrClientUser ||
                                  !isActive ||
                                  this.state.userRolePermissionRead
                                  ? "0.6"
                                  : "1",
                            }}
                          />
                        </label>
                        <input
                          type="file"
                          accept={validFileFormatsReadable}
                          id="myfile"
                          name="documentProfileFile"
                          onChange={
                            isClientOrClientUser ||
                              !isActive ||
                              this.state.userRolePermissionRead
                              ? null
                              : this.onAttachmentChange
                          }
                          key={fileInputKey || ""}
                          style={{ display: "none", visibility: "none" }}
                          disabled={
                            userTypeId == UserRole.SHIPCREW
                              ? false
                              : disableFields ||
                              !isActive ||
                              this.state.userRolePermissionRead
                          }
                        />
                        <div id="display-image"></div>
                      </div>
                    </div>
                    <h2>
                      {" "}
                      <input
                        type="name"
                        value={this.state.shipName}
                        disabled
                        style={{
                          background: "transparent",
                          border: "none",
                          lineHeight: 1,
                          fontSize: "18px",
                          textAlign: "center",
                        }}
                      />
                    </h2>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="admin-ship-login-detail">
                    <h1>Login</h1>
                    <div className="row">
                      <div className="col-md-4 pb3">
                        <h4>IMO Number:</h4>
                        <input
                          type="text"
                          autoFocus={false}
                          disabled={
                            (shipId != 0 && shipId != null && shipId != "") ||
                              this.state.userRolePermissionRead
                              ? true
                              : false
                          }
                          autoComplete="new-password"
                          className="form-control"
                          value={shipIMONo}
                          name="shipIMONo"
                          onChange={this.onTextChange}
                          onKeyDown={this.formatInput}
                          maxLength={10}
                          style={{ paddingLeft: "10px" }}
                        />
                      </div>
                      <div className="col-md-4 pb3">
                        <h4>Password:</h4>
                        <div className="admin-supplier-change-password">
                          <input
                            autoFocus={false}
                            autoComplete="new-password"
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            style={{ paddingLeft: "10px" }}
                            title="ppp"
                            name="password"
                            id="id_password"
                            value={password}
                            onChange={this.onTextChange}
                            disabled={
                              shipId == 0 ||
                              !isAccountActivated ||
                              disableFields ||
                              !isActive ||
                              this.state.userRolePermissionRead
                            }
                            maxLength={20}
                          />
                          <span>(Auto generated by system)</span>
                          <span onClick={this.togglePassword}>
                            {" "}
                            <i
                              title={showPassword ? "Hide" : "Show"}
                              className={
                                showPassword
                                  ? "fa fa-eye cursor-class "
                                  : "fa fa-eye-slash cursor-class"
                              }
                              id="togglePassword"
                            ></i>{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="">
                          <button
                            disabled={
                              shipId == 0 ||
                              !isAccountActivated ||
                              isClientOrClientUser ||
                              !isActive ||
                              this.state.userRolePermissionRead
                            }
                            type="button"
                            style={{ marginTop: "27px" }}
                            className="btn"
                            onClick={
                              () =>
                                isShipCrew
                                  ? this.onRequestNewPasswordClick()
                                  : this.showChangePasswordConfirmationModal() //this.onChangePasswordClick()
                            }
                          >
                            {isShipCrew
                              ? "Request New Password"
                              : "Change Password"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-ship-box">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#shipdetails"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Ship Details
                      </button>
                    </h2>
                    <div
                      id="shipdetails"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5
                                      className={
                                        isClientOrClientUser ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    >
                                      Ship Name:
                                    </h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorShipName
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <input
                                        disabled={
                                          isClientOrClientUser ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        type="text"
                                        autoFocus={false}
                                        autoComplete="new-password"
                                        className="form-control"
                                        id="shipname"
                                        name="shipName"
                                        value={shipName}
                                        onChange={this.onTextChange}
                                        maxLength={90}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.errorShipName
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5
                                      className={
                                        (shipId != 0 &&
                                          shipId != null &&
                                          shipId != "") ||
                                          this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    >
                                      Ship IMO Number:
                                    </h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorShipIMONo
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="ship-imo-no"
                                        name="shipIMONo"
                                        disabled={
                                          (shipId != 0 &&
                                            shipId != null &&
                                            shipId != "") ||
                                            this.state.userRolePermissionRead
                                            ? true
                                            : false
                                        }
                                        value={shipIMONo}
                                        autoFocus={false}
                                        autoComplete="new-password"
                                        onChange={this.onTextChange}
                                        onKeyDown={this.formatInput}
                                        maxLength={10}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.errorShipIMONo
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    {/* <h5
                                      className={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    > */}
                                    <h5>Ship Email Address:</h5>
                                    {/* </h5> */}
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorShipEmailAdress
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        type="email"
                                        className="form-control"
                                        id="shipemail"
                                        value={shipEmailAdress}
                                        name="shipEmailAdress"
                                        autoFocus={false}
                                        autoComplete="new-password"
                                        onChange={this.onTextChange}
                                        maxLength={100}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.errorShipEmailAdress
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5
                                      className={
                                        disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    >
                                      Type of Ship:
                                    </h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorTypeOfShip
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                      id={"shipType"}
                                    >
                                      <Dropdown
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        dropdownLabel=""
                                        name="typeOfShip"
                                        onChange={this.onDropdownItemSelect}
                                        selected={dropdown_typeOfShip}
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "pre",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {shipTypeList == null
                                          ? null
                                          : shipTypeList.map((item, key) => (
                                            <option
                                              key={key}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          ))}
                                        ;
                                      </Dropdown>
                                      {dropdown_typeOfShip > 0 ? (
                                        <UncontrolledTooltip
                                          autohide={false}
                                          target={"shipType"}
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          placement="bottom"
                                          style={{ width: "350px" }}
                                        >
                                          {shipTypeList != null && shipTypeList.filter(x => x.value == dropdown_typeOfShip)[0]?.label}
                                        </UncontrolledTooltip>) : null}
                                      {!isStringEmpty(
                                        errorMessages.errorTypeOfShip
                                      ) && (
                                          <ErrorView errorFor="emptyDropdown" />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    {/* <h5
                                      className={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    > */}
                                    <h5>Ships Distinctive Number:</h5>
                                    {/* </h5> */}
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="ship-distinctive-no"
                                      value={shipDistinctiveNo}
                                      name="shipDistinctiveNo"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.ontextdataChange}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Class Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="class-number"
                                      value={classNumber}
                                      name="classNumber"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      onKeyDown={this.formatInput}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5
                                      className={
                                        disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    >
                                      Port of Registry:
                                    </h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorPortofRegistry
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                      id={"portRegistery"}
                                    >
                                      <Dropdown
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        dropdownLabel=""
                                        name="portofregistry"
                                        onChange={this.onDropdownItemSelect}
                                        selected={dropdown_portofregistry}
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "pre",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {portRegistryList == null
                                          ? null
                                          : portRegistryList.map(
                                            (item, key) => (
                                              <option
                                                className={
                                                  item.value == "0"
                                                    ? "onhold-link"
                                                    : ""
                                                }
                                                key={key}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </option>
                                            )
                                          )}{" "}
                                        ;
                                      </Dropdown>
                                      {dropdown_portofregistry > 0 ? (
                                        <UncontrolledTooltip
                                          autohide={false}
                                          target={"portRegistery"}
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          placement="bottom"
                                          style={{ width: "350px" }}
                                        >
                                          {portRegistryList != null && portRegistryList.filter(x => x.value == dropdown_portofregistry)[0]?.label}
                                        </UncontrolledTooltip>) : null}
                                      {!isStringEmpty(
                                        errorMessages.errorPortofRegistry
                                      ) && (
                                          <ErrorView errorFor="emptyDropdown" />
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5 className={
                                      disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                        ? ""
                                        : "required-field"
                                    }>Classification Society:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorClassificationSociety
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                      id={"socClass"}
                                    >
                                      <Dropdown
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        dropdownLabel=""
                                        name="classificationSociety"
                                        onChange={this.onDropdownItemSelect}
                                        selected={
                                          dropdown_classificationSociety
                                        }
                                        style={{
                                          textOverflow: "ellipsis",
                                          whiteSpace: "pre",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {classificationSocietyList == null
                                          ? null
                                          : classificationSocietyList.map(
                                            (item, key) => (
                                              <option
                                                key={key}
                                                value={item.value}
                                              >
                                                {item.label}
                                              </option>
                                            )
                                          )}{" "}
                                        ;
                                      </Dropdown>
                                      {dropdown_classificationSociety > 0 ? (
                                        <UncontrolledTooltip
                                          autohide={false}
                                          target={"socClass"}
                                          aria-haspopup="true"
                                          role="example"
                                          effect="solid"
                                          placement="bottom"
                                          style={{ width: "350px" }}
                                        >
                                          {classificationSocietyList != null && classificationSocietyList.filter(x => x.value == dropdown_classificationSociety)[0]?.label}
                                        </UncontrolledTooltip>) : null}
                                      {!isStringEmpty(
                                        errorMessages.errorClassificationSociety
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Gross Tonnage:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="grossTonnage"
                                      value={grossTonnage}
                                      name="grossTonnage"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      onKeyDown={this.formatgrossInput}
                                      maxLength={9}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Date of Keel Laid:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      // readOnly
                                      min="0"
                                      selectedDate={dateKeelLaid}
                                      onDateChange={(e) =>
                                        this.onDateChanged("dateKeelLaid", e)
                                      }
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Principal Dimensions (LoaxBxD):</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="principalDimensions"
                                      value={principalDimensions}
                                      name="principalDimensions"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={40}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-4 align-self-class"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Name of Shipbuilder:</h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <input
                                          disabled={
                                            disableFields ||
                                            !isActive ||
                                            this.state.userRolePermissionRead
                                          }
                                          maxLength={90}
                                          type="text"
                                          className="form-control"
                                          id="shipBuilderName"
                                          value={shipBuilderName}
                                          name="shipBuilderName"
                                          autoFocus={false}
                                          autoComplete="new-password"
                                          onChange={this.onTextChange}
                                        />
                                        {/* <Dropdown
																					dropdownLabel=""
																					name="shipBuilderName"
																					onChange={this.onDropdownItemSelect}
																					selected={dropdown_shipBuilderName}
																				>
																					{shipBuilderNameList == null
																						? null
																						: shipBuilderNameList.map((item, key) => (
																							<option key={key} value={item}>
																								{item}
																							</option>
																						))} ;
																				</Dropdown> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-4 align-self-class"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Shipbuilder Contact Number:</h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <input
                                          disabled={
                                            disableFields ||
                                            !isActive ||
                                            this.state.userRolePermissionRead
                                          }
                                          type="text"
                                          className="form-control"
                                          id="shipbuildercontact"
                                          value={shipbuilderContactNo}
                                          name="shipbuilderContactNo"
                                          autoFocus={false}
                                          autoComplete="new-password"
                                          onChange={this.onPhoneNumberChange}
                                          onKeyDown={this.formatInput}
                                          maxLength={20}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Shipbuilder Address:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <textarea
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      rows="3"
                                      value={shipbuilderAddress}
                                      name="shipbuilderAddress"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={150}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-4 align-self-class"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Ship Owner Name:</h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <input
                                          disabled={
                                            disableFields ||
                                            !isActive ||
                                            this.state.userRolePermissionRead
                                          }
                                          maxLength={100}
                                          type="text"
                                          className="form-control"
                                          id="shipOwnerName"
                                          value={shipOwnerName}
                                          name="shipOwnerName"
                                          autoFocus={false}
                                          autoComplete="new-password"
                                          onChange={this.onTextChange}
                                        />
                                        {/* <Dropdown
																					dropdownLabel=""
																					name="shipOwnerName"
																					onChange={this.onDropdownItemSelect}
																					selected={dropdown_shipOwnerName}
																				>
																					{shipOwnerNameList == null
																						? null
																						: shipOwnerNameList.map((item, key) => (
																							<option key={key} value={item}>
																								{item}
																							</option>
																						))} ;
																				</Dropdown> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="ship-detail-pb">
                                    <div className="row">
                                      <div className="col-xl-6 col-lg-4 align-self-class"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Ship Owner Contact Number:</h5>
                                      </div>
                                      <div className="col-xl-6 col-lg-8">
                                        <input
                                          disabled={
                                            disableFields ||
                                            !isActive ||
                                            this.state.userRolePermissionRead
                                          }
                                          type="text"
                                          className="form-control"
                                          id="shipownerrcontact"
                                          value={shipOwnerContactNo}
                                          name="shipOwnerContactNo"
                                          autoFocus={false}
                                          autoComplete="new-password"
                                          onChange={this.onPhoneNumberChange}
                                          onKeyDown={this.formatInput}
                                          maxLength={20}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Ship Owner Address:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <textarea
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      rows="3"
                                      value={shipOwnerAddress}
                                      name="shipOwnerAddress"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={150}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    {/* <h5
                                      className={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    > */}
                                    <h5>Date of Construction:</h5>
                                    {/* </h5> */}
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      min="0"
                                      selectedDate={dateConstruction}
                                      onDateChange={(e) =>
                                        this.onDateChanged(
                                          "dateConstruction",
                                          e
                                        )
                                      }
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-3 col-lg-2 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    {/* <h5
                                      className={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                          ? ""
                                          : "required-field"
                                      }
                                    > */}
                                    <h5>
                                      Communication Primary Email Address:
                                    </h5>
                                    {/* </h5> */}
                                  </div>
                                  <div className="col-xl-3 col-lg-4 col-10">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorShipOwnerEmailAdress
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        type="email"
                                        className="form-control"
                                        id="commPrimaryEmailaddress"
                                        autoFocus={false}
                                        autoComplete="new-password"
                                        onChange={this.onTextChange}
                                        name="commPrimaryEmailaddress"
                                        value={commPrimaryEmailaddress}
                                        maxLength={80}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.errorShipOwnerEmailAdress
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-3 col-lg-2 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>
                                      Communication Secondary Email Address:
                                    </h5>
                                  </div>
                                  <div className="col-xl-3 col-lg-4 col-10">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorShipOwnerSecondaryEmailAdress
                                        )
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <ReactMultiEmail
                                        placeholder=""
                                        emails={secondaryEmailDetails}
                                        onChange={(_emails) =>
                                          this.state.userRolePermissionRead
                                            ? ""
                                            : this.onEmailChange(_emails)
                                        }
                                        validateEmail={(email) =>
                                          this.state.userRolePermissionRead
                                            ? ""
                                            : this.validateEmail(email)
                                        }
                                        style={{
                                          resize: "none",
                                          fontSize: "14px",
                                        }}
                                        getLabel={(
                                          email,
                                          index,
                                          removeEmail = index()
                                        ) => {
                                          return (
                                            <div data-tag key={index}>
                                              {email}
                                              <span
                                                data-tag-handle
                                                onClick={() =>
                                                  this.state
                                                    .userRolePermissionRead
                                                    ? ""
                                                    : removeEmail(index)
                                                }
                                              >
                                                x
                                              </span>
                                            </div>
                                          );
                                        }}
                                      />
                                      {!isStringEmpty(
                                        errorMessages.errorShipOwnerSecondaryEmailAdress
                                      ) && (
                                          <div>
                                            <div className="invalid-dropdown-icon">
                                              <img
                                                src="images\icon material-error.png"
                                                alt="invalid"
                                              ></img>
                                            </div>
                                            <div className="invalid-login-email-message">
                                              {
                                                errorMessages.errorShipOwnerSecondaryEmailAdress
                                              }
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#clientdetails"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Client Details
                      </button>
                    </h2>
                    <div
                      id="clientdetails"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Registered Owner Name:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="registeredownername"
                                      name="ownerName"
                                      value={ownerName}
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Client Code:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={true}
                                      type="text"
                                      className="form-control"
                                      id="registeredowneridno"
                                      name="ownerIdNo"
                                      value={shipClientCode}
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={40}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Registered Owner Address:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <textarea
                                      rows="3"
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      name="ownerAdress"
                                      value={ownerAdress}
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={150}
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Registered Owner Contact Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      min="0"
                                      className="form-control"
                                      id="registeredownercontact"
                                      name="ownerContactNo"
                                      value={ownerContactNo}
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onPhoneNumberChange}
                                      onKeyDown={this.formatInput}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Registered Owner Email Address:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="email"
                                      className="form-control"
                                      id="registeredowneremail"
                                      value={ownerEmailAdress}
                                      name="ownerEmailAdress"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Company Identification Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="companyidno"
                                      value={companyIdNo}
                                      name="companyIdNo"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.ontextdataChange}
                                      //onKeyDown={this.formatInput}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class">
                                    <h5>IHM Identification Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={disableFields || !isActive}
                                      type="text"
                                      min="0"
                                      className="form-control"
                                      id="companyidno"
                                      value={ihmIdNo}
                                      name="ihmIdNo"
                                      autoFocus={false}
                                       autoComplete="new-password"
                                      onChange={this.onPhoneNumberChange}
                                      onKeyDown={this.formatInput}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div> */}
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Ship IHMM PIC Name:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="shipihmmpicno"
                                      value={picName}
                                      name="picName"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Ship IHMM PIC Designation:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="shipihmmpicdesig"
                                      value={picDesignation}
                                      name="picDesignation"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Ship IHMM PIC Email:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="email"
                                      className="form-control"
                                      id="shipihmmpicemail"
                                      value={picEmail}
                                      name="picEmail"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onTextChange}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Ship IHMM PIC Contact Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="shipihmmpiccontact"
                                      value={picContactNo}
                                      name="picContactNo"
                                      autoFocus={false}
                                      autoComplete="new-password"
                                      onChange={this.onPhoneNumberChange}
                                      onKeyDown={this.formatInput}
                                      maxLength={20}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ihmparticulars"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        IHM Particulars
                      </button>
                    </h2>
                    <div
                      id="ihmparticulars"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 Issue Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      min="0"
                                      selectedDate={issueDate}
                                      onDateChange={(e) =>
                                        this.onDateChanged("issueDate", e)
                                      }
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 Update Latest Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <DatePicker
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      min="0"
                                      selectedDate={latestUpdateDate}
                                      onDateChange={(e) =>
                                        this.onDateChanged(
                                          "latestUpdateDate",
                                          e
                                        )
                                      }
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 Report Number:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      //onKeyDown={this.formatAlphanumericInput}
                                      type="text"
                                      min="0"
                                      className="form-control"
                                      id="ihmmpartreportno"
                                      name="reportNo"
                                      onChange={this.ontextdataChange}
                                      value={reportNo}
                                      maxLength={30}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6"></div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 SOC Issued By:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      type="text"
                                      className="form-control"
                                      id="ihmpartsocissued"
                                      name="issuedBy"
                                      onChange={this.onTextChange}
                                      value={issuedBy}
                                      maxLength={100}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 Approved:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8 divformcheck">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart1ApprovedYes"
                                        name="ihmPart1Approved"
                                        checked={
                                          // ihmPart1Approved == "" ||
                                          //   ihmPart1Approved == null
                                          //   ? "Yes"
                                          //   : 
                                          ihmPart1Approved == "Yes"
                                        }
                                        value="Yes"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart1Approved"
                                          );
                                        }}
                                      />
                                      Yes
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart1ApprovedYes"
                                      ></label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart1ApprovedNo"
                                        name="ihmPart1Approved"
                                        checked={ihmPart1Approved == "No"}
                                        value="No"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart1Approved"
                                          );
                                        }}
                                      />
                                      No
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart1ApprovedNo"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Statement of Compliance HKC:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8 divformcheck">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="stateComplianceHKCYes"
                                        name="stateComplianceHKC"
                                        checked={stateComplianceHKC == "Yes"}
                                        value="Yes"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "stateComplianceHKC"
                                          );
                                        }}
                                      />
                                      Yes
                                      <label
                                        className="form-check-label"
                                        htmlFor="stateComplianceHKCYes"
                                      ></label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="stateComplianceHKCNo"
                                        name="stateComplianceHKC"
                                        checked={stateComplianceHKC == "No"}
                                        value="No"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "stateComplianceHKC"
                                          );
                                        }}
                                      />
                                      No
                                      <label
                                        className="form-check-label"
                                        htmlFor="stateComplianceHKCNo"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>Statement of Compliance EU:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8 divformcheck">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="statComplianceEUYes"
                                        name="statComplianceEU"
                                        checked={stateComplianceEU == "Yes"}
                                        value="Yes"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "statComplianceEU"
                                          );
                                        }}
                                      />
                                      Yes
                                      <label
                                        className="form-check-label"
                                        htmlFor="statComplianceEUYes"
                                      ></label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="statComplianceEUNo"
                                        name="statComplianceEU"
                                        checked={stateComplianceEU == "No"}
                                        value="No"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "statComplianceEU"
                                          );
                                        }}
                                      />
                                      No
                                      <label
                                        className="form-check-label"
                                        htmlFor="statComplianceEUNo"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 SOC-HKC Issue Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorHKCIssueDate
                                        ) || dateRangeErrorHKC
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <DatePicker
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead ||
                                          stateComplianceHKC != "Yes"
                                        }
                                        min="0"
                                        selectedDate={
                                          stateComplianceHKC == "Yes"
                                            ? socHKCIssueDate
                                            : ""
                                        }
                                        onDateChange={(e) =>
                                          this.onDateChanged(
                                            "socHKCIssueDate",
                                            e
                                          )
                                        }
                                        maxDate={socHKCExpiryDate}
                                      >
                                        {" "}
                                      </DatePicker>
                                      {!isStringEmpty(
                                        errorMessages.errorHKCIssueDate
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 SOC-HKC Expiry Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorHKCExpiryDate
                                        ) || dateRangeErrorHKC
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <DatePicker
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead ||
                                          stateComplianceHKC != "Yes"
                                        }
                                        min="0"
                                        selectedDate={
                                          stateComplianceHKC == "Yes"
                                            ? socHKCExpiryDate
                                            : ""
                                        }
                                        onDateChange={(e) =>
                                          this.onDateChanged(
                                            "socHKCExpiryDate",
                                            e
                                          )
                                        }
                                        minDate={socHKCIssueDate}
                                      >
                                        {" "}
                                      </DatePicker>
                                      {!isStringEmpty(
                                        errorMessages.errorHKCExpiryDate
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 SOC-EU Issue Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorEUIssueDate
                                        ) || dateRangeErrorEU
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <DatePicker
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead ||
                                          stateComplianceEU != "Yes"
                                        }
                                        min="0"
                                        selectedDate={
                                          stateComplianceEU == "Yes"
                                            ? socEUIssueDate
                                            : ""
                                        }
                                        onDateChange={(e) =>
                                          this.onDateChanged(
                                            "socEUIssueDate",
                                            e
                                          )
                                        }
                                        maxDate={socEUExpiryDate}
                                      >
                                        {" "}
                                      </DatePicker>

                                      {!isStringEmpty(
                                        errorMessages.errorEUIssueDate
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-6 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 1 SOC-EU Expiry Date:</h5>
                                  </div>
                                  <div className="col-xl-6 col-lg-8 align-self-class">
                                    <div
                                      className={
                                        !isStringEmpty(
                                          errorMessages.errorEUExpiryDate
                                        ) || dateRangeErrorEU
                                          ? "invalid-position"
                                          : ""
                                      }
                                    >
                                      <DatePicker
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead ||
                                          stateComplianceEU != "Yes"
                                        }
                                        min="0"
                                        selectedDate={
                                          stateComplianceEU == "Yes"
                                            ? socEUExpiryDate
                                            : ""
                                        }
                                        onDateChange={(e) =>
                                          this.onDateChanged(
                                            "socEUExpiryDate",
                                            e
                                          )
                                        }
                                        minDate={socEUIssueDate}
                                      >
                                        {" "}
                                      </DatePicker>

                                      {!isStringEmpty(
                                        errorMessages.errorEUExpiryDate
                                      ) && <ErrorView errorFor="emptyText" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-5 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 2:</h5>
                                  </div>
                                  <div className="col-xl-7 col-lg-8 divformcheck">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart2Yes"
                                        name="ihmPart2"
                                        checked={ihmPart2 == "Yes"}
                                        value="Yes"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart2"
                                          );
                                        }}
                                      />
                                      Yes
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart2Yes"
                                      ></label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart2No"
                                        name="ihmPart2"
                                        checked={ihmPart2 == "No"}
                                        value="No"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart2"
                                          );
                                        }}
                                      />
                                      No
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart2No"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div className="col-xl-5 col-lg-4 align-self-class"
                                    style={{ alignSelf: "center" }}>
                                    <h5>IHM Part 3:</h5>
                                  </div>
                                  <div className="col-xl-7 col-lg-8 divformcheck">
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart3Yes"
                                        name="ihmPart3"
                                        checked={ihmPart3 == "Yes"}
                                        value="Yes"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart3"
                                          );
                                        }}
                                      />
                                      Yes
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart3Yes"
                                      ></label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input
                                        disabled={
                                          disableFields ||
                                          !isActive ||
                                          this.state.userRolePermissionRead
                                        }
                                        style={{ cursor: "pointer" }}
                                        type="radio"
                                        className="form-check-input"
                                        id="ihmPart3No"
                                        name="ihmPart3"
                                        checked={ihmPart3 == "No"}
                                        value="No"
                                        onChange={(e) => {
                                          this.onRadioButtonClick(
                                            e,
                                            "ihmPart3"
                                          );
                                        }}
                                      />
                                      No
                                      <label
                                        className="form-check-label"
                                        htmlFor="ihmPart3No"
                                      ></label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {(ihmPart2 == "Yes" && ihmPart3 == "Yes") ? (
                              <div className="col-md-6">
                                <div className="ship-detail-pb">
                                  <div className="row">
                                    <div className="col-xl-6 col-lg-4 align-self-class"
                                      style={{ alignSelf: "center" }}>
                                      <h5>Ready for Ship Recycling:</h5>
                                    </div>
                                    <div className="col-xl-6 col-lg-8">
                                      <div
                                        className={
                                          !isStringEmpty(
                                            errorMessages.errorRecyclingDate
                                          )
                                            ? "invalid-position"
                                            : ""
                                        }
                                      >
                                        <DatePicker
                                          disabled={
                                            disableFields ||
                                            !isActive ||
                                            this.state.userRolePermissionRead
                                          }
                                          min="0"
                                          selectedDate={dateRecycling}
                                          onDateChange={(e) =>
                                            this.onDateChanged(
                                              "dateRecycling",
                                              e
                                            )
                                          }
                                        >
                                          {" "}
                                        </DatePicker>
                                        {!isStringEmpty(
                                          errorMessages.errorRecyclingDate
                                        ) && <ErrorView errorFor="emptyText" />}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : <div className="col-md-6"></div>}

                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div
                                    style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                    <h5>SOC EU Expiry Reminder Automatic Count:</h5>
                                  </div>
                                  <div className="col-lg-6" id={"euAutoCount"}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="euAutoCount"
                                      max={50}
                                      maxLength={2}
                                      value={euAutoCount}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="ship-detail-pb">
                                <div className="row">
                                  <div
                                    style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                    <h5>SOC HKC Expiry Reminder Automatic Count:</h5>
                                  </div>
                                  <div className="col-lg-6" id={"hkcAutoCount"}>
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="hkcAutoCount"
                                      max={50}
                                      maxLength={2}
                                      value={hkcAutoCount}
                                      disabled
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {euAutoCount > 0 && (
                              <div className="col-md-6">
                                <div className="ship-detail-pb">
                                  <div className="row">
                                    <div
                                      style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                      <h5>SOC EU Expiry Reminder Manual Count:</h5>
                                    </div>
                                    <div className="col-lg-6" id={"euManualCount"}>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="euManualCount"
                                        max={50}
                                        maxLength={2}
                                        value={euManualCount}
                                        onChange={this.onTextChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>)}
                            {hkcAutoCount > 0 && (
                              <div className="col-md-6">
                                <div className="ship-detail-pb">
                                  <div className="row">
                                    <div
                                      style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                      <h5>SOC HKC Expiry Reminder Manual Count:</h5>
                                    </div>
                                    <div className="col-lg-6" id={"hkcManualCount"}>
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="hkcManualCount"
                                        max={50}
                                        maxLength={2}
                                        value={hkcManualCount}
                                        onChange={this.onTextChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>)}
                            {(euAutoCount > 0 || hkcAutoCount > 0) && (
                              <div className="col-md-12">
                                <div style={{ alignSelf: "center" }} className="col-lg-6 align-self-class">
                                  Please enter a number from 1 to 50
                                </div>
                              </div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#maritec-data"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Maritec Reference Data
                      </button>
                    </h2>
                    <div
                      id="maritec-data"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row maritec-detail-pb">
                                <div className="col-md-12 col-lg-3 align-self-class"
                                  style={{ alignSelf: "center" }}>
                                  <h5>Service Category:</h5>
                                </div>
                                <div className="col-md-12 col-lg-8">
                                  <div className=" form-check-inline">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="form-check-input"
                                      name="ihm"
                                      type="checkbox"
                                      checked={ihm}
                                      id="inlineCheckbox1"
                                      value={ihm}
                                      onChange={(e) => this.checkBoxClick(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox1"
                                      style={{ cursor: "pointer" }}
                                    >
                                      IHM
                                    </label>
                                  </div>
                                  <div className=" form-check-inline">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="form-check-input"
                                      type="checkbox"
                                      name="ihmm"
                                      checked={ihmm}
                                      id="inlineCheckbox2"
                                      value={ihmm}
                                      onChange={(e) => this.checkBoxClick(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox2"
                                      style={{ cursor: "pointer" }}
                                    >
                                      IHMM
                                    </label>
                                  </div>
                                  <div className=" form-check-inline">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="form-check-input"
                                      type="checkbox"
                                      name="abestos"
                                      checked={abestos}
                                      id="inlineCheckbox3"
                                      value={abestos}
                                      onChange={(e) => this.checkBoxClick(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox3"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Asbestos Removal / Management
                                    </label>
                                  </div>
                                  <div className=" form-check-inline">
                                    <input
                                      disabled={
                                        disableFields ||
                                        !isActive ||
                                        this.state.userRolePermissionRead
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="form-check-input"
                                      type="checkbox"
                                      name="others"
                                      checked={others}
                                      id="inlineCheckbox4"
                                      value={others}
                                      onChange={(e) => this.checkBoxClick(e)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineCheckbox4"
                                      style={{ cursor: "pointer" }}
                                    >
                                      Others
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {listMaritecData != undefined &&
                          listMaritecData.length > 0
                          ? listMaritecData.map((item, index) => {
                            return (
                              <div className="ship-details-block" key={index}>
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Service Category:</h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6" id={"shipType"}>
                                        <Dropdown
                                          dropdownLabel=""
                                          name="typeOfShip"
                                          onChange={this.onDropdownItemSelect}
                                          selected={item.serviceCategoryId}
                                          disabled
                                          style={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "pre",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {serviceCategoryList == null
                                            ? null
                                            : serviceCategoryList.map(
                                              (item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              )
                                            )}
                                          ;
                                        </Dropdown>
                                        {item.serviceCategoryId > 0 ? (
                                          <UncontrolledTooltip
                                            autohide={false}
                                            target={"shipType"}
                                            aria-haspopup="true"
                                            role="example"
                                            effect="solid"
                                            placement="bottom"
                                            style={{ width: "350px" }}
                                          >
                                            {serviceCategoryList != null && serviceCategoryList.filter(x => x.value == item.serviceCategoryId)[0]?.label}
                                          </UncontrolledTooltip>) : null}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5
                                          className={
                                            item.shipServiceCategoryBridgeId !=
                                              null ||
                                              disableFields ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Project Number:
                                        </h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                          className={
                                            maritecEmptyError &&
                                              item.projectNumber == undefined
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <input
                                            type="text"
                                            name="projectNumber"
                                            disabled={
                                              item.shipServiceCategoryBridgeId !=
                                              null ||
                                              disableFields ||
                                              this.state
                                                .userRolePermissionRead
                                            }
                                            value={
                                              item.projectNumber == undefined
                                                ? ""
                                                : item.projectNumber
                                            }
                                            onChange={(e) =>
                                              this.onTextMaritecChange(
                                                e,
                                                item.name
                                              )
                                            }
                                            className="form-control"
                                            maxLength={20}
                                          />
                                          {maritecEmptyError &&
                                            item.projectNumber ==
                                            undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5
                                          className={
                                            disableFields ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Date of Contract Commencement:
                                        </h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                          className={
                                            maritecEmptyError &&
                                              item.contractDate == undefined
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <DatePicker
                                            disabled={
                                              disableFields ||
                                              this.state
                                                .userRolePermissionRead
                                            }
                                            // readOnly
                                            min="0"
                                            maxDate={item.expiryDate}
                                            selectedDate={item.contractDate}
                                            onDateChange={(e) =>
                                              this.onMaritecDateChange(
                                                e,
                                                item.name,
                                                "contractDate"
                                              )
                                            }
                                          >
                                            {" "}
                                          </DatePicker>
                                          {maritecEmptyError &&
                                            item.contractDate ==
                                            undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb ">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5
                                          className={
                                            disableFields ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Date of Contract Expiry:
                                        </h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                          className={
                                            maritecEmptyError &&
                                              item.expiryDate == undefined
                                              ? "invalid-position"
                                              : ""
                                          }
                                        >
                                          <DatePicker
                                            disabled={
                                              disableFields ||
                                              this.state
                                                .userRolePermissionRead
                                            }
                                            // readOnly
                                            min="0"
                                            minDate={item.contractDate}
                                            selectedDate={item.expiryDate}
                                            onDateChange={(e) =>
                                              this.onMaritecDateChange(
                                                e,
                                                item.name,
                                                "expiryDate"
                                              )
                                            }
                                          >
                                            {" "}
                                          </DatePicker>
                                          {maritecEmptyError &&
                                            item.expiryDate == undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5
                                          className={
                                            disableFields ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          {item.name == "ihm"
                                            ? "IHM"
                                            : item.name == "ihmm"
                                              ? "IHMM"
                                              : item.name == "abestos"
                                                ? "Asbestos Removal / Management"
                                                : "Others"}{" "}
                                          In Charge:
                                        </h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                          className={
                                            maritecEmptyError &&
                                              item.dropdown_inChargeUserId ==
                                              undefined
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"inchargeUser"}
                                        >
                                          <Dropdown
                                            disabled={
                                              disableFields ||
                                              this.state
                                                .userRolePermissionRead
                                            }
                                            dropdownLabel=""
                                            name="inChargeUserId"
                                            onChange={(e) => {
                                              this.onDropdownMaritecItemSelect(
                                                e,
                                                item.name
                                              );
                                            }}
                                            selected={
                                              item.dropdown_inChargeUserId
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {ihmmInchargeList == null
                                              ? null
                                              : ihmmInchargeList.map(
                                                (item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </option>
                                                )
                                              )}
                                            ;
                                          </Dropdown>
                                          {item.dropdown_inChargeUserId > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"inchargeUser"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {ihmmInchargeList != null && ihmmInchargeList.filter(x => x.value == item.dropdown_inChargeUserId)[0]?.label}
                                            </UncontrolledTooltip>) : null}
                                          {maritecEmptyError &&
                                            item.dropdown_inChargeUserId ==
                                            undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5
                                          className={
                                            disableFields ||
                                              this.state.userRolePermissionRead
                                              ? ""
                                              : "required-field"
                                          }
                                        >
                                          Alternate{" "}
                                          {item.name == "ihm"
                                            ? "IHM"
                                            : item.name == "ihmm"
                                              ? "IHMM"
                                              : item.name == "abestos"
                                                ? "Asbestos Removal / Management"
                                                : "Others"}{" "}
                                          In Charge:
                                        </h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                          className={
                                            maritecEmptyError &&
                                              item.dropdown_alternateinChargeUserId ==
                                              undefined
                                              ? "invalid-position"
                                              : ""
                                          }
                                          id={"inchargeAltUser"}
                                        >
                                          <Dropdown
                                            disabled={
                                              disableFields ||
                                              this.state
                                                .userRolePermissionRead
                                            }
                                            dropdownLabel=""
                                            name="alternateinChargeUserId"
                                            onChange={(e) => {
                                              this.onDropdownMaritecItemSelect(
                                                e,
                                                item.name
                                              );
                                            }}
                                            selected={
                                              item.dropdown_alternateinChargeUserId
                                            }
                                            style={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "pre",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {ihmmInchargeList == null
                                              ? null
                                              : ihmmInchargeList.map(
                                                (item, key) => (
                                                  <option
                                                    key={key}
                                                    value={item.value}
                                                  >
                                                    {item.label}
                                                  </option>
                                                )
                                              )}
                                            ;
                                          </Dropdown>
                                          {item.dropdown_alternateinChargeUserId > 0 ? (
                                            <UncontrolledTooltip
                                              autohide={false}
                                              target={"inchargeAltUser"}
                                              aria-haspopup="true"
                                              role="example"
                                              effect="solid"
                                              placement="bottom"
                                              style={{ width: "350px" }}
                                            >
                                              {ihmmInchargeList != null && ihmmInchargeList.filter(x => x.value == item.dropdown_alternateinChargeUserId)[0]?.label}
                                            </UncontrolledTooltip>) : null}
                                          {maritecEmptyError &&
                                            item.dropdown_alternateinChargeUserId ==
                                            undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="row maritec-detail-pb">
                                      <div className="col-md-12 col-lg-6"
                                        style={{ alignSelf: "center" }}>
                                        <h5>Year:</h5>
                                      </div>
                                      <div className="col-md-12 col-lg-6">
                                        <div
                                        // className={
                                        //   maritecEmptyError &&
                                        //     item.year == undefined
                                        //     ? "invalid-position"
                                        //     : ""
                                        // }
                                        >
                                          <input
                                            type="name"
                                            value={
                                              item.year == undefined
                                                ? ""
                                                : item.year
                                            }
                                            className="form-control"
                                            disabled
                                          />
                                          {/* {maritecEmptyError &&
                                            item.year == undefined && (
                                              <ErrorView errorFor="emptyText" />
                                            )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="admin-ship-document">
                  {shipId != 0 && shipId != null && shipId != "" && (
                    <div className="container">
                      {!disableFields &&
                        isActive &&
                        !this.state.userRolePermissionRead ? (
                        <div className="tooltip">
                          <img
                            src="images/add-document.png"
                            alt="add-document"
                            id="addDoc"
                            style={{ cursor: "pointer", width: "28px", height: "28px" }}
                            onClick={() => this.setState({ showModal: true })}
                            data-bs-toggle="modal"
                            className="add-document"
                            data-bs-target="#shipdetailupload-modal"
                            title="Add Document"
                          />
                        </div>
                      ) : null}
                      {mappedDocuments != null && mappedDocuments.length > 0 ? (
                        <img
                          src="images/ship-download-document.png"
                          alt="download-document"
                          style={{ cursor: "pointer", width: "28px", height: "28px" }}
                          onClick={() => {
                            this.downloadShipDocData(0, shipId);
                          }}
                          title="Multi Download"
                          className="download-document"
                        />
                      ) : null}
                    </div>
                  )}
                </div>
                <div className="admin-ship-document-table">
                  <div className="row">
                    <div className="col-md-12">
                      <AGGrid
                        rowData={mappedDocuments}
                        colDefs={this.gridColumns(
                          disableFields || this.state.userRolePermissionRead
                        )}
                        onGridReady={this.onGridReady}
                        onSelectionChanged={(e) => this.onSelectionChanged(e)}
                        rowSelection="multiple"
                        pagination="10"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="admin-ship-settings-save-btn">
                {showErrorMessage && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}
                {showErrorMessageEmail && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter valid email address.</p>
                  </div>
                )}
                {showErrorMessageDuplicateEmail && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>
                      Ship Email Address and Communication Primary Email Address
                      cannot contain the same value.
                    </p>
                  </div>
                )}
                {imoNoExists && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Ship IMO Number already exists.</p>
                  </div>
                )}
                {primaryEmailExists && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Ship Email Address already exists.</p>
                  </div>
                )}
                {(dateRangeErrorEU || dateRangeErrorHKC) && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter a valid date.</p>
                  </div>
                )}
                {projectNumberError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter a unique project number.</p>
                  </div>
                )}
                {maritecEmptyError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please fill in all the mandatory fields.</p>
                  </div>
                )}

                {showErrorEmailAcctivation && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Please enter ship email address and save to proceed.</p>
                  </div>
                )}

                {shortEmployeeNameError && (
                  <div className="invalid-message-icon-box">
                    <img
                      src="images/icon material-error.png"
                      alt="invalid-icon"
                    />
                    <p>Ship Name must be atleast 3 characters long.</p>
                  </div>
                )}

                <button
                  className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles-btn manage-users-btn"
                  type="button"
                  disabled={
                    disableSave ||
                    isClientOrClientUser ||
                    !isActive ||
                    this.state.userRolePermissionRead
                  }
                  onClick={this.onSave}
                >
                  Save
                </button>

                {shipId > 0 && !isShipCrew ? (
                  <button
                    type="button"
                    className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive add-user-btn manage-users-btn"
                    onClick={this.onActivateDeactivateClicked}
                    disabled={
                      disableSave ||
                      isClientOrClientUser ||
                      !isActive ||
                      this.state.userRolePermissionRead
                    }
                  >
                    {!isAccountActivated
                      ? "Activate & Send Email"
                      : "Deactivate"}
                  </button>
                ) : null}
              </div>
            </div>
          </section>
        </main>

        {showPortRegistryModal && (
          <PortRegistryModal
            onCloseClick={this.showUploadModal}
            showModal={showPortRegistryModal}
            onPortRegistrySaveClicked={this.onPortRegistrySaveClicked}
            originalPortOfRegList={originalPortOfRegList}
            displayText={"Port of Registry"}
          />
        )}
        {this.state.showDirtyPopup && (
          <DirtyModal
            onModalCloseClick={this.onDirtyModalCloseClick}
            onProceedClick={this.onProceedClick}
          />
        )}
        {showModal && (
          <UploadFileModal
            onCloseClick={this.showUploadModal}
            clientName={clientName}
            shipName={shipName}
            documentTypeList={documentTypeList}
            flagStateList={flagStateList}
            onUploadClick={this.onUploadClick}
            onSaveNewTypeClick={this.onSaveNewTypeClick}
            isShip={isShipCrew}
          />
        )}

        {showHistoryModal && (
          <ViewHistoryModal
            onModalCloseClick={this.onViewHistoryModalClose}
            filesBasedDocumentType={filesBasedDocumentType}
            viewShipDocData={this.viewShipDocData}
            downloadShipDocData={this.downloadShipDocData}
          />
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteShipDocData}
          />
        )}
        <Footer />
      </div>
    );
  }
}

export default withRouter(AddEditShip);
