import React, { Component } from "react";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { ClientService } from "../../services/client.service";
import Dropdown from "../../components/Dropdown";
import AGGrid from "../../components/AGGrid";
import editIcon from "../../assets/images/Icon feather-edit.png";
import permissionIcon from "../../assets/images/permissionIcon.png";
import { AddLocalStorageData, GetLocalStorageDataWithParse, changePageTitle, helperText, withRouter, GetLocalStorageDataWithoutParse } from "../../helpers";
import Logout from "../../helpers/Logout";
import { isStringEmpty } from "../../helpers/string-helper";
import Toast from "../../components/Toast";
import RolesPermissionModal from "./ClientRolesPermissionModal";
import { UserAction, UserRole } from "../../helpers/enums";
import eyeIcon from "../../assets/images/admin-eye.png";
import { UncontrolledTooltip } from "reactstrap";

class ManageClientUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      toastVisible: false,
      toastMessage: "",
      toastType: "",
      mappedClientUsers: [],
      clientRoleList: [],
      defaultClientUsers: [],
      clientRolesPermissions: [],
      defaultClientRolePermission: [],
      dropdown_inheritedClientUserRoleId: 0,
      errorMessages: {},
      colDefs: this.gridColumns,
      clientName: "",
      rolesPermissionVisible: false,
      dropdown_clientRole: 0,
      clientUserId: 0,
      isEditable: false,
      roleId: 0,
      accessList: [],
      isClient: false,
      clientId: parseInt(GetLocalStorageDataWithoutParse("selectedClient"), 10) || 0,
      gridApi: null,
      userRolePermissionRead: false,
      searchedText: "",
      pageSize: 100,
      pageNumber: 1,
      hasMoreData: true,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }
/* <summary>
date: 03-12-2024
Name: MS
description: to call api on page change in Aggrid
<summary>*/ 
onScroll = () => {
  if (!this.state.isLoading && this.state.hasMoreData) {
    this.getManageClientUserData(this.state.clientId,this.state.pageSize,this.state.pageNumber);
  }
};
  onSortChanged(e) {
    e.api.refreshCells();
  }
  gridColumns = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Client PIC",
      field: "picname",
      tooltipField: "picname",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Role",
      field: "roleName",
      tooltipField: "roleName",
      wrapText: true,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Ships",
      field: "shipNames",
      tooltipField: "shipNames",
      width: 300,
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Service Category",
      field: "serviceCategoryName",
      tooltipField: "serviceCategoryName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Email Address",
      field: "emailAddress",
      tooltipField: "emailAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      wrapText: true,
    },
    {
      //headerName: "Permissions",
      pinned: "right",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 40,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
        textAlign: "center",
        paddingInline: "0"
      },
      cellRenderer: (params) => (
        <center>
          <img
            className="cursor-class"
            src={permissionIcon}
            onClick={(e) => {
              this.editClientUserPermission(params.data);
            }}
            width="20px"
            style={{ cursor: "pointer" }}
            title="View Permission"
          />
        </center>
      ),
    },
    {
      pinned: "right",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={
            this.state.accessList != null &&
              (this.state.accessList.addClientAccess == UserAction.READ ||
                this.state.accessList.addClientAccess == UserAction.DENY ||
                this.state.accessList.clientAndShipAccess == UserAction.READ ||
                this.state.accessList.clientAndShipAccess == UserAction.DENY)
              ? eyeIcon
              : editIcon
          }
          onClick={() => {
            this.addEditClientUser(params.data);
          }}
          width="20px"
          style={{ cursor: "pointer" }}
          title={
            this.state.accessList != null &&
              (this.state.accessList.addClientAccess == UserAction.READ ||
                this.state.accessList.addClientAccess == UserAction.DENY ||
                this.state.accessList.clientAndShipAccess == UserAction.READ ||
                this.state.accessList.clientAndShipAccess == UserAction.DENY)
              ? "View"
              : "Edit"
          }
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" }
    },
  ];

  /* <summary>
   date: 23/01/2023
   Name: SYJ
   description: addEditClientUser button click from grid, navigate to the Edit Page and Bind Data 
   <summary> */
  addEditClientUser = (data) => {
    AddLocalStorageData("selectedClientUserId", data.clientUserId);
    AddLocalStorageData("selectedClientUser", data);
    var clientData = GetLocalStorageDataWithParse("selectedClient");
    //AddLocalStorageData("SelectedClientUserDataFromGrid", JSON.stringify(data));
    var clientId = clientData.clientId;
    AddLocalStorageData("selectedClientID", clientId);
    this.props.navigate("/AddEditClientUser");
  };

  /* <summary>
   date: 23/01/2023
   Name: SYJ
   description: addEditClientUser button click from grid, navigate to the Edit Page and Bind Data 
   <summary> */
  editClientUserPermission = (data) => {
    AddLocalStorageData("selectedClientUserId", data.clientUserId);
    AddLocalStorageData("selectedClientUser", data);
    var clientData = GetLocalStorageDataWithParse("selectedClient");
    //AddLocalStorageData("SelectedClientUserDataFromGrid", JSON.stringify(data));
    var clientId =
      clientData.clientId != undefined
        ? clientData.clientId
        : clientData.clientID;
    AddLocalStorageData("selectedClientID", clientId);
    this.setState(
      {
        clientName: clientData.clientName,
        clientId:
          clientData.clientId != undefined
            ? clientData.clientId
            : clientData.clientID,
        clientUserId: data.clientUserId,
        roleId: data.roleId,
      },
      () => {
        this.getClientAndDefaultAccessPermissions(data.roleId);
      }
    );
  };

  /* <summary>
   date: 21/12/2022
   Name: SYJ
   description: Get data to bind on the manage Cleint User screen
   <summary> */
  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      var clientData = GetLocalStorageDataWithParse("selectedClient");

      this.setState(
        {
          userId: userData.id,
          clientName:
            clientData != null && clientData?.clientName != undefined
              ? clientData.clientName
              : "",
          clientId:
            clientData != null
              ? clientData?.clientName != undefined
                ? clientData.clientId
                : clientData.clientID
              : 0,
          isClient: userData.typeId == UserRole.CLIENT,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
        },
        () => {
          this.getDropdownData();
        }
      );
    }
  }

  /* <summary>
    date: 23/01/2023
    Name: SYJ
    description: Get all Client User Data to bind in the Ag grid, fill in the dropdown used for filtering
    <summary> */
  getManageClientUserData = async (clientId,pageSize,pageNumber) => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.clientAndShipAccess == UserAction.READ,
      });
      const response = await ClientService.GetManageClientUserData(clientId,pageSize,pageNumber);

      if (
        response.data != null &&
        response.data != undefined &&
        response.status == 200
      ) {
        // this.setState({
        //   mappedClientUsers: response.data,
        //   clientName: response.data[0]?.clientName,
        //   defaultClientUsers: response.data,
        // });
        AddLocalStorageData("totalClientUsers",response?.data[0]?.count)
        this.setState((prevState) => ({
          mappedClientUsers: [...prevState.mappedClientUsers, ...response.data],
          defaultClientUsers:[...prevState.mappedClientUsers, ...response.data],
          clientName: prevState.clientName || response.data[0]?.clientName,
          pageNumber: prevState.pageNumber + 1,
          hasMoreData: [...prevState.mappedClientUsers, ...response.data].length < 1000, // If fewer records are fetched, assume no more data
        }));
        if (response.status == 401) {
          Logout(this.props.navigate);
        }
      } 
      else {
        this.setState({
          mappedClientUsers: [],
          defaultClientUsers: [],
          hasMoreData:false
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  getDropdownData = async () => {
    this.setState({ isLoading: true });
    const { clientId } = this.state;

    const response = await ClientService.GetClientDropdownData(clientId, 0);
    if (response != null && response != undefined && response.status == 200) {
      this.setState(
        {
          clientRoleList: response.data.clientRoleList,
        },
        () => {
          this.getManageClientUserData(this.state.clientId,this.state.pageSize,this.state.pageNumber);
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: "fail",
      });
    }
  };

  /* <summary>
 date: 26/01/2023
 Name: SYJ
 description: Get Default permissions of Client
 <summary> */
  getClientAndDefaultAccessPermissions = async (roleId) => {
    const response =
      await ClientService.GetClientUserAndDefaultAccessPermissions(
        this.state.clientId,
        this.state.clientUserId,
        roleId
      );

    if (
      response != null &&
      response != undefined &&
      response.data != null &&
      response.status == 200
    ) {
      this.setState({
        defaultRolesPermissions: response.data.defaultClientRolesPermissions,
        clientRolesPermissions: response.data.customClientRolesPermission,
        defaultClientRolePermission: response.data.defaultClientRolesPermission,
        dropdown_inheritedClientUserRoleId:
          response.data.customClientRolesPermission == null
            ? 0
            : response.data.customClientRolesPermission.inheritedClientLevelId,
        rolesPermissionVisible: true,
        isEditable: false,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
      });
    }
  };

  /* <summary>
    date: 23/01/2023
    Name: SYJ
    description: filtering Dropdown Data
    <summary> */
  onDropDownSelected = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterGridView()
    );
  };

  /* <summary>
     date: 25/01/2023
     Name: SYJ
     description: Filter grid data using the dropdown selected
     <summary> */
  filterGridView = () => {
    const { dropdown_clientRole, searchedText } = this.state;

    var filterList = [];
    var user_clientRole =
      dropdown_clientRole == undefined ? 0 : parseInt(dropdown_clientRole, 10);
    var clientList = this.state.defaultClientUsers;

    if (user_clientRole == 0) {
      filterList = this.state.defaultClientUsers;
    } else {
      if (parseInt(dropdown_clientRole, 10) > 0) {
        filterList =
          filterList.length > 0
            ? filterList.filter(
              (e) => e.roleId == parseInt(dropdown_clientRole, 10)
            )
            : clientList.filter(
              (e) => e.roleId == parseInt(dropdown_clientRole, 10)
            );
      }
    }

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.picname?.toString().toLowerCase().includes(value) ||
          t.shipNames?.toString().toLowerCase().includes(value) ||
          t.emailAddress?.toString().toLowerCase().includes(value) ||
          t.roleName?.toString().toLowerCase().includes(value) ||
          t.serviceCategoryName?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedClientUsers: filterList,
    });
  };

  ApplyCustomPermissions = (
    accessPermissions,
    dropdown_inheritedClientUserRoleId
  ) => {
    this.setState({
      clientRolesPermissions: accessPermissions,
      dropdown_inheritedClientUserRoleId: dropdown_inheritedClientUserRoleId,
    });
    this.closeUserPermissionsModal();
  };

  closeUserPermissionsModal = () => {
    this.setState({
      rolesPermissionVisible: false,
    });
  };

  goback = () => {
    var location = GetLocalStorageDataWithoutParse("location");

    if (location == "/ActionRequired") {
      this.props.navigate("/ActionRequired");
    } else if (location == "/AddEditClient") {
      AddLocalStorageData("selectedClient", this.state.clientId);
      this.props.navigate("/AddEditClient");
    } else if (location == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else {
      this.state.isClient
        ? this.props.navigate("/ClientDashboard")
        : this.props.navigate("/ManageClient");
    }
    AddLocalStorageData("location", null);
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  /* <summary>
      date: 6/2/2023
      Name: NGG
      description: Filter data from grid using search bar
      <summary> */
  onSearch = (e) => {
    var inputValue = e.target.value;
    var clientUserList = this.state.defaultClientUsers;

    this.setState(
      {
        searchedText: inputValue,
      },
      () => {
        this.filterGridView();
      }
    );

    // if (inputValue.trim() !== "") {
    //   var value = inputValue.trim().toString().toLowerCase();

    //   clientUserList = clientUserList.filter(
    //     (t) =>
    //       t.picname.toString().toLowerCase().includes(value) ||
    //       t.shipNames.toString().toLowerCase().includes(value) ||
    //       t.emailAddress.toString().toLowerCase().includes(value) ||
    //       t.roleName.toString().toLowerCase().includes(value) ||
    //       t.serviceCategoryName.toString().toLowerCase().includes(value)
    //   );
    // }

    // this.setState({
    //   mappedClientUsers: clientUserList,
    // });
  };

  /* <summary>
   date: 13/02/2023
   Name: HV
   description: clearing filter
   <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_clientRole: "0",
        dropdown_clientName: "0",
        dropdown_shipName: "0",
        dropdown_region: "0",
        dropdown_supplier: "0",
        dropdown_documentname: "0",
        dropdown_documenttype: "0",
        dropdown_linename: "0",
        dropdown_hazmatpresent: "0",
        dropdown_tableAFilter: "0",
        dropdown_tableBFilter: "0",
        filter_fromDate: "0",
        filter_toDate: "0",
        searchedText: "",
      },
      () => {
        this.filterGridView();
      }
    );
  };

  onGridReady = (params) => {
    params.api.sizeColumnsToFit();

    this.setState({
      gridApi: params.api,
    });
  };

  render() {
    const {
      mappedClientUsers,
      colDefs,
      defaultRolesPermissions,
      clientRolesPermissions,
      isLoading,
      toastVisible,
      toastMessage,
      toastType,
      clientName,
      clientRoleList,
      dropdown_clientRole,
      rolesPermissionVisible,
      errorMessages,
      dropdown_inheritedClientRoleId,
      isEditable,
      defaultClientRolePermission,
      roleId,
      accessList,
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageClientUsers"} />

        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        {rolesPermissionVisible && (
          <RolesPermissionModal
            showModal={rolesPermissionVisible}
            clientRoleList={this.state.clientRoleList}
            clientRolesPermissions={clientRolesPermissions}
            defaultRolesPermissions={defaultRolesPermissions || []}
            dropdown_inheritedClientLevelID={dropdown_inheritedClientRoleId}
            roleId={roleId}
            defaultClientRolePermission={defaultClientRolePermission}
            isDataEditable={isEditable}
            ApplyCustomPermissions={this.ApplyCustomPermissions}
            closeModal={this.closeUserPermissionsModal}
          />
        )}

        <main className="page-height">
          <section className="communications">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    height="22px"
                    style={{ pointerEvents: "all", cursor: "pointer" }}
                    onClick={this.goback}
                  />
                  <span className="title-font">Manage Client Users</span>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div className="searchBar">
                    <Searchbar
                      placeholder={"Client PIC, Ships, etc..."}
                      onChange={this.onSearch}
                    />
                  </div>
                </div>
              </div>
              <div className="accordion-filter-dropdown">
                <div className="accordion" id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="form-group dropdown-form form-drpdown-responsive admin-client-form">
                          <input
                            type="name"
                            value={clientName}
                            className="form-control"
                            disabled
                          />
                        </div>

                        <div className="form-group dropdown-form admin-client-form">
                          <div
                            className={
                              !isStringEmpty(errorMessages.clientRoleValidation)
                                ? "invalid-position"
                                : ""
                            }
                            id={"clientRole"}
                          >
                            <Dropdown
                              dropdownLabel=""
                              name="clientRole"
                              onChange={this.onDropDownSelected}
                              selected={dropdown_clientRole}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {clientRoleList == null
                                ? null
                                : clientRoleList.map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_clientRole > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"clientRole"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {clientRoleList != null && clientRoleList.filter(x => x.value == dropdown_clientRole)[0]?.label}
                              </UncontrolledTooltip>) : null}

                            {!isStringEmpty(
                              errorMessages.clientRoleValidation
                            ) && (
                                <div className="invalid-dropdown-icon">
                                  <img
                                    src="images\icon material-error.png"
                                    alt="invalid"
                                  ></img>
                                </div>
                              )}
                          </div>
                        </div>
                        <div
                          className="col-12 col-md-4 col-lg-3 "
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            type="button"
                            className="btn btn-blue-bg"
                            onClick={this.clearAllFilter}
                            style={{ marginTop: 24 }}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-row">
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form">
                      <label className="label-class">Client:</label>
                      <input
                        title={clientName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                        type="name"
                        value={clientName}
                        className="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form admin-client-form">
                      <label className="label-class">Role: </label>
                      <div
                        className={
                          !isStringEmpty(errorMessages.clientRoleValidation)
                            ? "invalid-position"
                            : ""
                        }
                        id={"clientRole1"}
                      >
                        <Dropdown
                          dropdownLabel=""
                          name="clientRole"
                          onChange={this.onDropDownSelected}
                          selected={dropdown_clientRole}
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "pre",
                            overflow: "hidden",
                          }}
                        >
                          {clientRoleList == null
                            ? null
                            : clientRoleList.map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          ;
                        </Dropdown>
                        {dropdown_clientRole > 0 ? (
                          <UncontrolledTooltip
                            autohide={false}
                            target={"clientRole1"}
                            aria-haspopup="true"
                            role="example"
                            effect="solid"
                            placement="bottom"
                            style={{ width: "350px" }}
                          >
                            {clientRoleList != null && clientRoleList.filter(x => x.value == dropdown_clientRole)[0]?.label}
                          </UncontrolledTooltip>) : null}
                        {!isStringEmpty(errorMessages.clientRoleValidation) && (
                          <div className="invalid-dropdown-icon">
                            <img
                              src="images\icon material-error.png"
                              alt="invalid"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12 col-md-4 col-lg-3 desktop-clear-btn"
                  style={{ alignSelf: "center" }}
                >
                  <button
                    type="button"
                    className="btn btn-blue-bg"
                    onClick={this.clearAllFilter}
                    style={{ marginTop: 24 }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-12 client-user-btn-top-space">
                  <div className="float-btn manage-client-button">
                    {this.state.isClient && (
                      <button
                        type="button"
                        disabled={
                          accessList != null &&
                            accessList.clientAndShipAccess == UserAction.READ
                            ? true
                            : false
                        }
                        onClick={() =>
                          this.props.navigate("/ManageClientRolesAndPermission")
                        }
                        className="btn btn-background-color view-history-btn btn-padding btn-responsive manage-roles manage-add-client"
                      >
                        View Roles and Permissions
                      </button>
                    )}
                    <button
                      type="button"
                      disabled={
                        accessList != null &&
                          (accessList.addClientAccess == UserAction.READ ||
                            accessList.addClientAccess == UserAction.DENY ||
                            this.state.userRolePermissionRead)
                          ? true
                          : false
                      }
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.addEditClientUser(0)}
                    >
                      <img src="images/admin-plus-icon.png" alt="plus-icon" />
                      Add User
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={mappedClientUsers}
                      colDefs={colDefs}
                      rowSelection="single"
                      pagination="100"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                      onGridReady={this.onGridReady}
                      isClientUsers={true}
                      onScroll={this.onScroll}
                      lazyLoading={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <br />
        <br />
        <Footer />
      </>
    );
  }
}

export default withRouter(ManageClientUsers);
