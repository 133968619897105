export const helperText = {
    loginPageText: {
        emailSentText: "Password reset email has been sent successfully",
        errorSendingMail: "Email address is not registered",
        errorUsername: "Please enter your username.",
        emailPlaceholder: "Please enter account email address.",
        errorPassword: "Please enter your password.",
        //passwordPlaceholder: "Please enter your password.",
        userNotActive: "User is not active",
        LoginFailed: "Incorrect username or password",
        errorCaptcha: "Incorrect captcha entered. Please try again.",
        errorEmptyCaptcha: "Please check the reCAPTCHA box to proceed.",
        errorCheckbox: "Please check the box to proceed.",
        SessionExpired: "Session timeout. Please login again",
        emailValidation: "Please enter email address.",
        invalidEmailValidation: "Please enter valid email address.",
        emailNotRegister:
            "Email address is not registered. Please enter a valid email address.",
        maxLoginLimitExceeded:
            "User login has crossed the maximum active sessions, hence you wont be able to access the site.",
        forgotRequestSent: "Forgot password request sent successfully.",
        adminEmailPasswordChange:
            "Please contact the eDOT Team to change the Admin password.",
        supplierOnHold:
            "Supplier is on hold, please contact the administrator for further details.",
    },

    global: {
        TermsCheck: "Please select the terms and conditions.",
        sessionTimeout: "Your session has expired. You will be logged out now.",
        apiDataDeletedSuccessfully: "Data imported from API has been deleted successfully.",
        somethingWentWrong:
            "Something went wrong. If error persists, please contact the IHM Administrator.",
        emailSent: "An email has been sent successfully.",
        saveSuccess: "Data has been saved successfully.",
        downloadSuccess: "Downloaded successfully.",
        downloadInProgress: "Downloaded is in progress.",
        buttonDisabledTooltip: "Button is disabled for new user accounts.",
        toastSuccess: "success",
        toastError: "error",
        toastHtmlContent: "htmlContent",
        showValidationMessage: "Please fill mandatory data.",
        noDocumentsSelected: "Please select at least one document to download.",
        noHistoryData: "No data to display",
        documnetDownloadSuccess: "Document has been downloaded successfully.",
        documnetUploadedSuccess: "Document has been uploaded successfully.",
        usernameAlreadyExists: "Username already exist.",
        selectClient: "Please select Client to generate report.",
        selectClientShip: "Please select Client, Ship, From and To Date to generate report.",

        reportDownloadSuccess: "Report has been downloaded successfully.",
        selectFromAndToDate: "Please select From and To Date to generate report.",
        selectClientAndDate:
            "Please select Client, From and To Date to generate report.",
        reportUnavailbale: "Report Currently unavailable.",
        selectSubject: "Please select Subject/Email Type in order to proceed.",
        nofileToDownload: "No file to download.",
        deletedDocument: "Document has been deleted successfully.",
        deletedDocumentdLineItem: "Line item has been deleted successfully.",
        trackingToolAddRowInstruction:
            "Please click on Enter to save the data after filling all the fields.",
        reportGenerateSuccess: "Report has been generated successfully.",
        clientEmailFethError: "No emails Found",
        wrongFileTypeError:
            "You have attached the wrong file type, please upload a file type of jpg, jpeg, or png.",
        deletedProfile: "profile Image has been deleted successfully.",
        wrongFileError:
            "You have attached the wrong file type, please upload a file type of excel, doc, or pdf.",
        wrongSupplierFileError:
            "You have attached the wrong file type, please upload a file type of pdf, jpg, jpeg or PNG.",
        wrongFileSupMDSDoCError:
            "You have attached the wrong file type, please upload a file type of pdf or doc",
        wrongCommunicationFileError:
            "You have attached the wrong file type, please upload a file type of pdf, excel, doc, jpg, jpeg or PNG.",
        accessError: "You do not have access to this section.",
        hazmatAddSuccess: "Hazmat added successfully.",
        clientEmailFetchError: "Error ocurred while fetching client emails.", 
        noEmailsToFetchError: "No more emails to load.", 
        deleteFilledMDSDoC: `delete?
      Note: The document that has recently been uploaded is displayed in the grid. Deleting the current MD/SDoC, the earlier MD/SDoC provided for this line item will be shown if the user has previously uploaded.`,
    },

    ResetPasswordPageText: {
        enterPassword: "Please enter new password",
        invalidPassword:
            "Your password must contain one lowercase letter, one uppercase letter, one number, and one symbol",
        enterConfirmPassword: "Please enter confirm password",
        identicalPassword: "Confirm password not matching",
        resetPasswordSuccess: "Password has been reset successfully.",
    },

    Communication: {
        toAddressValidation: "Please enter Email Id.",
        ccAddressValidation: "Please enter CC Email Id.",
        bccAddressValidation: "Please enter BCC Email Id.",
        subjectValidation:
            "The subject field cannot be left blank. Please enter a subject for your email.",
        emailBodyValidation:
            "The message field cannot be left blank. Please enter a message for your email.",
        communicationAdded: "Communication sent successfully.",
        invalidEmailValidation:
            "The email address is not valid. Please enter a valid email address.",
        templateNameValidation: "Please enter Template Name.",
        maritecEmailValidation:
            "Please enter email address other than @maritec.com.sg",
        secondaryPrimaryEmailValidation:
            "Please enter email address other than Client Email Address.",
    },

    //Messages used in Add Internal user/Edit Internal User/UserProfile screen
    UserProfile: {
        profileNameValidation: "Please enter Employee Name.",
        designationValidation: "Please enter Designation.",
        emailValidation: "Please enter Email Address.",
        phoneNoValidation: "Please enter Phone Number.",
        addressValidation: "Please enter Address.",
        nameValidation: "Please enter Username.",
        //passwordValidation: "Please set/request Password.",
        picValidation: "Please select Profile Picture.",
        userAdded: "User details added successfully.",
        userUpdated: "Details updated successfully.",
        setPassword: "Password reset successfully.",
        regionValidation: "Please select region.",
        userRoleValidation: "Please select user role.",
        userLevelValidation: "Please select user level.",
        clientValidation: "Please select client.",
        shipValidation: "Please select ship.",
        saveUserDataToChangePassword:
            "Please save the user data to change password.",
        emailAlreadyExists: "Email Address is already registered.",
        outlookEmailIdValidation: "Please enter User Id",
        outlookPasswordValidation: "Please enter Password",
    },

    Supplier: {
        setOnHold: "Supplier is set on hold successfully.",
        supplierExist: "Supplier already exist.",
        supplierAdded: "Supplier is added successfully",
        supplierUpdated: "Details updated successfully.",
        userNameValidation: "Please enter username.",
        companyNameValidation: "Please enter company name.",
        compContactValidation: "Please enter company contact number.",
        supplierNameValidation: "Please enter supplier name.",
        designationValidation: "Please enter designation.",
        emailValidation: "Please enter email address.",
        contactValidation: "Please enter supplier contact number.",
        issuerNameValidation: "Please enter issuer name.",
        digitalSignatureValidation: "Please attach digital signature.",
        errorPassword: "Please enter your password.",
        changeRequestSent: "New password request has been sent successfully.",
        supplierPONumberNotSelected: "Please select atleast one PO Number.",
    },

    ContactUs: {
        emptyName: "Contact name cannot be empty.",
        emptyEmail: "Email cannot be empty.",
        wrongEmail: "Email is not correct.",
        emptyContactNumber: "Contact number cannot be empty.",
        emptySubject: "Subject cannot be empty.",
        emptyMessage: "Message cannot be empty.",
        saveSuccess: "Your message has been sent successfully.",
        saveError: "Error while saving",
    },

    TrackingTool: {
        POFileImportedSuccess: "PO File uploaded successfully.",
        POFileImportedNoRecords: "No PO records to fetch.",
        POFileSavedSuccess: "PO File Saved Successfully.",
        HazmatItemsImported: "Suspected Hazmat Items Imported Successfully.",
        copyPasteRowData: "Data saved successfully.",
        importedFileShipMissing:
            "The ships mentioned in the import list does not match with records found in the portal. Please check and upload again.",
        addCurrentDataAsHazmat:
            "Please add the suspected hazmat items to Tracking Tool.",
        requestMDSDoCSent: "Request for MDSDoC sent successfully.",
        otherValueData: "Other value saved successfully.",
        noLineItemHistory: "No line item history data to display",
        selectClientTT: "Select a client to view the tracking tool detail.",
        selectLineItemToUploadDocument: "Select a line item to upload a document.",
        selectLineItemToDownloadDocument:
            "Select a line item to download a document.",
        selectLineItemToSendReminder: "Select a line item to send reminder email.",
        multipleReminderEmail:
            "Email has been sent to Supplier: [supplierNames] requesting for MD/SDoC documents for PO Number: [poNumbers]",
        incorrectExcel:
            "Excel is empty or the data is not in a correct format. Please recheck and upload the document.",
        poNumberMandatory: "Enter a PO number to save the line item.",
        selectToAddHazard: "Select a line item to add as Hazmat.",
        selectClientAndYear: "Please select client and year to upload PO Items.",
        selectOneLineItem:
            "Multiple line items selected please select only one line item to upload MD/SDoC.",
        NoSupplierPresent:
            "Selected line item does not contain any supplier linked to it.",
        documnetUploadedSuccess: "Data saved successfully.",
        noFileInSelectedLineItem: "The line item does not have a PO uploaded.",
        supplierDetailsMissing: "Please update the supplier details.",
        wrongPOFileTpeExcelError:
            "You have attached the wrong file type, please upload a file type of excel.",
        wrongPOFileTpePDFError:
            "You have attached the wrong file type, please upload a file type of pdf.",
        wrongExcelFile:
            "You have attached the wrong file type, please upload a file type of excel.",
        wrongPOFileTpeError:
            "You have attached the wrong file type, please upload a file type of excel or pdf.",
        wrongPDFFile:
            "You have attached the wrong file type, please upload a file type of pdf.",
        shipNotMatching:
            "Selected ship name doesn't match with the data you want to save.",
        trackingToolEmpty: "No Tracking Tool data to download.",
        selectYearError: "Please select year to add manual record.",
        invalidPODateError:
            "The PO date you're trying to save doesn't match the selected period of the contract year from the year dropdown.",
        documentLinked: "Document has been linked successfully.",
        supplierDetailsPartiallyFilled:
            "</br><b>Note:</b> A supplier profile will not be created if supplier details are partially entered.",
        autoAddSupplierMessage:
            "Please enter supplier name."
    },

    Training: {
        TrainigDataSavedSuccess: "Training Data Saved Successfully.",
        selectLineItemToDownloadDocument:
            "Select a line item to Download a document.",
    },

    //A.N. - 06/03/2023 - Added helptext message for noShipsPresent.
    Client: {
        userNameValidation: "Please enter username.",
        clientNameValidation: "Please enter client name.",
        clientCodeValidation: "Please enter client code.",
        clientContact: "Please enter client contact number.",
        clientRoleValidation: "Please enter client role.",
        shipValidation: "Please select ship.",
        clientPicValidation: "Please enter client PIC.",
        primaryEmailAddressValidation: "Please enter primary email address.",
        secondaryEmailAddressValidation: "Please enter secondary email address.",
        clientAddressValidation: "Please enter client address.",
        clientCityValidation: "Please enter client city.",
        clientPincodeValidation: "Please enter client postal code.",
        clientCountryValidation: "Please enter client country.",
        clientAdded: "Client data added successfully.",
        clientUpdated: "Details updated successfully.",
        serviceCategoryValidation: "Please enter service category.",
        clientContractualBriefTermsValidation:
            "Please enter client's contractual brief terms.",
        maritecBDMValidation: "Please select maritecBDM.",
        regionValidation: "Please select CTI region.",
        dateOfContractValidation: "Please select date of contract.",
        dateOfContractExpiryValidation: "Please select date of contract expiry.",
        dateOfCreationValidation: "Please select date of creation.",
        ihmmInChargeValidation: "Please enter IHMM in charge.",
        ihmmpicEmailAddressValidation: "Please enter IHMM PIC email address.",
        alternateihmmInChargeValidation: "Please enter alternate IHMM in charge.",
        alternateihmmPicEmailAddressValidation:
            "Please enter alternate IHMM PIC email address.",
        statusUpdated: "Status has been changed.",
        changeRequestUpdated: "Manage change request data updated successfully.",
        changeRequestSent: "Change request sent successfully.",
        noShipsPresent:
            "There are no ships assigned. Please get in touch with the administrator if you want to add the ships.",
    },

    Ship: {
        shipAdded: "Ship details added successfully.",
        shipUpdated: "Details updated successfully.",
        selectClient: "Please select client to add ship.",
        noClient: "No Client assigned to the logged in user.",
        deactivatedShip: "Ship Profile is deactivated.",
        activatedShip: "Ship Profile is activated and email is sent.",
    },

    MiscellaneousOperations: {
        moDataSavedSuccess: "Miscellaneous Operations Data Uploaded Successfully.",
    },

    ClientUser: {
        EmailRegistered: "Email Address is already registered.",
        clientUserAdded: "Client user details added successfully.",
        clientUserUpdated: "Details updated successfully.",
    },

    Notification: {
        fetchNotificationError: "Error occurred while fetching all notifications.",
        toDateNotificationError: "To Date must be greater than From Date.",
    },
    ActionRequired: {
        fetchActionRequiredError:
            "Error occurred while fetching action required data.",
        toDateActionRequiredError: "To Date must be greater than From Date.",
    },
    SettingsPageText: {
        settingsLoadSucess: "Data loaded successfully.",
        settingsLoadError: "Something went wrong.",

        activityLogUpdateSuccess: "Activity log settings saved successfully.",
        activityLogUpdateError: "Could not save activity log settings.",

        EmailReminderUpdateSuccess: "Email settings saved successfully.",
        EmailReminderUpdateError: "Could not save email settings.",

        ctiRegulationUpdateSuccess: "SDoC regulations settings saved successfully.",
        ctiRegulationUpdateError: "Could not save SDoC regulations settings.",

        ctiRegulationDeleteSuccess: "Data deleted successfully.",
        ctiRegulationDeleteError: "Could not Delete.",

        ctiRegulationNothingToSave: "No changes to save.",

        reportsUdpateSuccess: "Reports settings saved successfully.",
        reportsUdpateError: "Could not save reports settings.",

        loginUpdateSuccess: "Login settings saved successfully.",
        loginUpdateError: "Could not save login settings.",
    },

    Mdsdoc: {
        mdsdocSavedSuccessfully: "MDSDoC is saved successfully.",
        mdsdocSubmittedSuccessfully: "MDSDoC is submitted successfully.",
        remarkDeletedSuccessfully: "Remark deleted successfully.",
        objectDeclarationSuccessfully: "Object declaration deleted successfully.",
        objectDeclarationDeleteError:
            "Error occurred while deleting object declaration.",
        remarkDeleteError: "Error occurred while deleting remark.",
        objectDeclarationAddingError:
            "Error occurred while adding object declaration.",
        objectDeclarationAddingEmpty:
            "Please add a declaration to save for the MD/SDoC.",
        remarkAddingError: "Error occurred while adding remark.",
        remarkAddingEmpty: "Please add a remark to save for the MD/SDoC.",

        lineItemDeletedSuccessfully: "Line item deleted successfully.",
        lineItemDeleteError: "Error occurred while deleting line item.",
        lineItemAddingError: "Error occurred while adding line item.",
        lineItemAddingEmpty:
            "Please add a valid line item to save for the MD/SDoC.",
        lineItemAlreadyAddedError: "Line item already present.",

        moreThan1LineItemPresentError:
            "More than 1 line item present, please create separate MD/SDoc document for each line item if hazards present above threshold level.",
        hazardousPresentForLineItemError:
            "Hazardous contained present above threshold level in current line item, please create a separate MD/SDoc document.",
    },
};
