import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import { helperText } from "../../helpers/helperText";
import { ShipService } from "../../services/ship.service";
import editIcon from "../../assets/images/Icon feather-edit.png";
import viewIcon from "../../assets/images/admin-eye.png";
import viewTrackingTool from "../../assets/images/admin-upload-file.png";
import deactivatedIcon from "../../assets/images/deactivatedIcon.png";
import activatedIcon from "../../assets/images/activatedIcon.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Toast from "../../components/Toast";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import { AddLocalStorageData, GetLocalStorageDataWithParse, GetLocalStorageDataWithoutParse, changePageTitle, withRouter } from "../../helpers";
import Logout from "../../helpers/Logout";
import Switch from "react-switch";
import { UserAction, UserRole } from "../../helpers/enums";
import { UncontrolledTooltip } from "reactstrap";
import { isStringEmpty } from "../../helpers/string-helper";

var resetPageSize = "notReset"
var totalPOCount = 0;

class ManageShips extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clientName: "",
      clientId: 0,
      mappedShips: [],
      defaultShips: [],
      dropdownList: [],
      isLoading: false,
      toastMessage: null,
      toastType: "",
      toastVisible: false,
      showOnHoldModal: false,
      selectedSupplierId: null,
      onHoldFromDate: "",
      onHoldToDate: "",
      userId: 0,
      shipNameList: [],
      imoNoList: [],
      dropdown_shipName: 0,
      dropdown_imono: 0,
      dropdown_status: 0,
      filter_fromDate: "",
      filter_toDate: "",
      uniqueStatusList: [],
      statusChanged: false,
      accessList: [],
      isClient: false,
      clientList: [],
      dropdown_clientName: 0,
      isBackClicked: false,
      clientUserId: 0,
      isClientOrClientUser: false,
      navigatedFromManageClient:
        GetLocalStorageDataWithParse("navigatedFromManageClient") || false,
      isAdmin: false,
      userTypeId: 0,
      gridApi: null,
      userRolePermissionRead: false,
      searchedText: "",
      pageSize: 100,
      pageNumber: 1,
      hasMoreData: true,
      isLoad: false, count: 0,
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  /* <summary>
   date: 21/12/2022
   Name: NGG
   description: Get data to bind on the manage ships screen
   <summary> */
  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");

    var userData = GetLocalStorageDataWithParse("loggedInUser");
    var clientData = GetLocalStorageDataWithParse("selectedClient");

    if (userData != null) {
      let isclientUser = userData.typeId == UserRole.CLIENTUSER;
      this.setState(
        {
          userId: UserRole.CLIENT == userData.typeId ? 0 : userData.id,
          userTypeId: userData.typeId,
          clientId:
            clientData != null
              ? clientData?.clientName != undefined
                ? clientData?.clientId
                : clientData?.clientID
              : 0,
          isClient: UserRole.CLIENT == userData.typeId,
          dropdown_clientName:
            clientData != null
              ? clientData?.clientName != undefined
                ? clientData?.clientId
                : clientData?.clientID
              : 0,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          clientUserId: isclientUser ? userData.id : 0,
          isClientOrClientUser:
            UserRole.CLIENT == userData.typeId || isclientUser,
          isAdmin: userData.typeId == UserRole.ADMIN,
        },
        () => {
          const { clientId, userId, clientUserId, userTypeId } = this.state;
          this.getManageShipData(clientId, userId, clientUserId, userTypeId); //, userTypeId
        }
      );
    }
  }

  componentWillUnmount() {
    if (this.state.isBackClicked) {
      AddLocalStorageData("selectedClient", null);
    }
  }

  // componentDidUpdate() {
  //   if (this.state.gridApi) {
  //     this.state.gridApi.paginationGoToPage(0);
  //   }
  // }

  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
  };

  onSortChanged = (e) => {
    e.api.refreshCells();
  };
  /* <summary>
   date: 21/12/2022
   Name: NGG
   description: Bind AG grid columns
   <summary> */
  //HV - 06/03/2023 - handled navigating to tracking tool page on ship name clicked

  gridColumns = (hideColumn) => {
    return [
      {
        headerName: "Serial Number",
        // field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "Ship Name",
        field: "shipName",
        tooltipField: "shipName",
        width: 150,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
          cursor: "pointer",
        },
        cellRendererFramework: (params) => {
          return !this.state.isAdmin &&
            this.state.accessList != null &&
            this.state.accessList.trackingToolAccess == UserAction.DENY ? (
            <label>{params.data.shipName} </label>
          ) : (
            <label
              onClick={() => this.onViewTrackingToolClicked(params.data.shipId)}
              style={{ cursor: "pointer" }}
            >
              {params.data.shipName}{" "}
            </label>
          );
        },
      },
      {
        headerName: "Ship IMO Number",
        field: "imono",
        tooltipField: "imono",
        width: 190,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Statement of Compliance",
        field: "statementOfCompliancName",
        tooltipField: "statementOfCompliancName",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Ships Distinctive Number",
        field: "shipDistinctiveNo",
        tooltipField: "shipDistinctiveNo",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Port of Registry",
        field: "portOfRegistryName",
        tooltipField: "portOfRegistryName",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Gross Tonnage",
        field: "grossTonnage",
        tooltipField: "grossTonnage",
        width: 180,
        wrapText: true,
      },
      {
        headerName: "Class Number",
        field: "classNumber",
        tooltipField: "classNumber",
        width: 180,
        wrapText: true,
      },

      {
        headerName: "Classification Society",
        field: "classificationSocietyName",
        tooltipField: "classificationSocietyName",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship Owner Name",
        field: "shipOwnerName",
        tooltipField: "shipOwnerName",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship Owner Address",
        field: "shipOwnerAddress",
        tooltipField: "shipOwnerAddress",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Ship Owner Contact Details",
        field: "shipOwnerContactNo",
        tooltipField: "shipOwnerContactNo",
        width: 250,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "IMO Company Identification Number",
        field: "imocompanyIdentificationNo",
        tooltipField: "imocompanyIdentificationNo",
        width: 300,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "IHM Project Number",
        field: "ihmidentificationNo",
        tooltipField: "ihmidentificationNo",
        width: 250,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Date of Construction",
        field: "dateOfConstruction",
        tooltipField: "dateOfConstruction",
        width: 250,
        wrapText: true,
      },

      {
        headerName: "PIC Name",
        field: "shipIhmpicName",
        tooltipField: "shipIhmpicName",
        width: 190,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "PIC Contact Details",
        field: "shipIhmpicContactNo",
        tooltipField: "shipIhmpicContactNo",
        width: 250,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "PIC Email ID",
        field: "shipIhmpicEmailAddress",
        tooltipField: "shipIhmpicEmailAddress",
        width: 250,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Type of Vessel",
        field: "shipTypeName",
        tooltipField: "shipTypeName",
        width: 250,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },

      {
        headerName: "Name of Shipbuilder",
        field: "shipBuilderName",
        tooltipField: "shipBuilderName",
        width: 230,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Shipbuilder Address",
        field: "shipBuilderAddress",
        tooltipField: "shipBuilderAddress",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Shipbuilder Contact",
        field: "shipBuilderContactNo",
        tooltipField: "shipBuilderContactNo",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
        wrapText: true,
      },

      {
        headerName: "Date of Keel Laid",
        field: "dateOfKeelLaid",
        tooltipField: "dateOfKeelLaid",
        width: 200,
        wrapText: true,
      },

      {
        headerName: "Service Category",
        field: "serviceCategory",
        tooltipField: "serviceCategory",
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "IHMM Project Number",
        field: "projectNumber",
        tooltipField: "projectNumber",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "IHMM Handler",
        field: "ihmmHandler",
        tooltipField: "ihmmHandler",
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Alternate IHMM Handler",
        field: "alternateIHMMHandler",
        tooltipField: "alternateIHMMHandler",
        width: 230,
        wrapText: true,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        filter: false,
        width: 180,
        sortable: false,
        editable: false,
        headerName: "Account Activated/Deactivated",
        field: "isActivated",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
          textAlign: "center",
        },
        cellRenderer: (params) => (
          <img
            src={params.data.isActivated ? activatedIcon : deactivatedIcon}
            width="30px"
            title={params.data.isActivated ? "Activated" : "Deactivated"}
          />
        ),
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        headerName: "",
        width: 40,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={viewTrackingTool}
            width="20px"
            style={{ textAlign: "center" }}
            title="View Tracking Tool"
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() => this.onViewTrackingToolClicked(params.data.shipId)}
          />
        ),
        hide: hideColumn,
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        headerName: "",
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={
              !params.data.isActive ||
                this.state.isClientOrClientUser ||
                this.state.userRolePermissionRead
                ? viewIcon
                : editIcon
            }
            width="20px"
            title={
              !params.data.isActive ||
                this.state.isClientOrClientUser ||
                this.state.userRolePermissionRead
                ? "View"
                : "Edit"
            }
            data-bs-toggle="modal"
            data-bs-target="#view-history"
            onClick={() => {
              this.onAddEditShipClicked(params.data.shipId);
            }}
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        editable: false,
        headerName: "",
        field: "isActive",
        width: 50,
        cellRenderer: (params) => {
          if (params.data.clientStatus) {
            return (
              <div title={params.data.isActive ? "Active" : "Inactive"}>
                <Switch
                  className={
                    this.state.accessList != null &&
                      (this.state.isClientOrClientUser ||
                        this.state.accessList.clientAndShipAccess == UserAction.READ)
                      ? "switch disabled-switch"
                      : "switch"
                  }
                  height={20}
                  disabled={
                    this.state.accessList != null &&
                      (this.state.isClientOrClientUser ||
                        this.state.accessList.clientAndShipAccess == UserAction.READ)
                      ? true
                      : false
                  }
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor={"#C90000"}
                  width={45}
                  onChange={() => {
                    this.onSwitchChange(params.data.shipId, params.data.isActive);
                  }}
                  checked={params.data.isActive}
                />
              </div>
            );
          } else {
            return (
              <div title={params.data.isActive ? "Active" : "Inactive"}>
                <Switch
                  className="switch disabled-switch"
                  height={20}
                  disabled={true}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  offColor={"#C90000"}
                  width={45}
                  checked={params.data.isActive}
                />
              </div>
            );
          }
        },
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },
    ];
  };

  /* <summary>
   date: 5/01/2023
   Name: NGG
   description: Navigates to Add/Edit ship Page
   <summary> */
  onAddEditShipClicked = (shipId) => {
    const { dropdown_clientName, clientList } = this.state;

    let clientId = parseInt(dropdown_clientName, 10);

    if (clientId == 0) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.Ship.selectClient,
        toastType: helperText.global.toastError,
      });
    } else {
      if (shipId === 0) AddLocalStorageData("selectedShip", null);
      else {
        let clientData = {
          clientId: clientId,
          clientName: clientList?.filter((x) => x.value == clientId)[0]?.label,
        };

        AddLocalStorageData("selectedClient", JSON.stringify(clientData));
        AddLocalStorageData("selectedShip", shipId);
      }
      this.props.navigate("/AddEditShip");
    }
  };

  /* <summary>
   date: 05/01/2023
   Name: NGG
   description: Updates Active/Inactvie toggle status
   <summary> */
  onSwitchChange = async (shipId, status) => {

    //save the status of the ships in db
    this.setState({
      isLoading: true,
    });
    const { clientId, userId, userTypeId, dropdown_status, dropdown_clientName, dropdown_shipName, dropdown_imono } = this.state;
    const response = await ShipService.ChangeStatus(
      shipId,
      status,
      clientId,
      userId,
      userTypeId
    );

    if (response.data != null && response.data != undefined) {
      if (response.status == 401) {
        Logout(this.props.navigate);
      }
      if (response.status == 200) {
        let uniqueShipNameList = Array.from([
          ...new Set(
            response.data.map((l) => l.shipName).filter((x) => x != undefined)
          ),
        ]);
        let uniqueStatusList = Array.from([
          ...new Set(
            response.data.map((l) => l.imono).filter((x) => x != undefined)
          ),
        ]);

        let status = Array.from([
          ...new Set(response.data.map((l) => l.isActive)),
        ]);

        //SYJ - 8/06/2023 - Changes For https://jiraapp.edotteam.com/browse/IHMM-1155
        var filterList = response.data;
        if (response.data.length > 0 && dropdown_status != "") {
          filterList = response.data.filter((e) => e.status == dropdown_status);
        }

        if (response.data.length > 0 && dropdown_clientName != "" && dropdown_shipName != "" && dropdown_status != "") {
          if (filterList.length > 0) {
            filterList = response.data.filter((e) => e.clientId == dropdown_clientName && e.shipName.includes(dropdown_shipName) && e.status == dropdown_status);
          }
        }

        if (response.data.length > 0 && dropdown_clientName != "" && dropdown_shipName != "" && dropdown_imono != "" && dropdown_status != "") {
          if (filterList.length > 0) {
            filterList = response.data.filter((e) => e.clientId == dropdown_clientName && e.shipName.includes(dropdown_shipName) && e.imono == dropdown_imono && e.status == dropdown_status);
          }
        }

        if (response.data.length > 0 && dropdown_clientName != "" && dropdown_shipName != "" && dropdown_status == "0") {
          if (filterList.length > 0) {
            filterList = response.data.filter((e) => e.clientId == dropdown_clientName && e.shipName.includes(dropdown_shipName));
          }
        }

        if (response.data.length > 0 && dropdown_clientName != "" && dropdown_shipName != "" && dropdown_imono != "" && dropdown_status == "0") {
          if (filterList.length > 0) {
            filterList = response.data.filter((e) => e.clientId == dropdown_clientName && e.shipName.includes(dropdown_shipName) && e.imono == dropdown_imono);
          }
        }



        this.setState({
          mappedShips: filterList,
          defaultShips: response.data,
          shipNameList: uniqueShipNameList.sort((a, b) => a.localeCompare(b)),
          //imoNoList: uniqueStatusList,
          uniqueStatusList: status,
          //dropdown_shipName: ,
        });

      } else {
        this.setState({
          mappedShips: [],
          defaultShips: [],
          shipNameList: [],
          imoNoList: [],
          uniqueStatusList: [],
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }
    }
    this.setState({
      isLoading: false,
    });
  };

  /* <summary>
   date: 22/12/2022
   Name: NGG
   description: Get all ship to bind in the Ag grid, fill in the dropdown used for filtering
   //A.N. - 06/03/2023 - handled client login for ships
   //HV - 10/03/2023 - handled displaying all client and ship data if navigated from header
   <summary> */
  getManageShipData = async (clientId, userId, clientUserId, userTypeId) => {
    //, userTypeId
    try {
      if (this.state.isLoad || !this.state.hasMoreData) return;
      const {
        pageSize,
        pageNumber
      } = this.state;
      const page = pageNumber;

      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.clientAndShipAccess == UserAction.READ,
      });

      const response = await ShipService.GetManageShipData(
        this.state.clientId,
        this.state.userId,
        this.state.clientUserId,
        this.state.userTypeId,
        pageSize,
        page
      );
      //, userTypeId
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        }

        const newData = response.data.lstShipModel;

        totalPOCount = response?.data?.totalCount ?? 0;
        this.setState({ count: totalPOCount }),
          localStorage.setItem("Count", totalPOCount);

        let hasMoreData = false;

        // if (newData.length === 200)
        //   hasMoreData = true;
        // else
        //   hasMoreData = newData.length === pageSize;

        debugger;
        var shipData = response.data.lstShipModel;
        let clientList = response.data.clientList;

        if (clientList.length != 0) {
          let defaultClientId = this.state.clientId == 0 ? clientList[0].value : this.state.clientId;
          let filteredShipData = shipData.filter(
            (x) => x.clientId == defaultClientId
          );

          let uniqueShipNameList = Array.from([
            ...new Set(
              filteredShipData
                .map((l) => l.shipName)
                .filter((x) => x != undefined)
            ),
          ]);
          let uniqueImoList = Array.from([
            ...new Set(
              filteredShipData.map((l) => l.imono).filter((x) => x != undefined)
            ),
          ]);

          let clientData = {
            clientId: defaultClientId,
            clientName: clientList.filter((x) => x.value == defaultClientId)[0]
              .label,
          };

          AddLocalStorageData("selectedClient", JSON.stringify(clientData));

          let status = Array.from([
            ...new Set(shipData.map((l) => l.isActive)),
          ]);
          var filterDataBySelectedShip =
            this.state.isClient && shipData != null
              ? GetLocalStorageDataWithoutParse("selectedShip") != 0 &&
                this.state.dropdown_shipName != 0
                ? shipData.filter(
                  (x) => x.shipId == GetLocalStorageDataWithoutParse("selectedShip")
                )
                : shipData
              : "";

          let shipDetails = !this.state.isClient ? filteredShipData : filterDataBySelectedShip;

          this.setState((prevState) => ({
            mappedShips: [...prevState.mappedShips, ...shipDetails],
            defaultShips: [...prevState.defaultShips, ...shipData],
            shipNameList: uniqueShipNameList.sort((a, b) => a.localeCompare(b)),
            imoNoList: uniqueImoList.sort(function (a, b) {
              return a - b;
            }),
            dropdown_imono:
              GetLocalStorageDataWithoutParse("selectedShip") != 0 &&
                this.state.dropdown_shipName != 0
                ? filterDataBySelectedShip != ""
                  ? filterDataBySelectedShip[0].imono
                  : 0
                : 0,
            uniqueStatusList: status,
            dropdown_shipName:
              GetLocalStorageDataWithoutParse("selectedShip") != 0 &&
                this.state.dropdown_shipName != 0
                ? filterDataBySelectedShip != ""
                  ? filterDataBySelectedShip[0].shipName
                  : 0
                : 0,
            dropdown_clientName: defaultClientId,
            clientList: clientList,
            pageNumber: prevState.pageNumber + 1,
            hasMoreData: [...prevState.mappedShips, ...shipDetails].length<totalPOCount,
          }));
        } else {
          this.setState({
            isLoading: false,
            toastVisible: true,
            toastMessage: helperText.Ship.noClient,
            toastType: helperText.global.toastError,
          });
        }
      } else {
        this.setState({
          mappedShips: [],
          defaultShips: [],
          shipNameList: [],
          imoNoList: [],
          uniqueStatusList: [],
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
   date: 22/12/2022
   Name: NGG
   description: On filter dropdown selected
   <summary> */
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        [name]: value,
        hasMoreData: true,
        pageNumber: 1,
      },
      () => this.filterGridView()
    );
  };

  /* <summary>
   date: 22/12/2022
   Name: NGG
   description: Filter grid data using the date and dropdowns available in the ui
   <summary> */
  filterGridView = () => {
    const {
      dropdown_shipName,
      dropdown_imono,
      dropdown_status,
      dropdown_clientName,
      searchedText
    } = this.state;

    var filterList = [];
    var supplierList = this.state.defaultShips;

    if (
      dropdown_shipName == 0 &&
      dropdown_imono == 0 &&
      dropdown_status == 0 &&
      dropdown_clientName == 0
    )
      filterList = this.state.defaultShips;
    else {
      if (dropdown_clientName != "" && dropdown_clientName != "0") {
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.clientId == dropdown_clientName)
            : supplierList.filter((e) => e.clientId == dropdown_clientName);
      }

      if (dropdown_shipName != "" && dropdown_shipName != "0") {
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.shipName.includes(dropdown_shipName))
            : supplierList.filter((e) =>
              e.shipName.includes(dropdown_shipName)
            );
      }

      if (dropdown_imono != "" && dropdown_imono != "0") {
        filterList =
          filterList.length > 0
            ? filterList.filter((e) => e.imono == dropdown_imono)
            : supplierList.filter((e) => e.imono == dropdown_imono);
      }

      if (dropdown_status != "0" && dropdown_status != "") {
        if (filterList.length > 0) {
          filterList = filterList.filter((e) => e.status == dropdown_status);
        } else if (
          filterList.length == 0 &&
          (dropdown_imono != 0 || dropdown_shipName != 0)
        ) {
          filterList = filterList.filter((e) => e.status == dropdown_status);
        } else {
          filterList = supplierList.filter((e) => e.status == dropdown_status);
        }
      }
    }

    let uniqueImoList = Array.from([
      ...new Set(
        this.state.defaultShips
          .map((l) => l.imono)
          .filter((x) => x != undefined)
      ),
    ]);

    if (searchedText.trim() != "") {
      var value = searchedText.trim().toString().toLowerCase();

      filterList = filterList.filter(
        (t) =>
          t.shipName?.toString().toLowerCase().includes(value) ||
          t.imono?.toString().toLowerCase().includes(value) ||
          t.statementOfCompliancName
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.shipDistinctiveNo?.toString().toLowerCase().includes(value) ||
          t.shipEmailAddress?.toString().toLowerCase().includes(value) ||
          t.shipBuilderName?.toString().toLowerCase().includes(value) ||
          t.shipOwnerName?.toString().toLowerCase().includes(value) ||
          t.shipOwnerContactNo?.toString().toLowerCase().includes(value) ||
          t.shipOwnerEmailAdress?.toString().toLowerCase().includes(value) ||
          t.imoregisteredOwnerName?.toString().toLowerCase().includes(value) ||
          t.shipIhmpicName?.toString().toLowerCase().includes(value) ||
          t.shipIhmpicEmailAddress?.toString().toLowerCase().includes(value) ||
          t.alternateIHMMHandler?.toString().toLowerCase().includes(value) ||
          t.classNumber?.toString().toLowerCase().includes(value) ||
          t.classificationSocietyId?.toString().toLowerCase().includes(value) ||
          t.classificationSocietyName
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.grossTonnage?.toString().toLowerCase().includes(value) ||
          t.ihmidentificationNo?.toString().toLowerCase().includes(value) ||
          t.ihmmHandler?.toString().toLowerCase().includes(value) ||
          t.imoregisteredOwnerIdentficationNo
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.imocompanyIdentificationNo
            ?.toString()
            .toLowerCase()
            .includes(value) ||
          t.portOfRegistryName?.toString().toLowerCase().includes(value) ||
          t.projectNumber?.toString().toLowerCase().includes(value) ||
          t.serviceCategory?.toString().toLowerCase().includes(value) ||
          t.shipBuilderAddress?.toString().toLowerCase().includes(value) ||
          t.shipBuilderContactNo?.toString().toLowerCase().includes(value) ||
          t.shipOwnerAddress?.toString().toLowerCase().includes(value) ||
          t.shipIhmpicAddress?.toString().toLowerCase().includes(value) ||
          t.shipIhmpicContactNo?.toString().toLowerCase().includes(value) ||
          t.shipTypeName?.toString().toLowerCase().includes(value) ||
          t.pendingCorrect?.toString().toLowerCase().includes(value) ||
          t.dateOfConstruction?.toString().toLowerCase().includes(value)
      );
    }

    this.setState({
      mappedShips: filterList,
      imoNoList:
        dropdown_shipName != 0
          ? Array.from([
            ...new Set(
              this.state.defaultShips
                .filter((x) => x.shipName == dropdown_shipName)
                .map((l) => l.imono)
            ),
          ]).sort(function (a, b) {
            return a - b;
          })
          : uniqueImoList.sort(function (a, b) {
            return a - b;
          }),
    });
  };

  /* <summary>
    date: 22/12/2022
    Name: NGG
    description: Filter data from grid using search bar
    <summary> */
  onSearch = (e) => {
    var inputValue = e.target.value;

    var inputValue = e.target.value;
    this.setState({
      searchedText: inputValue,
    }, () => {
      this.filterGridView();
    });
  };

  clearAllFilterClicked = () => {
    document.getElementById("searchQueryInput").value = "";
    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        dropdown_shipName: 0,
        dropdown_imono: 0,
        dropdown_status: 0,
        searchedText: "",
        hasMoreData: true,
      },
      () => {
        this.filterGridView();
      }
    );
  };

  /* <summary>
    date: 05/01/2022
    Name: NGG
    description: navigates to tracking tool page 
    <summary> */
  onViewTrackingToolClicked = (shipId) => {
    if (shipId === 0) AddLocalStorageData("selectedShip", null);
    else {
      var shipData = this.state.defaultShips.filter((s) => s.shipId === shipId);
      AddLocalStorageData("selectedShipTTool", JSON.stringify(shipData[0]));
    }
    AddLocalStorageData("location", "/ManageShips")
    this.props.navigate("/TrackingTool");
  };

  //A.N. - 06/03/2023 - handled for ship name filter select
  onShipNameSelect = (e) => {
    var { name, value } = e.target;

    this.setState({
      [name]: value,
      dropdown_imono: 0,
      dropdown_status: 0,
    });

    var filterList = [];
    var supplierList = this.state.defaultShips;

    if (value != "" && value != "0")
      filterList =
        filterList.length > 0
          ? filterList.filter((e) => e.shipName.includes(value))
          : supplierList.filter((e) => e.shipName.includes(value));

    let uniqueImoList = Array.from([
      ...new Set(
        supplierList.map((l) => l.imono).filter((x) => x != undefined)
      ),
    ]);
    this.setState({
      mappedShips: value == "0" ? supplierList : filterList,
      imoNoList:
        value != "" && value != "0"
          ? Array.from([
            ...new Set(
              this.state.defaultShips
                .filter((x) => x.shipName == value)
                .map((l) => l.imono)
            ),
          ]).sort(function (a, b) {
            return a - b;
          })
          : uniqueImoList.sort(function (a, b) {
            return a - b;
          }),
    });
  };

  /* <summary>
    date: 11/03/2023
    Name: HV
    description: on client name select
    <summary> */
  onClientNameSelect = (e) => {
    var { name, value } = e.target;
    this.setState(
      {
        [name]: value,
        dropdown_imono: 0,
        dropdown_shipName: 0,
      },
      () => {
        const { defaultShips } = this.state;
        let defaultClientId = value;

        let filteredShipData = defaultShips.filter(
          (x) => x.clientId == defaultClientId
        );

        let uniqueShipNameList = Array.from([
          ...new Set(
            filteredShipData
              .map((l) => l.shipName)
              .filter((x) => x != undefined)
          ),
        ]);
        let uniqueImoList = Array.from([
          ...new Set(
            filteredShipData.map((l) => l.imono).filter((x) => x != undefined)
          ),
        ]);

        this.setState({
          mappedShips: filteredShipData,
          shipNameList: uniqueShipNameList,
          imoNoList: uniqueImoList.sort(function (a, b) {
            return a - b;
          }),
        });
      }
    );
  };

  goback = () => {
    this.setState({ isBackClicked: true }, () => {
      var loc = GetLocalStorageDataWithoutParse("location");
      if (this.state.isClientOrClientUser) {
        if (loc == "/ActivityLog") {
          this.props.navigate("/ActivityLog");
        } else {
          this.props.navigate("/ClientDashboard");
        }
      } else {
        if (loc == "/ActivityLog") {
          this.props.navigate("/ActivityLog");
        } else {
          this.props.navigate("/ManageClient");
        }
      }
    });
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };


  onScroll = () => {
    if (!this.state.isLoading && this.state.hasMoreData) {
      this.getManageShipData();
    }
  };

  render() {
    const {
      mappedShips,
      shipNameList,
      imoNoList,
      toastMessage,
      isLoading,
      toastVisible,
      toastType,
      dropdown_shipName,
      dropdown_imono,
      dropdown_status,
      accessList,
      clientList,
      dropdown_clientName,
      clientId,
      navigatedFromManageClient,
      hasMoreData,
      count,
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"ManageShip"} />

        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        <main className="page-height">
          <section className="communications manage-ship">
            <div className="container manageship-parent-class">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-6 col-xl-7 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button"
                    src="images/back button image.png"
                    alt="Back"
                    onClick={this.goback}
                    style={{ cursor: "pointer" }}
                  />
                  <span className="title-font">Manage Ships</span>
                </div>
                <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                  <Searchbar
                    placeholder={"PIC Name, Ship Owner Name, Ship Name etc..."}
                    onChange={this.onSearch}
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="accordion-filter-dropdown">
                  <div className="accordion" id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"cName"}>
                            <Dropdown
                              dropdownLabel="Client Name:"
                              name="clientName"
                              onChange={this.onClientNameSelect}
                              selected={dropdown_clientName}
                              disabled={
                                navigatedFromManageClient ||
                                this.state.isClientOrClientUser
                              }
                            // style={{ textOverflow: 'ellipsis', whiteSpace: 'pre', overflow: 'hidden' }}
                            >
                              {clientList == null
                                ? null
                                : clientList.map((item, key) => (
                                  <option key={key} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>

                            {dropdown_clientName > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"cName"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {clientList != null && clientList.filter(x => x.value == dropdown_clientName)[0]?.label}
                              </UncontrolledTooltip>) : null}
                          </div>
                          <div className="form-group dropdown-form form-drpdown-responsive admin-client-form" id={"shipName"}>
                            <Dropdown
                              dropdownLabel="Ship Name:"
                              name="shipName"
                              onChange={this.onShipNameSelect}
                              selected={dropdown_shipName}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                              hasALL={true}
                            >
                              {shipNameList == null
                                ? null
                                : shipNameList.map((item, key) => (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {!isStringEmpty(dropdown_shipName) ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"shipName"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {shipNameList != null && dropdown_shipName}
                              </UncontrolledTooltip>) : null}
                          </div>
                          <div className="form-group dropdown-form admin-client-form" id={"imoNo"}>
                            <Dropdown
                              dropdownLabel="IMO Number:"
                              name="imono"
                              onChange={this.onFilterSelected}
                              selected={dropdown_imono}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {imoNoList == null
                                ? null
                                : imoNoList.map((item, key) => (
                                  <option key={key} value={item}>
                                    {item}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_imono > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"imoNo"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {imoNoList != null && dropdown_imono}
                              </UncontrolledTooltip>) : null}
                          </div>
                          <div className="form-group dropdown-form admin-client-form">
                            <Dropdown
                              dropdownLabel="Status:"
                              name="status"
                              onChange={this.onFilterSelected}
                              selected={dropdown_status}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              <option key={1} value={"active"}>
                                {"Active"}
                              </option>
                              <option key={2} value={"inactive"}>
                                {"Inactive"}
                              </option>
                              ;
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form form-drpdown-responsive " id={"clientName1"}>
                      <Dropdown
                        dropdownLabel="Client Name:"
                        name="clientName"
                        onChange={this.onClientNameSelect}
                        selected={dropdown_clientName}
                        disabled={
                          navigatedFromManageClient ||
                          this.state.isClientOrClientUser
                        }
                      >
                        {clientList == null
                          ? null
                          : clientList.map((item, key) => (
                            <option key={key} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_clientName > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientName1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {clientList != null && clientList.filter(x => x.value == dropdown_clientName)[0]?.label}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form form-drpdown-responsive " id={"shipName1"}>
                      <Dropdown
                        dropdownLabel="Ship Name:"
                        name="shipName"
                        onChange={this.onShipNameSelect}
                        selected={dropdown_shipName}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                        hasALL={true}
                      >
                        {shipNameList == null
                          ? null
                          : shipNameList.map((item, key) => (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_shipName != undefined && dropdown_shipName != null && dropdown_shipName != "" && dropdown_shipName != 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"shipName1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {shipNameList != null && dropdown_shipName}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form " id={"imoNo1"}>
                      <Dropdown
                        dropdownLabel="IMO Number:"
                        name="imono"
                        onChange={this.onFilterSelected}
                        selected={dropdown_imono}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {imoNoList == null
                          ? null
                          : imoNoList.map((item, key) => (
                            <option key={key} value={item}>
                              {item}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_imono > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"imoNo1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {imoNoList != null && dropdown_imono}
                        </UncontrolledTooltip>) : null}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-3">
                  <div className="desktop-class">
                    <div className="form-group dropdown-form ">
                      <Dropdown
                        dropdownLabel="Status:"
                        name="status"
                        onChange={this.onFilterSelected}
                        selected={dropdown_status}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        <option key={1} value={"active"}>
                          {"Active"}
                        </option>
                        <option key={2} value={"inactive"}>
                          {"Inactive"}
                        </option>
                        ;
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-lg-12 btn-top-space">
                  {/* <button type="button"  className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client" onClick={()=>{this.clearAllFilterClicked()}}>Reset</button> */}
                  <div className="float-btn manage-client-button">
                    <button
                      type="button"
                      className="btn btn-blue-bg "
                      onClick={this.clearAllFilterClicked}
                    >
                      Reset
                    </button>
                    <button
                      style={{
                        display: this.state.isClientOrClientUser ? "none" : "",
                      }}
                      type="button"
                      disabled={
                        accessList != null &&
                          (this.state.isClientOrClientUser
                            ? this.state.accessList.shipParticularsAccess ==
                            UserAction.READ
                            : this.state.accessList.clientAndShipAccess ==
                            UserAction.READ)
                          ? true
                          : false
                      }
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => {
                        this.onAddEditShipClicked(0);
                      }}
                    >
                      <img src="images/admin-plus-icon.png" alt="plus-icon" />
                      Add Ship
                    </button>
                  </div>
                </div>
              </div>
              <div className="manage-supplier-grid">
                <div className="row">
                  <div className="col-md-12">
                    <AGGrid
                      onGridReady={this.onGridReady}
                      rowData={mappedShips}
                      colDefs={this.gridColumns(
                        !this.state.isAdmin &&
                        this.state.accessList != null &&
                        this.state.accessList.trackingToolAccess ==
                        UserAction.DENY
                      )}
                      rowSelection="single"
                      autoHeight={false}
                      onSortChanged={(e) => this.onSortChanged(e)}
                      pagination="100"
                      onScroll={this.onScroll}
                      lazyLoading={true}
                      hasMoreData={hasMoreData}
                      //resetLoadedPages={hasMoreData}
                      totalCount={totalPOCount}
                      stateCount={count}
                      isLazyPage={true}
                      update={"manageShip"}
                      isManageShip={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer />
      </>
    );
  }
}

export default withRouter(ManageShips);
