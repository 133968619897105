import webserviceWrapper from "../helpers/webserviceWrapper";

export const TrainingService = {
    GetTrainingData,
    DeleteTrainingDocData,
    AddTrainingDocsData,
    DownloadTrainingDoc,
    GetClientTrainingData
};

/* <summary>
date: 30/12/2022
Name: RM
description: service api call to get training data
<summary> */
function GetTrainingData(userID, typeID, pageSize, pageNumber) {
    return webserviceWrapper.get(`/Training/GetTrainingDocsData?userID=${userID}&typeID=${typeID}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
}

/* <summary>
date: 02/01/2023
Name: RM
description: service api call to delete training data
<summary> */
function DeleteTrainingDocData(trainingdocID, loggedInUserID, typeId) {
    return webserviceWrapper.get(`/Training/DeleteTrainingDocData?trainingdocId=${trainingdocID}&loggedInUserID=${loggedInUserID}&typeID=${typeId}`);
}

/* <summary>
date: 02/01/2023
Name: AS
description: service api call to save file
<summary> */
function AddTrainingDocsData(trainigData) {
    return webserviceWrapper.postWithHeader(
        `/Training/AddTrainingDocsData`,
        trainigData, { "Content-Type": "multipart/form-data", },
        600000
    );
}

/* <summary>
date: 06/01/2023
Name: RM
description: service api call to download training file
<summary> */

function DownloadTrainingDoc(tFileIDlist) {
    return webserviceWrapper.post(
        `/Training/DownloadTrainingDoc`,
        tFileIDlist,
        600000
    );
}

/* <summary>
date: 27/02/2023
Name: AN
description: service api call to get client training data
<summary> */
function GetClientTrainingData(loggedinUserID, typeId) {
    return webserviceWrapper.get(`/Training/GetClientTrainingDocsData?loggedinUserID=${loggedinUserID}&typeId=${typeId}`);
}